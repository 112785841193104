<div class="active-revert-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formRevertPayments !== undefined || formRevertPayments !== null" [formGroup]="formRevertPayments">
        <p class="title text-center h4 w-100">Activa la devolución de todas las inversiones en un contrato de venta</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="seller" class="form-label">Address del contrato de venta:</label>
            <input [class.errorInput]="seller?.invalid && (seller?.dirty || seller?.touched)" formControlName="seller" type="text" class="form-control" id="seller" placeholder="Introduce la address del contrato de venta">
        </div>
        
        <button [disabled]="formRevertPayments.invalid" (click)="revert()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Activar devolución</button>
    </form>
</div>
