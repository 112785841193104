<div class="payment-container">
    <div class="payment__title">
      <h1 class="text-center">¡Error en el proceso de pago!</h1>
    </div>
    <div>
      <p class="payment__text__paragraph text-center">
        Hubo un problema al procesar tu pago para el proyecto. Verifica la información de tu tarjeta y vuelve a intentarlo.
      </p>
    </div>
    <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 15px;">
      <button routerLink="/oportunidades" class="btn btn-success width-btn">Aceptar</button>
    </div>
  </div>