import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DigitalWalletRoutingModule } from './digital-wallet-routing.module';
import { DigitalWalletComponent } from './digital-wallet.component';
import { BlockchainComponent } from './blockchain/blockchain.component';
import { EurosComponent } from './euros/euros.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TransactionsHistoryComponent } from './transactions-history/transactions-history.component';


@NgModule({
  declarations: [
    DigitalWalletComponent,
    BlockchainComponent,
    EurosComponent,
    WithdrawComponent,
    TransactionsHistoryComponent
  ],
  imports: [
    CommonModule,
    DigitalWalletRoutingModule,
    FormsModule, // Añade FormsModule a los imports
    ReactiveFormsModule, // Añade ReactiveFormsModule a los imports
    NgbModule,
    SharedModule,
    MatCardModule,
    MatButtonModule,
    NgSelectModule,
  ]
})
export class DigitalWalletModule { }
