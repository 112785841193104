import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import * as jsonManager from '../../abis/Manager.json';
import { environment } from '../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { percentage } from '@angular/fire/storage';
import { LoaderService } from 'src/app/services/loader.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-new-proyect',
  templateUrl: './new-proyect.component.html',
  styleUrls: ['./new-proyect.component.css']
})
export class NewProyectComponent {
  id: string = Date.now().toString();
  formNewNft: FormGroup = this.createFormNewNft();
  fotoMain: string | null = null;
  foto1: string | null = null;
  foto2: string | null = null;
  foto3: string | null = null;
  foto4: string | null = null;
  foto5: string | null = null;
  foto6: string | null = null;
  foto7: string | null = null;
  foto8: string | null = null;
  foto9: string | null = null;
  foto10: string | null = null;
  whitePaper: string | null = null;
  dosier: string | null = null;
  infoToken: string | null = null;
  loadingFlag: boolean = false;

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(
    private storage: AngularFireStorage, 
    private afs: AngularFirestore, 
    private connSrv: ConnectionsService,
    private fns: AngularFireFunctions,
    private loaderService: LoaderService
  ) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER);
  }

  createFormNewNft() {
    return new FormGroup({
      titulo: new FormControl(null, [Validators.required]),
      promotora: new FormControl(null, [Validators.required]),
      pais: new FormControl(null, [Validators.required]),
      estadoCCAAProvincia: new FormControl(null, [Validators.required]),
      localidad: new FormControl(null, [Validators.required]),
      barrio: new FormControl(null, [Validators.required]),
      codigoPostal: new FormControl(null, [Validators.required]),
      calle: new FormControl(null, [Validators.required]),
      iframeGoogleMaps: new FormControl(null, [Validators.required]),
      descripcion: new FormControl(null, [Validators.required]),
      plazoEstimadoEntrega: new FormControl(null, [Validators.required]),
      rentabilidadAnual: new FormControl(0, [Validators.required, Validators.min(1)]),
      amountToSell: new FormControl(0, [Validators.required, Validators.min(1)]),
      precioToken: new FormControl(0, [Validators.required, Validators.min(0)]),
      inversionMinima: new FormControl(0, [Validators.required, Validators.min(1)]),
      stableCoinAddress: new FormControl('0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063', [Validators.required]),
      beneficiaryAddress: new FormControl(null, [Validators.required]),
      aggregatorStableCoinDolar: new FormControl('0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D', [Validators.required]),
      urlWhitepaperPdf: new FormControl(null, [Validators.required]),
      urlDosierPdf: new FormControl(null),
      urlInfoTokenPdf: new FormControl(null, [Validators.required]),
      urlImagenPrincipal: new FormControl(null, [Validators.required]),
      toBlockchain: new FormControl(false),
      onlyInversors: new FormControl(false),
      percentageToPivateSale: new FormControl(75, [Validators.required, Validators.min(0), Validators.max(100)]),
      atributos: new FormControl(null, [Validators.required]),
      nameTokens: new FormControl(null, [Validators.required]),
      symbolTokens: new FormControl(null, [Validators.required]),
      infoProyecto: new FormControl(null, [this.urlValidator])
    })
  }

  get titulo() { return this.formNewNft?.get('titulo'); }
  get promotora() { return this.formNewNft?.get('promotora'); }
  get pais() { return this.formNewNft?.get('pais'); }
  get estadoCCAAProvincia() { return this.formNewNft?.get('estadoCCAAProvincia'); }
  get localidad() { return this.formNewNft?.get('localidad'); }
  get barrio() { return this.formNewNft?.get('barrio'); }
  get codigoPostal() { return this.formNewNft?.get('codigoPostal'); }
  get calle() { return this.formNewNft?.get('calle'); }
  get iframeGoogleMaps() { return this.formNewNft?.get('iframeGoogleMaps'); }
  get descripcion() { return this.formNewNft?.get('descripcion'); }
  get plazoEstimadoEntrega() { return this.formNewNft?.get('plazoEstimadoEntrega'); }
  get rentabilidadAnual() { return this.formNewNft?.get('rentabilidadAnual'); }
  get precioToken() { return this.formNewNft?.get('precioToken'); }
  get inversionMinima() { return this.formNewNft?.get('inversionMinima'); }
  get amountToSell() { return this.formNewNft?.get('amountToSell'); }
  get stableCoinAddress() { return this.formNewNft?.get('stableCoinAddress'); }
  get beneficiaryAddress() { return this.formNewNft?.get('beneficiaryAddress'); }
  get aggregatorStableCoinDolar() { return this.formNewNft?.get('aggregatorStableCoinDolar'); }
  get urlWhitepaperPdf() { return this.formNewNft?.get('urlWhitepaperPdf'); }
  get urlDosierPdf() { return this.formNewNft?.get('urlDosierPdf'); }
  get urlInfoTokenPdf() { return this.formNewNft?.get('urlInfoTokenPdf'); }
  get urlImagenPrincipal() { return this.formNewNft?.get('urlImagenPrincipal'); }
  get toBlockchain() { return this.formNewNft?.get('toBlockchain'); }
  get onlyInversors() { return this.formNewNft?.get('onlyInversors'); }
  get percentageToPivateSale() { return this.formNewNft?.get('percentageToPivateSale'); }
  get atributos() { return this.formNewNft?.get('atributos'); }
  get nameTokens() { return this.formNewNft?.get('nameTokens'); }
  get symbolTokens() { return this.formNewNft?.get('symbolTokens'); }
  get infoProyecto() { return this.formNewNft?.get('infoProyecto'); }

  async newNft() {
    if (this.formNewNft.invalid) { 
      this.formNewNft.markAllAsTouched();
      return; 
    }

    const imagesUrls: string[] = this.pushImages();
    const atributos = this.getAtributosJson();

    const item: any = {
      id: this.id,
      hide: false,
      title: this.titulo?.getRawValue(),
      company: this.promotora?.getRawValue(),
      country: this.pais?.getRawValue(),
      stateCCAAProvince: this.estadoCCAAProvincia?.getRawValue(),
      location: this.localidad?.getRawValue(),
      neighborhood: this.barrio?.getRawValue(),
      cp: this.codigoPostal?.getRawValue(),
      street: this.calle?.getRawValue(),
      iframeGoogleMaps: this.iframeGoogleMaps?.getRawValue(),
      description: this.descripcion?.getRawValue(),
      estimatedDeliveryTime: this.plazoEstimadoEntrega?.getRawValue(),
      annualReturn: this.rentabilidadAnual?.getRawValue(),
      amountSold: 0,
      amountToSell: this.amountToSell?.getRawValue(),
      priceToken: this.precioToken?.getRawValue() * 100,
      minimumInvestment: this.inversionMinima?.getRawValue(),
      stableCoinAddress: this.stableCoinAddress?.getRawValue(),
      beneficiaryAddress: this.beneficiaryAddress?.getRawValue(),
      aggregatorStableCoinDolar: this.aggregatorStableCoinDolar?.getRawValue(),
      urlWhitepapperPdf: this.whitePaper,
      urlDosierPdf: this.dosier,
      urlInfoTokenPdf: this.infoToken,
      proyectStatus: 'En estudio',
      attributes: atributos,
      blockchain: this.toBlockchain?.getRawValue(),
      onlyInversors: this.onlyInversors?.getRawValue(),
      nameTokens: this.nameTokens?.getRawValue(),
      symbolTokens: this.symbolTokens?.getRawValue(),
      mainImage: this.fotoMain,
      images: imagesUrls,
      sellerAddress: null,
      tokensAddress: null,
      dividendsAddress: null,
      NftID: null,
      endOfSale: 0,
      numberInvestors: 0,
      hoursToSell: 0,
      infoProyecto: this.infoProyecto?.getRawValue(),
      ...(this.onlyInversors?.getRawValue() ? { percentageToPrivateSale: this.percentageToPivateSale?.getRawValue() } : {})
    };
    
    this.addInvestment(item);
  }

  addInvestment(item: any) {
    this.afs.collection('investments').doc(this.id).set(item, {merge: true})
      .then(async() => {
        await this.createProjectWallet(this.id);
        if (this.toBlockchain?.getRawValue() === true) {
          const _id = this.id;
          this.addProyectToBlockchain(_id);
        } else {
          Swal.fire({
            icon: 'success',
            text: `Proyecto subido a la BBDD (No blockchain)`
          });

          this.id = Date.now().toString();
          this.formNewNft.reset();
          this.resetVariables();
          this.stableCoinAddress?.patchValue('0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063');
          this.aggregatorStableCoinDolar?.patchValue('0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D');
        }
      })
      .catch(() => { 
        this.id = Date.now().toString();

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  async addProyectToBlockchain(id: string) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.mintNft(
      this.formatToWei(this.amountToSell?.getRawValue()),
      id,
      this.stableCoinAddress?.getRawValue(),
      this.beneficiaryAddress?.getRawValue(),
      this.aggregatorStableCoinDolar?.getRawValue(),
      this.nameTokens?.getRawValue(),
      this.symbolTokens?.getRawValue()
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        this.web3.eth.getBlockNumber().then((res: any) => {
          const blockNumber: number = res;

          this.contractManager.getPastEvents('newMint', {
            fromBlock: blockNumber - 8
          }, (err: any, events: any) => { 
            const e = events[0];
  
            if (this.web3.utils.sha3(id) === e.returnValues.uri) {
              this.afs.collection('investments').doc(id).set({
                sellerAddress: e.returnValues.e_seller,
                tokensAddress: e.returnValues.e_holdings,
                dividendsAddress: e.returnValues.e_dividends,
                NftID: e.returnValues.e_NftID
              }, {merge: true}).then(() => {
                Swal.close();
                Swal.fire({
                  icon: 'success',
                  text: `Proyecto subido a la BBDD y a la Blockchain`
                });
        
                this.id = Date.now().toString();
                this.formNewNft.reset();
                this.resetVariables();
                this.stableCoinAddress?.patchValue('0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063');
                this.aggregatorStableCoinDolar?.patchValue('0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D');
              });
            }
          });
        });
      })
      .catch(() => {
        this.afs.collection('investments').doc(id).delete();
        this.id = Date.now().toString();

        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Transacción errónea',
          text: 'Algo ha ido mal, la transacción NO se ha realizado'
        });
      });
  }

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  resetVariables() {
    this.fotoMain = null;
    this.foto1 = null;
    this.foto2 = null;
    this.foto3 = null;
    this.foto4 = null;
    this.foto5 = null;
    this.foto6 = null;
    this.foto7 = null;
    this.foto8 = null;
    this.foto9 = null;
    this.foto10 = null;

    this.whitePaper = null;
    this.dosier = null;
    this.infoToken = null;
  }

  pushImages(): string[] {
    let images: string[] = [];

    (this.foto1 !== null) ? images.push(this.foto1) : null;
    (this.foto2 !== null) ? images.push(this.foto2) : null;
    (this.foto3 !== null) ? images.push(this.foto3) : null;
    (this.foto4 !== null) ? images.push(this.foto4) : null;
    (this.foto5 !== null) ? images.push(this.foto5) : null;
    (this.foto6 !== null) ? images.push(this.foto6) : null;
    (this.foto7 !== null) ? images.push(this.foto7) : null;
    (this.foto8 !== null) ? images.push(this.foto8) : null;
    (this.foto9 !== null) ? images.push(this.foto9) : null;
    (this.foto10 !== null) ? images.push(this.foto10) : null;

    return images;
  }

  getAtributosJson(): Object {
    const atributos = '{' + this.atributos?.getRawValue() + '}';
    return JSON.parse(atributos);
  }

  uploadImg(e: any, id: string) {
    this.loadingFlag = true;
    const file = e.target.files[0];

    const filePath = `investments/${this.id}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          (id === 'fotoPrincipal') ? this.fotoMain = res : null;
          (id === 'foto1') ? this.foto1 = res : null;
          (id === 'foto2') ? this.foto2 = res : null;
          (id === 'foto3') ? this.foto3 = res : null;
          (id === 'foto4') ? this.foto4 = res : null;
          (id === 'foto5') ? this.foto5 = res : null;
          (id === 'foto6') ? this.foto6 = res : null;
          (id === 'foto7') ? this.foto7 = res : null;
          (id === 'foto8') ? this.foto8 = res : null;
          (id === 'foto9') ? this.foto9 = res : null;
          (id === 'foto10') ? this.foto10 = res : null; 
          this.loadingFlag = false;
        })
      })).subscribe();
  }

  uploadWhitepaper(e: any) {
    this.loadingFlag = true;
    const file = e.target.files[0];

    const filePath = `investments/${this.id}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.whitePaper = res;
          this.loadingFlag = false;
        })
      })).subscribe();
  }

  uploadDosier(e: any) {
    this.loadingFlag = true;
    const file = e.target.files[0];

    const filePath = `investments/${this.id}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.dosier = res;
          this.loadingFlag = false;
        })
      })).subscribe();
  }

  uploadInfoToken(e: any) {
    this.loadingFlag = true;
    const file = e.target.files[0];

    const filePath = `investments/${this.id}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.infoToken = res;
          this.loadingFlag = false;
        })
      })).subscribe();
  }

  urlValidator(control: AbstractControl): { [key: string]: any } | null {
    const validUrlRegex = /^(ftp|http|https):\/\/(?:(?:[a-z0-9][a-z0-9-]*[a-z0-9]\.)+[a-z]{2,}|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))(?::[0-9]{1,5})?(?:\/[^\s]*)?$/i;
    const value = control.value;
    const isValid = validUrlRegex.test(value);
    return isValid ? null : { 'invalidUrl': { value } };
  }

  async createProjectWallet(projectId: string) {
    try {
      this.loaderService.showSpinner('Creando wallet para el proyecto');
      const response = await this.fns.httpsCallable('setProjectWallet')({ projectId }).toPromise();
      console.log(response);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ha ocurrido un error creando la wallet del proyecto!'
      });
      await this.afs.collection('investments').doc(projectId).delete();
    } finally {
      this.loaderService.hideSpinner();
    }
  }
}
