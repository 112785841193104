import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { KycService } from '../services/kyc.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {
  constructor(
    private authSrv: AuthService,
    private router: Router, 
    private zone: NgZone,
    private kycSrv: KycService
  ) {}

  goLogin() {
    this.zone.run(() => { this.router.navigate(['/login']); });
  }

  goOportunidades() {
    this.zone.run(() => { this.router.navigate(['/oportunidades']); });
  }

  goKyc() {
    this.zone.run(() => { this.router.navigate(['/oportunidades']); });
  }

  async checkLogin() {
    const loggedIn = await this.authSrv.isLoggedIn();
    //console.log(loggedIn);
    (!loggedIn) ? this.goLogin() : null;
  }

  async checkEmailVerified() {
    await firebase.auth().currentUser?.reload();

    const info = await this.authSrv.userAuth.getValue();
    (info !== null && !info.emailVerified) ? this.authSrv.sendOtherEmailVerification() : null;  
  }

  async checkKyc() {
    const kyc = await this.kycSrv.kycSuccess.getValue();
    
    (!kyc) ? this.goKyc() : null;
  }

  async checkKyc2() {
    const kyc = await this.kycSrv.kycSuccess.getValue();
    
    (kyc) ? this.goOportunidades() : null;
  }

  async checkAdmin() {
    const info = this.authSrv.admin.getValue();

    (info !== null && info.admin !== undefined && !info.admin) ? this.goOportunidades() : null;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    this.checkLogin();

    if (
      route.routeConfig?.path !== 'login' && 
      route.routeConfig?.path !== 'documentacion'
    ) {
      this.checkEmailVerified();
    }

    if(route.routeConfig?.path === 'oportunidades') {
      this.checkLogin();   
     }


    if (route.routeConfig?.path === 'mis-inversiones') {
      this.checkLogin();
    }

    if (route.routeConfig?.path === 'administracion') {
      this.checkAdmin();
    }

    if (route.routeConfig?.path === 'kyc') {
      this.checkKyc2();
    }
    
    return true;
  }
  
}
