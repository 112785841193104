import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {
  rol: string | null = null;
  goAdminFlag: boolean = false;
  newProyectFlag: boolean = false;
  proyectsSetDataFlag: boolean = false;
  newSaleSellerFlag: boolean = false;
  setDataSellerFlag: boolean = false;
  addDividendsFlag: boolean = false;
  setRolFlag: boolean = false;
  documentationGeneralFlag: boolean = false;
  uriYoutubeFlag: boolean = false;
  buyThroughCompanyFlag: boolean = false;
  setWhitelistFlag: boolean = false;
  forcedTransferFlag: boolean = false;
  setPauseFlag: boolean = false;
  setKycFlag: boolean = false;
  revertPaymentsFlag: boolean = false;
  infoDividendsFlag: boolean = false;
  buyWithoutPayFlag: boolean = false;
  hideProyectFlag: boolean = false;
  docsLegalFlag: boolean = false;
  adminWalletFlag: boolean = false;

  constructor(private afs: AngularFirestore, private authSrv: AuthService) {
    this.authSrv.userAuth.subscribe((res: any) => {
      if (res !== undefined && res !== null) {
        this.afs.collection('administrators').doc(res.uid).valueChanges().subscribe((res: any) => {
          (res !== undefined) ? this.rol = res.rol : this.rol = null;
        });
      }
    });
  }

  goSetRol() {
    this.goAdminFlag = true;
    this.setRolFlag = true;
  }

  goNewProyect() {
    this.goAdminFlag = true;
    this.newProyectFlag = true;
  }

  goProyectsSetData() {
    this.goAdminFlag = true;
    this.proyectsSetDataFlag = true;
  }

  goNewSaleSeller() {
    this.goAdminFlag = true;
    this.newSaleSellerFlag = true;
  }

  goSetDataSeller() {
    this.goAdminFlag = true;
    this.setDataSellerFlag = true;
  }

  goAddDividends() {
    this.goAdminFlag = true;
    this.addDividendsFlag = true;
  }

  goDocumentation() {
    this.goAdminFlag = true;
    this.documentationGeneralFlag = true;
  }

  goUriYoutube() {
    this.goAdminFlag = true;
    this.uriYoutubeFlag = true;
  }

  goBuyThroughCompamy() {
    this.goAdminFlag = true;
    this.buyThroughCompanyFlag = true;
  }

  goSetWhitelist() {
    this.goAdminFlag = true;
    this.setWhitelistFlag = true;
  }

  goForcedTransfer() {
    this.goAdminFlag = true;
    this.forcedTransferFlag = true;
  }

  goSetPause() {
    this.goAdminFlag = true;
    this.setPauseFlag = true;
  }

  goSetKyc() {
    this.goAdminFlag = true;
    this.setKycFlag = true;
  }

  goRevertPayments() {
    this.goAdminFlag = true;
    this.revertPaymentsFlag = true;
  }

  goInfoDividends() {
    this.goAdminFlag = true;
    this.infoDividendsFlag = true;
  }

  goBuyWithoutPay() {
    this.goAdminFlag = true;
    this.buyWithoutPayFlag = true;
  }

  goHideProyect() {
    this.goAdminFlag = true;
    this.hideProyectFlag = true;
  }

  goDocsLegal() {
    this.goAdminFlag = true;
    this.docsLegalFlag = true;
  }

  goAdminWallet() {
    this.goAdminFlag = true;
    this.adminWalletFlag = true;
  }


  goAdmin() {
    this.goAdminFlag = false;
    this.newProyectFlag = false;
    this.proyectsSetDataFlag = false;
    this.newSaleSellerFlag = false;
    this.setDataSellerFlag = false;
    this.addDividendsFlag = false;
    this.setRolFlag = false;
    this.documentationGeneralFlag = false;
    this.uriYoutubeFlag = false;
    this.buyThroughCompanyFlag = false;
    this.setWhitelistFlag = false;
    this.forcedTransferFlag = false;
    this.setPauseFlag = false;
    this.setKycFlag = false;
    this.revertPaymentsFlag = false;
    this.infoDividendsFlag = false;
    this.buyWithoutPayFlag = false;
    this.hideProyectFlag = false;
    this.docsLegalFlag = false;
    this.adminWalletFlag = false;
  }
}
