import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup = this.createFormLogin();
  formRegistro: FormGroup = this.createFormRegistro();
  loginFlag: boolean = true;
  registroFlag: boolean = false;
  verifiedFlag: boolean = false;

  constructor(private authSrv: AuthService) { }

  ////////////////////////////// LOGIN ///////////////////////////////////////////////
  createFormLogin() {
    return new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl({value: null, disabled:  true}, [ 
        Validators.required, 
        Validators.minLength(3),
        Validators.maxLength(20),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
      ])
    })
  }

  get email() { return this.formLogin?.get('email'); }
  get password() { return this.formLogin?.get('password'); }

  async login() {
    if (this.formLogin.invalid) { return; }
    if (this.email?.value === null) { return; }
    if (this.password?.value === null) { return; }

    this.authSrv.login(this.email?.value, this.password?.value);
    this.formLogin.reset();
  }
  ///////////////////////////////////////////////////////////////////////////////////
  
  ////////////////////////////// REGISTRO ///////////////////////////////////////////
  createFormRegistro() {
    return new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(25)]),
      emailR: new FormControl({value: null, disabled:  true}, [Validators.required, Validators.email]),
      passwordR1: new FormControl({value: null, disabled:  true}, [ 
        Validators.required, 
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/)
      ]),
      passwordR2: new FormControl({value: null, disabled:  true}, [Validators.required])
    })
  }

  get name() { return this.formRegistro?.get('name'); }
  get emailR() { return this.formRegistro?.get('emailR'); }
  get passwordR1() { return this.formRegistro?.get('passwordR1'); }
  get passwordR2() { return this.formRegistro?.get('passwordR2'); }

  async registro() {
    if (this.formRegistro.invalid) { return; }
    if (this.name?.value === null) { return; }
    if (this.emailR?.value === null) { return; }
    if (this.passwordR1?.value === null) { return; }
    if (this.passwordR1?.value !== this.passwordR2?.value) { return; }

    // this.authSrv.registerWithEmail(this.emailR?.value, this.passwordR1?.value, this.name?.value);
    this.formRegistro.reset();
  }
  ////////////////////////////////////////////////////////////////////////////////////

  async resetPasword() {
    const { value: email } = await Swal.fire({
      title: '',
      input: 'email',
      inputLabel: 'Necesitamos tu email',
      inputPlaceholder: 'Introduce tu email'
    })
    
    if (email) {
      this.authSrv.resetPassword(email);
    }
  }

  ngOnInit(): void {
    this.email?.valueChanges.subscribe(() => {
      if (!this.email?.errors && this.email?.value !== null) { 
        this.password?.enable();
      } else { 
        this.password?.disable(); 
        this.password?.patchValue(null);
      };
    });

    this.name?.valueChanges.subscribe(() => {
      if (!this.name?.errors && this.name?.value !== null) { 
        this.emailR?.enable(); 
      } else { 
        this.emailR?.disable(); 
        this.emailR?.patchValue(null);
      };
    });

    this.emailR?.valueChanges.subscribe(() => {
      if (!this.emailR?.errors && this.emailR?.value !== null) { 
        this.passwordR1?.enable(); 
      } else { 
        this.passwordR1?.disable(); 
        this.passwordR1?.patchValue(null);
      };
    });

    this.passwordR1?.valueChanges.subscribe(() => {
      if (!this.passwordR1?.errors && this.passwordR1?.value !== null) { 
        this.passwordR2?.enable(); 
      } else { 
        this.passwordR2?.disable(); 
        this.passwordR2?.patchValue(null);
      };
    });
  }

}
