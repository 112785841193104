import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ModalLoginComponent } from '../modal-login/modal-login.component';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-modal-register',
  templateUrl: './modal-register.component.html',
  styleUrls: ['./modal-register.component.css']
})
export class ModalRegisterComponent implements OnInit {
  formRegistro: FormGroup = this.createFormRegistro();
  loginFlag: boolean = true;
  show: boolean = false;
  registroFlag: boolean = false;
  verifiedFlag: boolean = false;
  showPassword: boolean = false;
  isRegisterPage: boolean = false;
  capsLockActivated: boolean = false;

  constructor(private authSrv: AuthService, private modal: NgbModal, private route: ActivatedRoute, private router: Router) {
    $(document).ready(function () {
      $('#registerModal').modal('show');
      $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })
    });
    this.show = true;
    $(document).on('keydown', function (e: any) {
      if (e.key === "Escape") {
        e.preventDefault();
      }
    });


  }
  validatePassword(password: string): boolean {
    // Aquí puedes implementar tu lógica de validación de contraseña
    // Retorna true si la contraseña cumple con los requisitos, caso contrario, retorna false
    // Ejemplo de validación básica:
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(password);
  }

  ngAfterViewInit() {
    const passwordInput = document.getElementById('passwordR1') as HTMLInputElement;
    const passwordValidationMessage = document.getElementById('passwordValidationMessage') as HTMLInputElement;
    passwordInput.addEventListener('input', () => {
      const password = passwordInput.value.trim();
      console.log(password.length);
      if (password.length === 0) {
        passwordValidationMessage.style.display = 'none';
      } else {
        if (this.validatePassword(password)) {
          passwordValidationMessage.style.display = 'none';
        } else {
          passwordValidationMessage.style.display = 'block';
        }
      }
    }); 
   }




  ngOnInit(): void {
    this.emailR?.valueChanges.subscribe(() => {
      if (!this.emailR?.errors && this.emailR?.value !== null) {
        this.passwordR1?.enable();
      } else {
        this.passwordR1?.disable();
        this.passwordR1?.patchValue(null);
      };
    });
    this.isRegisterPage = this.route.snapshot.url[0].path === 'register';
  }


  ////////////////////////////// REGISTRO ///////////////////////////////////////////
  createFormRegistro() {
    return new FormGroup({
      emailR: new FormControl(null, [Validators.required, Validators.email]),
      passwordR1: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/)
      ]),
    })
  }

  get emailR() { return this.formRegistro?.get('emailR'); }
  get passwordR1() { return this.formRegistro?.get('passwordR1'); }

  async registro() {
    if (this.formRegistro.invalid) { return; }
    if (this.emailR?.value === null) { return; }
    if (this.passwordR1?.value === null) { return; }

    this.authSrv.registerWithEmail(this.emailR?.value, this.passwordR1?.value);
    this.formRegistro.reset();
    this.closeModal();
  }




  public togglePassword() {
    this.showPassword = !this.showPassword;
  }

  closeModal() {
    $(document).ready(() => {
      $('#registerModal').modal('hide');
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    });
    this.modal.dismissAll();
    const modal = document.getElementsByClassName('modal-backdrop fade show')[0];
    if (modal) {
      modal.parentNode!.removeChild(modal);
    }
    document.body.classList.remove('modal-open');
  }

  openLoginForm() {
    this.closeModal();
    this.router.navigateByUrl('/login');
  }

  async registerWithGoogle() {
    try {
      await this.authSrv.registerWithGoogle();
    } catch (error) {
      console.log(error);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.capsLockActivated = event.getModifierState('CapsLock');
  }

}
