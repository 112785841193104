import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as jsonSeller from '../../../abis/Seller.json';
import * as jsonManager from '../../../abis/Manager.json';
import * as jsonErc20 from '../../../abis/ERC20.json';
import { environment } from '../../../../environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-revert-payments',
  templateUrl: './revert-payments.component.html',
  styleUrls: ['./revert-payments.component.css']
})
export class RevertPaymentsComponent {
  formRevertPayments: FormGroup = this.createFormRevertPayments(); 
  ourAddress: string | undefined;
  web3: any | undefined;
  abiSeller: any = jsonSeller;
  abiErc20: any = jsonErc20;
  abiManager: any = jsonManager;

  constructor(private connSrv: ConnectionsService, private afs: AngularFirestore) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => { 
      this.ourAddress = res;
    });
  }

  createFormRevertPayments() {
    return new FormGroup({
      seller: new FormControl(null, [Validators.required])
    })
  }

  get seller() { return this.formRevertPayments?.get('seller'); }

  async revert() {
    if (this.formRevertPayments.invalid) { 
      this.formRevertPayments.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    
    this.afs.collection('investments', ref => ref.where('sellerAddress', '==', this.seller?.getRawValue()).limit(1)).get()
      .subscribe(async (res: any) => {
        if (!res.docs[0].exists) { return; }
        const proyect = res.docs[0].data(); 

        const contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER); 
        const contractStableCoin = new this.web3.eth.Contract(this.abiErc20.abi, proyect.stableCoinAddress); 
        const contractSeller = new this.web3.eth.Contract(this.abiSeller.abi, this.seller?.getRawValue()); 

        const amountRevert = await contractSeller.methods.amountToActiveRevertPayments().call();

        const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
          return Number(block.baseFeePerGas) + 100000000000; 
        });

        contractStableCoin.methods.approve(this.seller?.getRawValue(), amountRevert).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
          .then(async () => {
            contractManager.methods.activeRevertPayments(this.seller?.getRawValue(), this.ourAddress).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
              .then(async () => {
                this.afs.collection('investments').doc(proyect.id).set({
                  proyectStatus: 'Finalizado'
                }, {merge: true});

                Swal.close();
                Swal.fire({
                  icon: 'success',
                  text: 'Los usuarios ya pueden retirar sus inversiones'
                });

                this.formRevertPayments.reset();
              })
              .catch(() => {
                Swal.close();
                Swal.fire({
                  icon: 'error',
                  title: 'Transacción errónea',
                  text: 'Algo ha ido mal, la transacción NO se ha realizado'
                });
              });
          })
          .catch(() => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Transacción errónea',
              text: 'Algo ha ido mal, la transacción NO se ha realizado'
            });
          });
      });
  }
}
