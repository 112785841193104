<div class="pause-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formPause !== undefined || formPause !== null" [formGroup]="formPause">
        <p class="title text-center h4 w-100">Pausar o reiniciar un contrato</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="addressContract" class="form-label">Address del contrato de participaciones (ERC-20):</label>
            <input [class.errorInput]="addressContract?.invalid && (addressContract?.dirty || addressContract?.touched)" formControlName="addressContract" type="text" class="form-control" id="addressContract" placeholder="Introduce la address del contrato">
        </div>
        
        <div class="mb-4 w-100">
            <label for="flag" class="form-label">Pausar o reiniciar:</label>
            <select [class.errorInput]="flag?.invalid && (flag?.dirty || flag?.touched)" formControlName="flag" class="form-select">
                <option value="null" selected>Selecciona un opción</option>
                <option value="true">Pausar</option>
                <option value="false">Reiniciar</option>
            </select>
        </div>
        
        <button [disabled]="formPause.invalid" (click)="setPause()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Actualizar</button>
    </form>
</div>
