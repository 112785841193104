<div class="info-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="form !== undefined || form !== null" [formGroup]="form">
        <p class="title text-center h4 w-100">Obtener Excel de información</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="tokenAddress" class="form-label">Address del token (Participaciones ERC-20)</label>
            <input [class.errorInput]="tokenAddress?.invalid && (tokenAddress?.dirty || tokenAddress?.touched)" formControlName="tokenAddress" type="string" class="form-control" id="tokenAddress" placeholder="Dirección del contrato de las participaciones">
        </div>
        
        <button [disabled]="form.invalid" (click)="getExcel()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Generar excel</button>
    </form>
</div>
