<div class="mt-5">
    <div class="head-info">
        <div>
            <h3>
                Movimientos
            </h3>
            <p style="max-width: 530px;" >
                Aquí puedes consultar los movimientos asociados a los ingresos y retiradas de dinero en tu cartera digital en €. 
                Además, puedes filtrar por el rango de fechas que quieres consultar, importe u ordenarlas por en base a diferentes criterios.
            </p>
        </div>
        <div>
            <img src="assets/img/house.svg" alt="withdraw" class="withdraw-image">
        </div>
    </div>
    
    <div class="mt-4">
        <div class="history-head">
            <div class="containerButtons">
                <button type="button" class="btn btn-success text-center">Entrantes</button>
                <button type="button" class="btn btn-outline-success">Salientes</button>
            </div>
            <div class="transactions-btns">
                <span style="color: #b9b9b9;">Descargar movimientos:</span>
                <a href="https://www.domoblock.io/" target="_blank" style="color: #20a366;">EXCEL</a>
                <a href="https://www.domoblock.io/" target="_blank" style="color: #e3262a;">PDF</a>
            </div>
        </div>

        <div class="filters mt-4">
            <div class="d-flex gap-2" style="align-items: center; width: 250px;">

                <input type="date" class="form-control" id="from" placeholder="Desde">

                <i class="bi bi-arrow-right" style="font-size: 1.5rem; color: #848484;"></i>

                <input type="date" class="form-control" id="to" placeholder="Hasta">
            </div>

            <div class="d-flex gap-3">
                <ng-select 
                    [items]="['Todos', 'Entrantes', 'Salientes']" 
                    [placeholder]="'Tipo de movimiento'" 
                    [multiple]="false" 
                    [searchable]="false" 
                    [clearable]="false" 
                    [hideSelected]="true" 
                    [closeOnSelect]="true" 
                    [notFoundText]="'No se encontraron resultados'" 
                    [appendTo]="'body'">
                </ng-select>

                <input type="number" class="form-control" id="amount" placeholder="Importe">
            </div>
        </div>

        <!-- transactions-history.component.html -->
<div class="container mt-4">
    <div class="row mb-2">
      <div class="col-4 fw-bold text-muted">
        Importe
        <i [class]="'bi ' + getSortIcon('amount')" (click)="sortBy('amount')" style="cursor: pointer;"></i>
      </div>
      <div class="col-4 fw-bold text-muted">Método de inversión</div>
      <div class="col-4 fw-bold text-muted">
        Fecha
        <i [class]="'bi ' + getSortIcon('date')" (click)="sortBy('date')" style="cursor: pointer;"></i>
      </div>
    </div>
    <div class="list-group">
      <div *ngFor="let investment of investments" class="list-group-item list-group-item-action">
        <div class="row align-items-center">
          <div class="col-4">{{ investment.amount | currency:'EUR':'symbol':'1.0-0' }}</div>
          <div class="col-4">{{ investment.method }}</div>
          <div class="col-4">{{ investment.date | date:'dd-MM-yyyy' }}</div>
        </div>
      </div>
    </div>
  </div>

    </div>



</div>
