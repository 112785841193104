<div class="proyectsToChange-container">
    <div *ngIf="proyectsToChange.length == 0" class="noProyectsToChange">
        <p class="m-0 p-4">No hay proyectos para modificar ventas</p>
    </div>

    <p  *ngIf="proyectsToChange.length > 0" class="small w-100 text-white mb-1">* Los proyectos que ya han ejecutado ventas no pueden ser modificados (no se muestran).</p>
    <p  *ngIf="proyectsToChange.length > 0" class="small w-100 text-white">* (Importante) Las variables se atualizan en el servidor y la blockchain.</p>
    <div *ngFor="let proyect of proyectsToChange" class="bradius p-3 text-white mb-4 containerDataForm">
        <h5 class="text-center mb-4 pt-2">{{proyect.title}}</h5>

        <hr class="mb-4">

        <div class="valuesProyectToActivate mb-4">
            <img [src]="proyect.mainImage">
            <div class="mb-4 pl-4 dataRef">
                <p class="small mb-0">Dirrección que recibirá los pagos: "{{proyect.beneficiaryAddress}}"</p>
                <p class="small mb-0">Token usado para los pagos: "{{proyect.stableCoinAddress}}"</p>
                <p class="small mb-0">Token de las participaciones: "{{proyect.tokensAddress}}"</p>
                <p class="small mb-0">Contrato de venta: "{{proyect.sellerAddress}}"</p>
                <hr class="mt-2 mb-2 p-0">
                <p class="small mb-0">Número de participaciones (tokens): {{proyect.amountToSell}}</p>
                <p class="small mb-0">Objetivo de financiación: {{proyect.amountToSell * (proyect.priceToken / 100) | euros}}</p>
                <p class="small mb-0">Precio de la participacion: <span class="resaltar">{{proyect.priceToken / 100}} EUROS</span></p>
                <p class="small mb-0">Compra mínima de tokens: <span class="resaltar">{{proyect.minimumInvestment}}</span></p>
                <p class="small mb-0">Número de horas a la venta: <span class="resaltar">({{proyect.hoursToSell}}) {{toFloor(proyect.endOfSale)}}</span></p>
            </div>
        </div>
        
        <form *ngIf="formDataNewSale !== undefined || formDataNewSale !== null" [formGroup]="formDataNewSale" class="mb-5">
            <div class="mb-4">
                <label for="priceEUR" class="form-label">Cambiar el <span class="resaltar">precio en euros</span>:</label>
                <input [class.errorInput]="priceEUR?.invalid && (priceEUR?.dirty || priceEUR?.touched)" formControlName="priceEUR" type="number" class="form-control" id="priceEUR" placeholder="Introduce la cantidad en EUROS">
                <button (click)="setData(proyect.id, proyect.sellerAddress)" type="button" class="btn btn-success">Cambiar precio</button>
            </div>

            <div class="mb-4">
                <label for="minTokensBuy" class="form-label">Cambiar la <span class="resaltar">cantidad mínima de tokens</span> para comprar:</label>
                <input [class.errorInput]="minTokensBuy?.invalid && (minTokensBuy?.dirty || minTokensBuy?.touched)" formControlName="minTokensBuy" type="number" class="form-control" id="maxTimeHours" placeholder="Introduce la cantidad mínima de tokens en una compra">
                <button (click)="setData(proyect.id, proyect.sellerAddress)" type="button" class="btn btn-success">Cambiar cantidad</button>
            </div>

            <div class="mb-4">
                <label for="maxTimeHours" class="form-label mb-0">Cambiar el  <span class="resaltar">numero de horas</span> a la venta:</label>
                <p class="small w-100 mb-2">* La venta se reinicia con el nuevo número de horas</p>
                <input [class.errorInput]="maxTimeHours?.invalid && (maxTimeHours?.dirty || maxTimeHours?.touched)" formControlName="maxTimeHours" type="number" class="form-control" id="maxTimeHours" placeholder="Introduce el máximo número de horas a la venta">
                <button (click)="setData(proyect.id, proyect.sellerAddress)" type="button" class="btn btn-success">Cambiar horas</button>
            </div>
        </form>

        <div class="accordion" [id]="'accordionParent' + proyect.id">
            <div class="accordion-item">
                <h2 class="accordion-header text-white" [id]="'heading' + proyect.id">
                    <span class="accordion-button text-black" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + proyect.id" aria-expanded="false" [attr.aria-controls]="'collapse' + proyect.id">
                    Más información
                    </span>
                </h2>
                <div [id]="'collapse' + proyect.id" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + proyect.id" [attr.data-bs-parent]="'#accordionParent' + proyect.id">
                    <div class="accordion-body">
                        <ngx-json-viewer [json]="proyect" [expanded]="false"></ngx-json-viewer>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>