<div>
    <div style="position: relative;">
        <div class="metamask-image">
            <img src="assets/img/metamask.svg" alt="metamask">
        </div>
    </div>
    <h2>
        Gestión de wallets
    </h2>
    <p style="max-width: 530px;">
        Si vas a invertir directamente a través de blockchain necesitas disponer de una wallet. 
        Con la wallet podrás comprar tokens, recibir rendimientos y reinvertir. En la parte de 
        inferior podrás visualizar tus wallet ya configuradas y disponibles.
    </p>

    <div class="mt-5">
        <p style="font-weight: bold;">
            Wallets disponibles
        </p>
        <span>
            Puedes tener un máximo de tres.
        </span>

        <div class="mt-4">
            <mat-card class="example-card"  *ngFor="let wallet of walletsWhitelist; let i = index;" class="mb-4">
                <mat-card-content style="padding: 10px;" class="content-card">
                    <span>Wallet {{i+1}}:</span>
                    <div class="d-flex justify-content-between wallet">
                        <p style="font-weight: 500;">{{wallet}}</p>
                        <p class="delete-wallet" (click)="deleteWallet(wallet)">Eliminar</p>
                    </div>
                </mat-card-content>
            </mat-card>

            <div class="example-button-row">
                <button mat-stroked-button  data-bs-toggle="modal" data-bs-target="#exampleModal" [disabled]="walletsWhitelist.length >= 3">Añadir nueva wallet</button>
            </div>
        </div>
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
        <div class="modal-body" style="padding: 3rem !important;">
            <div class="position-relative">
                <div class="position-absolute" style="top: -25px; right:-20px;">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <h4 class="modal-title" id="staticBackdropLabel">Lista blanca o Whitelist</h4>
                
                <div class="d-flex">
                    <p class="mt-4">
                        Solo las wallet añadidas en la whitelist pueden adquirir tokens e 
                        interactuar con la plataforma a través de la blockchain
                    </p>

                    <img src="assets/img/whitelist.svg" alt="whitelist" class="whitelist-image" style="width: 120px;">
                </div>
                

                <div class="mt-4">
                    <button class="btn btn-success text-center" style=" width: 180px; border-radius: 30px;"  (click)="addWallet()">
                        <span style="color: white;">Vincular cuenta</span>
                    </button>
                </div>
          
        </div>
      </div>
    </div>
</div>
