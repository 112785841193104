import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class KycService {
  kycSuccess: any = new BehaviorSubject<boolean>(false);
  kycReview: any = new BehaviorSubject<boolean>(false);
  kycInfo: any = new BehaviorSubject<any>(null);
  kycDuplicated: any = new BehaviorSubject<boolean>(false);

  constructor(private authSrv: AuthService, private afs: AngularFirestore) {
    this.authSrv.loggedIn.subscribe((res: any) => {
      if (res === true) {
        this.authSrv.userAuth.subscribe((res: any) => {
          if (res !== null) {
              this.afs.collection('users').doc(res.uid).valueChanges().subscribe((res: any) => {
              (res !== undefined && res.kyc !== undefined) ? this.kycInfo.next(res.kyc) : this.kycInfo.next(null);
              
              if (res !== undefined && res.kyc !== undefined && res.kyc.result !== undefined) {
                this.kycDuplicated.next(!!res.duplicated);
                (res.kyc.result === 1) ? this.kycSuccess.next(true) : this.kycSuccess.next(false);
                (res.kyc.result === 2) ? this.kycReview.next(true) : this.kycReview.next(false);
              } else { 
                this.kycSuccess.next(false); 
                this.kycReview.next(false);
              }
            });
          } else { this.cleanAll(); }
        });
      } else { this.cleanAll(); }
    });
  }

  cleanAll() {
    this.kycInfo.next(null);
    this.kycSuccess.next(false);
  }
}
