import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-documentation-general',
  templateUrl: './documentation-general.component.html',
  styleUrls: ['./documentation-general.component.css']
})
export class DocumentationGeneralComponent {
  documents: any[] = [];
  formNewDoc: FormGroup = this.createFormNewDoc();
  linkNewDoc: string | null = null;
  id: string | null = null;

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage) {
    this.afs.collection('general-documentation').valueChanges().subscribe((res: any) => {
      if (res !== undefined && res !== null) {
        this.documents = res;
      }
    });
  }

  createFormNewDoc() {
    return new FormGroup({
      title: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      link: new FormControl(null, [Validators.required])
    })
  }

  get title() { return this.formNewDoc?.get('title'); }
  get description() { return this.formNewDoc?.get('description'); }
  get link() { return this.formNewDoc?.get('link'); }

  async newDoc() {
    if (this.formNewDoc.invalid || this.linkNewDoc === null || this.id === null) { 
      this.formNewDoc.markAllAsTouched();
      return; 
    }

    const doc: any = {
      id: this.id,
      title: this.title?.getRawValue(),
      description: this.description?.getRawValue(),
      link: this.linkNewDoc
    }

    this.afs.collection('general-documentation').doc(this.id).set(doc, {merge: true})
      .then(() => {
        this.formNewDoc.reset();
        this.id = null;
        this.linkNewDoc = null;
        
        Swal.fire({
          icon: 'success',
          text: `El documento ha sido subido correctamente`
        });
      })
      .catch(() => { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  uploadFile(e: any) {
    const file = e.target.files[0];
    this.id = this.afs.createId();

    const filePath = `generaldocs/${this.id}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.linkNewDoc = res;
        })
      })).subscribe();
  }

  deleteDoc(doc: any) {
    this.storage.storage.refFromURL(doc.link).delete();
    this.afs.collection('general-documentation').doc(doc.id).delete();
  }
}
