import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Web3 from 'web3';
import Swal from 'sweetalert2';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService {
  web3: any = null;
  get web3Instance() { return this.web3; }

  chainIds: string[] = ['0x89']; // Polygon Mainet '0x89'
  chainId: string | null = null;
  addressUser: any = new BehaviorSubject<string>('');
  admin: any = new BehaviorSubject<boolean>(false);

  constructor() {
    this.init();
  }

  async init() {
    const isMetamaskUnlocked = await window.ethereum?._metamask?.isUnlocked();
    if (typeof window.ethereum !== 'undefined' && isMetamaskUnlocked) {
      console.log('inyectando proveedor metamask')
      this.web3 = new Web3(window.ethereum);
    } else {
      console.log('inyectando proveedor http')
      this.web3 = new Web3(new Web3.providers.HttpProvider('https://prettiest-orbital-arrow.matic.quiknode.pro/d3485a6223541986833f63906b4e455b852750a4/'))
    }
  }

  connect() { this.handleIdChain(); }

  async handleIdChain() {
    const chainId: string = await window.ethereum.request({ method: 'eth_chainId' });
    this.chainId = chainId;

    if (!this.chainIds.includes(chainId)) {
      const params: any = [{
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
          name: 'Polygon',
          symbol: 'MATIC',
          decimals: 18
        },
        rpcUrls: ['https://polygon-rpc.com'],
        blockExplorerUrls: ['https://polygonscan.com']
      }];

      window.ethereum.request({ method: 'wallet_addEthereumChain', params })
        .then(async () => {
          const chainId: string = await window.ethereum.request({ method: 'eth_chainId' });
          this.chainId = chainId;
          this.handleAccounts();
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No se ha podido conectar tu wallet!'
          });
        });
    } else {
      this.handleAccounts();
    }
  }

  async handleAccounts() {
    const accounts: string[] = await window.ethereum.request({ method: 'eth_requestAccounts' });

    if (this.chainId !== null && this.chainIds.includes(this.chainId)) {
      this.addressUser.next(accounts[0]);
    } else {
      this.addressUser.next('');
    }

    this.listening()
  }

  listening() {
    window.ethereum.on('chainChanged', async (res: string) => {
      this.chainId = res;

      if (this.chainId !== null && this.chainIds.includes(this.chainId)) {
        const accounts: string[] = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.addressUser.next(accounts[0]);
      } else {
        this.addressUser.next('');

        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x89' }]
        });
      }
    });

    window.ethereum.on('accountsChanged', (accounts: string[]) => {
      if (this.chainId !== null && this.chainIds.includes(this.chainId)) {
        this.addressUser.next(accounts[0]);
      }
    });
  }
}
