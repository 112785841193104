import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';

@Component({
  selector: 'app-cards-real-estate',
  templateUrl: './cards-real-estate.component.html',
  styleUrls: ['./cards-real-estate.component.css']
})
export class CardsRealEstateComponent implements OnInit{
  cards: any[] | undefined;
  cardsLeft: number[] = [];
  isInversor: boolean = false;
  userWallets: string[] | string = '';
  userID: string = '';
  loadingButtons: boolean = true;

  constructor(
    private afs: AngularFirestore, 
    private authSrv: AuthService,  
    private crf: ChangeDetectorRef,
    private kycSrv: KycService,
    private loaderService: LoaderService, 
    private fns: AngularFireFunctions
    ) {}

  openKycModal() {
    //this.kycSrv.openModalKyc();
  }

  ngOnInit(): void {
    this.getCards();
    this.openKycModal();
    this.getWalletsUser();
  }

  getCards() {
    this.afs.collection('investments', ref => ref.where('hide', '==', false)).valueChanges().subscribe(async(res: any) => {
      if (res !== undefined && res !== null) {
        this.isInversor = false;
        const user = await this.authSrv.userAuth.getValue();
        if(user?.uid){
          console.log(user.uid)
          this.userID = user.uid;
          const result = await this.afs.collection('users').doc(user.uid).get()
          const parseResult:any = await firstValueFrom(result);
          this.isInversor = parseResult?.data().isInversor
        }
        let activo: any[] = [];
        let enestudio: any[] = [];
        let noactivo: any[] = [];
        //let soloinvesores: any[] = [];
        this.cards = undefined;
        this.cardsLeft = [];

        const nowTimeStamp: number = Math.floor(Date.now() / 1000);
        res.forEach((e: any) => {
          /*
          if(e.onlyInversors && !this.isInversor)
            soloinvesores.push(e);*/
          if (e.proyectStatus === 'Activo') {
            (e.endOfSale - nowTimeStamp > 0) ? activo.push(e) : noactivo.push(e);
          }

          if (e.proyectStatus === 'En estudio') {
            enestudio.push(e);
          }

          if (e.proyectStatus !== 'Activo' && e.proyectStatus !== 'En estudio') {
            noactivo.push(e);
          }
        });

        this.cards = activo.concat(enestudio).concat(noactivo).sort((a:any,b:any)=> Number(b.id)-Number(a.id));

        if (this.cards.length % 3 > 0) {
          const restos: number = this.cards.length % 3;
          const positions: number[] = [];

          for (let i = this.cards.length - 1; i > (this.cards.length - 1) - restos; i--) {
            this.cardsLeft.push(this.cards[i]);
            positions.push(i);
          }

          positions.forEach((i: number) => {
            this.cards?.splice(i, 1);
          });
        }

        this.crf.detectChanges();
        document.addEventListener('DOMContentLoaded', () => {
          const cards: HTMLElement = document.getElementById('cards-container') as HTMLElement;
          if (cards) {
            cards.style.display = 'none';
            setTimeout(() => { cards.style.display = 'block'; }, 1);
          }
        });
      }
    });
  }

  async getWalletsUser() {
    setTimeout(async () => {
      const uid = await this.authSrv.userAuth.getValue().uid;
      const result = await this.afs.collection('users').doc(uid).get();
      const userData: any  = (await firstValueFrom(result)).data();
      console.log(userData.walletsWhitelist);
      this.userWallets =  userData.walletsWhitelist ?? '';
      console.log(this.userWallets);
    }, 1000);
  }

  ngAfterViewInit(): void {
    this.waitLoad();
    // Aquí puedes realizar cualquier acción que necesites después de que la vista se haya inicializado completamente.
  }


  async waitLoad(){
    // this.loaderService.showInitialSpinner('Cargando oportunidades de inversión');
    // await setTimeout(() => {
    //   this.loaderService.hideInitialSpinner();
      this.setLoadingButtons();
    // }, 5000);
  }

  setLoadingButtons(){
    this.loadingButtons = true;

    setTimeout(() => {
      this.loadingButtons = false;
    }, 3000);
  }

}
