import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';

@Component({
  selector: 'app-modal-kyc',
  templateUrl: './modal-kyc.component.html',
  styleUrls: ['./modal-kyc.component.css']
})
export class ModalKycComponent implements OnInit {

  currentContent: string = '';
  isLoading: boolean = false;
  isInNeocheck: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ModalKycComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private kycSrv: KycService
  ) { }

  ngOnInit(): void {
    this.kycSrv.currentModalContent.subscribe(content => this.currentContent = content);

    this.kycSrv.dataIsLoading.subscribe(loading => this.isLoading = loading);

    this.kycSrv.currentIsInNeocheck.subscribe(neocheck => this.isInNeocheck = neocheck);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
