<div class="proyectsToActivate-container">
    <div *ngIf="proyectsToActivate.length == 0" class="noProyectsToActive">
        <p class="m-0 p-4">No hay proyectos para activar ventas</p>
    </div>

    <div *ngFor="let proyect of proyectsToActivate" class="bradius p-3 text-white mb-4">
        <h5 class="text-center mb-4 pt-2">{{proyect.title}}</h5>

        <hr class="mb-4">

        <div class="valuesProyectToActivate mb-4">
            <img [src]="proyect.mainImage">
            <div class="mb-4 pl-4">
                <p class="small mb-0">Dirrección que recibirá los pagos: "{{proyect.beneficiaryAddress}}"</p>
                <p class="small mb-0">Token usado para los pagos: "{{proyect.stableCoinAddress}}"</p>
                <p class="small mb-0">Token de las participaciones: "{{proyect.tokensAddress}}"</p>
                <p class="small mb-0">Contrato de venta: "{{proyect.sellerAddress}}"</p>
                <hr class="mt-2 mb-2 p-0">
                <p class="small mb-0">Número de participaciones (tokens): {{proyect.amountToSell}}</p>
                <p class="small mb-0">Objetivo de financiación: {{proyect.amountToSell * (proyect.priceToken / 100) | euros}}</p>
                <p class="small mb-0">Precio de la participacion: <span class="resaltar">{{proyect.priceToken / 100}} EUROS</span></p>
                <p class="small mb-0">Compra mínima de tokens: <span class="resaltar">{{proyect.minimumInvestment}}</span></p>
                <p *ngIf="proyect.hoursToSell == undefined || proyect.hoursToSell == null || proyect.hoursToSell == 0" class="small mb-0">Número de horas a la venta: <span class="resaltar">({{proyect.hoursToSell}}) No activo - Parámetro obligatorio</span></p>
                <p *ngIf="proyect.hoursToSell != undefined && proyect.hoursToSell != null && proyect.hoursToSell > 0" class="small mb-0">Número de horas a la venta: <span class="resaltar">({{proyect.hoursToSell}}) {{toFloor(proyect.hoursToSell)}}</span></p>
            </div>
        </div>

        <form *ngIf="formDataNewSale !== undefined || formDataNewSale !== null" [formGroup]="formDataNewSale">
            <div class="mb-4">
                <label for="priceEUR" class="form-label">Cambiar el <span class="resaltar">precio en euros</span>:</label>
                <input [class.errorInput]="priceEUR?.invalid && (priceEUR?.dirty || priceEUR?.touched)" formControlName="priceEUR" type="number" class="form-control" id="priceEUR" placeholder="Introduce la cantidad en EUROS">
            </div>

            <div class="mb-4">
                <label for="minTokensBuy" class="form-label">Cambiar la <span class="resaltar">cantidad mínima de tokens</span> para comprar:</label>
                <input [class.errorInput]="minTokensBuy?.invalid && (minTokensBuy?.dirty || minTokensBuy?.touched)" formControlName="minTokensBuy" type="number" class="form-control" id="maxTimeHours" placeholder="Introduce la cantidad mínima de tokens en una compra">
            </div>

            <div class="mb-4">
                <label for="maxTimeHours" class="form-label">Cambiar el  <span class="resaltar">numero de horas</span> a la venta:</label>
                <input [class.errorInput]="maxTimeHours?.invalid && (maxTimeHours?.dirty || maxTimeHours?.touched)" formControlName="maxTimeHours" type="number" class="form-control" id="maxTimeHours" placeholder="Introduce el máximo número de horas a la venta">
            </div>

            <div class="buttonsDatActivate mb-4">
                <button (click)="setData(proyect.id)" type="button" class="btn btn-outline-success">Cambiar los datos</button>
                <button 
                    (click)="activateSale(proyect)" 
                    [disabled]="
                        priceEUR?.value != null || 
                        proyect.priceToken == 0 ||
                        maxTimeHours?.value != null || 
                        proyect.hoursToSell == 0 || 
                        minTokensBuy?.value != null ||
                        proyect.minimumInvestment == 0
                    " 
                    class="btn btn-success">
                        Activar venta
                </button>
            </div>
        </form>

        <div class="accordion" [id]="'accordionParent' + proyect.id">
            <div class="accordion-item">
                <h2 class="accordion-header text-white" [id]="'heading' + proyect.id">
                    <span class="accordion-button text-black" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + proyect.id" aria-expanded="false" [attr.aria-controls]="'collapse' + proyect.id">
                    Más información
                    </span>
                </h2>
                <div [id]="'collapse' + proyect.id" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + proyect.id" [attr.data-bs-parent]="'#accordionParent' + proyect.id">
                    <div class="accordion-body">
                        <ngx-json-viewer [json]="proyect" [expanded]="false"></ngx-json-viewer>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>