<div class="documentsTable">
    <form class="bradius mb-5" *ngIf="formNewRol !== undefined || formNewRol !== null" [formGroup]="formNewRol">
        <p>¡Si el usuario ya tiene un rol, primero tienes que eliminarlo abajo!</p>

        <div class="mb-4">
            <label for="email" class="form-label">Email del usuario:</label>
            <input maxlength="60" [class.errorInput]="email?.invalid && (email?.dirty || email?.touched)" formControlName="email" type="text" class="form-control" id="email" placeholder="Introduce el email del usuario">
        </div>

        <div class="mb-4">
            <label for="walletAddress" class="form-label">Wallet del usuario:</label>
            <input maxlength="60" [class.errorInput]="walletAddress?.invalid && (walletAddress?.dirty || walletAddress?.touched)" formControlName="walletAddress" type="text" class="form-control" id="walletAddress" placeholder="Introduce la address que el usuario usará">
        </div>

        <div class="w-100 mb-3">
            <p class="w-100 mb-0">Nuevo rol de usuario:</p>
            <select formControlName="role" class="form-select" aria-label="Default select example">
                <option value="null" selected>Selecciona un rol</option>
                <option value="ADMIN_ROLE">ADMIN_ROLE</option>
                <option value="MANAGER_ROLE">MANAGER_ROLE</option>
                <option value="EDITOR_ROLE">EDITOR_ROLE</option>
            </select>
        </div>

        <button (click)="newRol()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Subir rol de usuario</button>
    </form>

    <table class="table">
        <tr>
            <th *ngFor="let a of administrators" class="bradius">
                <div>
                    <p class="m-0">({{a.displayName}}) {{a.email}} | {{a.role}}</p>
                </div>
                <span (click)="deleteRol(a)" class="h2 m-0 p-0"><i class="bi bi-x-octagon-fill"></i></span>
            </th>
        </tr>
    </table>
</div>