import { Injectable } from '@angular/core';
import { MatDialog, MatDialogState } from '@angular/material/dialog';
import  { ModalKycComponent } from '../../components/modal-kyc/modal-kyc.component';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuthService } from '../auth.service';
import { ModalConnector } from '../modal-connection.service';
import { LoaderService } from '../loader.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';

interface stageData {
  nextStage: number;
  nextStep: number;
  backFromRegister?: boolean;
  isPerson?: boolean;
  isCompany?: boolean;
  addedPayment?: boolean;
}

interface stepData {
  nextStage: number;
  nextStep: number;
  stage1Progress:number;
  stage2Progress:number;
  stage3Progress: number;
}
@Injectable({
  providedIn: 'root'
})
export class KycService {

  private modalContent = new BehaviorSubject<string>('survey');
  currentModalContent = this.modalContent.asObservable();
  private readonly KYCMODAL_ID:string = 'kyc-modal-component'
  private stageData = new BehaviorSubject<any>(null);
  private stepData = new BehaviorSubject<any>(null);
  currentStageData = this.stageData.asObservable();
  currentStepData = this.stepData.asObservable();
  private kycFinished: boolean = false;
  private isLoading = new BehaviorSubject<boolean>(false);
  dataIsLoading = this.isLoading.asObservable();

  private typeRegister = new BehaviorSubject<any>(null);
  currentTypeRegister = this.typeRegister.asObservable();

  private isInNeocheck = new BehaviorSubject<boolean>(false);
  currentIsInNeocheck = this.isInNeocheck.asObservable();

  private paymentAdded = new BehaviorSubject<boolean>(false);
  currentPaymentAdded = this.paymentAdded.asObservable();
  //currenteUserItem = this.stageData.asObservable();
  kycSuccess: any
  kycInfo: any
  kycReview: any

  constructor(
    private matDialog: MatDialog,
    private modalConnectorService: ModalConnector,
    private loadingService: LoaderService,
    private readonly router: Router,
    private authService: AuthService,
    ) { }

  async openModalKyc() {
    this.loadingService.showSpinner("Cargando datos...");
    
      this.authService.isLoggedIn().then(async (logged: boolean) => {
      if (logged ) { 
          const userData = await this.modalConnectorService.getUserData() as any;
          
          const response = await this.modalConnectorService.getRegisterUserData()
          if(userData.kyc){
            switch(userData.kyc.result){
              case 1:{
                if(userData.bankAccount || (userData.walletsWhitelist && userData.walletsWhitelist.length > 0)){
                  this.loadingService.hideSpinner();
                  this.router.navigateByUrl('/oportunidades');
                  return;
                }
                this.setModalContent('stages');
                this.setStageData({nextStage:3, nextStep:1});
                this.loadingService.hideSpinner();
                this.openDialog(this.KYCMODAL_ID);
                return;
              }
              case 2:{
                this.setModalContent('completed');
                this.loadingService.hideSpinner();
                this.openDialog(this.KYCMODAL_ID);
                return;
              }
              
            }
            
          }

          if(response.exists){
            const data = response.data() as any
            console.log(data.stage)
            console.log(data.step)
            let step =  0;
            let stage = 0;
            if(data?.userdata?.inversorType == 'company'){
              const isKYCVideoStep = (data.step == 19 && data.stage == 3);
              step =  isKYCVideoStep ? 19 : data.step;
              stage = isKYCVideoStep ? 3 : data.stage;
              if(data.step >= 19 && data.stage == 3) this.isInNeocheckStep(true)
            }
            if(data?.userdata?.inversorType == 'person'){
              const isKYCVideoStep = (data.step == 14 && data.stage == 3);
              console.log(isKYCVideoStep)
              step =  isKYCVideoStep ? 14 : data.step;
              stage = isKYCVideoStep ? 3 : data.stage;
              if(data.step >= 12 && data.stage == 3) this.isInNeocheckStep(true)
            }
            const stage1Progress = data?.stepsPercentage?.stage1Progress || 20;
            const stage2Progress = data?.stepsPercentage?.stage2Progress || 0;
            const stage3Progress = data?.stepsPercentage?.stage3Progress || 0;
            //const type = data?.userdata?.inversorType;
            
            if(data?.userdata)
              this.setTypeRegister(data?.userdata);

            this.setModalContent(stage == 3 && step <= 4 || stage == 0 && step == 0 ? "stages" : "register");
            if(stage == 3 && step <= 4 ){
              this.setStageData({nextStage:stage, nextStep:step})
            }
            else{
              if(stage == 1 && step == 6) stage++;
              
              console.log(stage)
              console.log(step)
              console.log(stage1Progress)
              console.log(stage2Progress)
              console.log(stage3Progress)
              this.setStepData({nextStage:stage,nextStep:step, stage1Progress:stage1Progress, stage2Progress:stage2Progress, stage3Progress:stage3Progress})
            }
          }

          this.loadingService.hideSpinner();
          this.openDialog(this.KYCMODAL_ID);
        }
      });
        this.loadingService.hideSpinner();

  }


  setModalContent(content: string) {
    this.modalContent.next(content);
  }

  isPaymentAdded(addedPayment: boolean){
    this.paymentAdded.next(addedPayment);
  }

  setStageData(data: stageData) {
    this.stageData.next(data);
  }
  setStepData(data: stepData) {
    this.stepData.next(data);
  }

  setTypeRegister(data:any) {
    this.typeRegister.next(data);
  }

  closeDialog(){
    const dialog = this.matDialog.getDialogById(this.KYCMODAL_ID);
    dialog?.close();
  }

  openDialog(id:string){
    const dialog = this.matDialog.getDialogById(id);
    if(dialog?.getState() == MatDialogState.CLOSED || !dialog)
        this.matDialog.open(ModalKycComponent,{disableClose:true,id,panelClass: 'custom-dialog-container'})
  }

  isInNeocheckStep(state: boolean){
    this.isInNeocheck.next(state);
  }
  /*
  private hideSpinner() {
    this.isLoading.next(false);
  }

  private showSpinner() {
    this.isLoading.next(true);
  } */
}
