<div class="main-container">
    <div class="menu-movil-container">
        <app-menu-movil></app-menu-movil>
    </div>

    <div class="menu-desktop-container">
        <app-menu-desktop></app-menu-desktop>
    </div>

    <div class="navbar-router-container">
        <app-navbar-user></app-navbar-user>
        <router-outlet></router-outlet>
    </div>  
</div>
<app-spinner *ngIf="intialLoading" [loadingText]="initialLoadingMessage"></app-spinner>

<app-spinner *ngIf="isLoading && !intialLoading" [loadingText]="loadingMessage"></app-spinner>
