<div class="new-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formNewNft !== undefined || formNewNft !== null" [formGroup]="formNewNft">
        <p class="title text-center h4 w-100">Editar datos del Proyecto</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="titulo" class="form-label">Título:</label>
            <input [class.errorInput]="titulo?.invalid && (titulo?.dirty || titulo?.touched)" formControlName="titulo" type="text" class="form-control" id="titulo" placeholder="Introduce el titulo del Inmueble">
        </div>

        <div class="mb-4">
            <label for="promotora" class="form-label">Promotora:</label>
            <input [class.errorInput]="promotora?.invalid && (promotora?.dirty || promotora?.touched)" formControlName="promotora" type="text" class="form-control" id="promotora" placeholder="Introduce el nombre de la promotora">
        </div>

        <div class="mb-4">
            <label for="pais" class="form-label">País:</label>
            <input [class.errorInput]="pais?.invalid && (pais?.dirty || pais?.touched)" formControlName="pais" type="text" class="form-control" id="pais" placeholder="Introduce el país del Inmueble">
        </div>

        <div class="mb-4">
            <label for="estadoCCAAProvicia" class="form-label">Estado, CCAA o Provincia:</label>
            <input [class.errorInput]="estadoCCAAProvincia?.invalid && (estadoCCAAProvincia?.dirty || estadoCCAAProvincia?.touched)" formControlName="estadoCCAAProvincia" type="text" class="form-control" id="estadoCCAAProvicia" placeholder="Introduce el estado/CCAA/provincia del Inmueble (Solo uno de estos tres)">
        </div>

        <div class="mb-4">
            <label for="localidad" class="form-label">Localidad (Ciudad, pueblo, ...):</label>
            <input [class.errorInput]="localidad?.invalid && (localidad?.dirty || localidad?.touched)" formControlName="localidad" type="text" class="form-control" id="localidad" placeholder="Introduce la localidad del Inmueble">
        </div>

        <div class="mb-4">
            <label for="barrio" class="form-label">Barrio o distrito:</label>
            <input [class.errorInput]="barrio?.invalid && (barrio?.dirty || barrio?.touched)" formControlName="barrio" type="text" class="form-control" id="barrio" placeholder="Introduce el barrio del Inmueble">
        </div>

        <div class="mb-4">
            <label for="calle" class="form-label">Calle:</label>
            <input [class.errorInput]="calle?.invalid && (calle?.dirty || calle?.touched)" formControlName="calle" type="text" class="form-control" id="calle" placeholder="Introduce la calle del Inmueble">
        </div>

        <div class="mb-4">
            <label for="codigoPostal" class="form-label">Código postal:</label>
            <input [class.errorInput]="codigoPostal?.invalid && (codigoPostal?.dirty || codigoPostal?.touched)" formControlName="codigoPostal" type="text" class="form-control" id="codigoPostal" placeholder="Introduce el código postal del Inmueble">
        </div>

        <div class="mb-4">
            <label for="iframeGoogleMaps" class="form-label">Iframe de Google Maps:</label>
            <input [class.errorInput]="iframeGoogleMaps?.invalid && (iframeGoogleMaps?.dirty || iframeGoogleMaps?.touched)" formControlName="iframeGoogleMaps" type="text" class="form-control" id="iframeGoogleMaps" placeholder="Introduce todo el iframe">
        </div>

        <div class="mb-4">
            <label for="amountToSell" class="form-label">Número de participaciones (tokens):</label>
            <input [class.errorInput]="amountToSell?.invalid && (amountToSell?.dirty || amountToSell?.touched)" formControlName="amountToSell" type="number" class="form-control" id="amountToSell">
        </div>

        <div class="mb-4">
            <label for="nameTokens" class="form-label">Nombre del token (Paticipaciones):</label>
            <input [class.errorInput]="nameTokens?.invalid && (nameTokens?.dirty || nameTokens?.touched)" formControlName="nameTokens" type="text" class="form-control" id="nameTokens" placeholder="Introduce un nombre corto">
        </div>

        <div class="mb-4">
            <label for="symbolTokens" class="form-label">Simbolo del token (Paticipaciones):</label>
            <input [class.errorInput]="symbolTokens?.invalid && (symbolTokens?.dirty || symbolTokens?.touched)" formControlName="symbolTokens" type="text" class="form-control" id="symbolTokens" placeholder="Introduce unas siglas">
        </div>

        <div class="mb-4">
            <label for="stableCoinAddress" class="form-label">Stablecoin con la que se pagará (Por defecto DAI):</label>
            <input [class.errorInput]="stableCoinAddress?.invalid && (stableCoinAddress?.dirty || stableCoinAddress?.touched)" formControlName="stableCoinAddress" type="text" class="form-control" id="stableCoinAddress">
        </div>

        <div class="mb-4">
            <label for="aggregatorStableCoinDolar" class="form-label">Oraculo de Chainlink (StableCoin/USD) (Por defecto DAI):</label>
            <input [class.errorInput]="aggregatorStableCoinDolar?.invalid && (aggregatorStableCoinDolar?.dirty || aggregatorStableCoinDolar?.touched)" formControlName="aggregatorStableCoinDolar" type="text" class="form-control" id="aggregatorStableCoinDolar">
        </div>

        <div class="mb-4">
            <label for="precioToken" class="form-label">Precio del ticket en Euros:</label>
            <input [class.errorInput]="precioToken?.invalid && (precioToken?.dirty || precioToken?.touched)" formControlName="precioToken" type="number" class="form-control" id="precioToken">
        </div>

        <div class="mb-4">
            <label for="inversionMinima" class="form-label">Inversión mínima en tokens:</label>
            <input [class.errorInput]="inversionMinima?.invalid && (inversionMinima?.dirty || inversionMinima?.touched)" formControlName="inversionMinima" type="number" class="form-control" id="inversionMinima">
        </div>

        <div class="mb-4">
            <label for="beneficiaryAddress" class="form-label">Wallet que recibirá los pagos:</label>
            <input [class.errorInput]="beneficiaryAddress?.invalid && (beneficiaryAddress?.dirty || beneficiaryAddress?.touched)" formControlName="beneficiaryAddress" type="text" class="form-control" id="beneficiaryAddress" placeholder="Introduce la wallet que custodia los pagos">
        </div>

        <div class="mb-4">
            <label for="plazoEstimadoEntrega" class="form-label">Plazo estimado de entrega:</label>
            <input [class.errorInput]="plazoEstimadoEntrega?.invalid && (plazoEstimadoEntrega?.dirty || plazoEstimadoEntrega?.touched)" formControlName="plazoEstimadoEntrega" type="text" class="form-control" id="plazoEstimadoEntrega" placeholder="Ejm: 10 semanas">
        </div>

        <div class="mb-4">
            <label for="rentabilidadAnual" class="form-label">Rentabilidad anual:</label>
            <input [class.errorInput]="rentabilidadAnual?.invalid && (rentabilidadAnual?.dirty || rentabilidadAnual?.touched)" formControlName="rentabilidadAnual" type="number" class="form-control" id="rentabilidadAnual">
        </div>

        <div class="mb-4">
            <label for="infoProyecto" class="form-label">Información del proyecto:</label>
            <input [class.errorInput]="infoProyecto?.invalid && (infoProyecto?.dirty || infoProyecto?.touched)" formControlName="infoProyecto" type="text" class="form-control" id="infoProyecto" placeholder="https://www.informaciondelproyecto.com/">
        </div>

        <div class="mb-4 w-100">
            <label for="descripcion" class="form-label">Descripción general del proyecto:</label>
            <textarea [class.errorInput]="descripcion?.invalid && (descripcion?.dirty || descripcion?.touched)" formControlName="descripcion" rows="5" class="form-control" id="descripcion" placeholder="Introduce una descripción general"></textarea>
        </div>

        <div class="form-check mb-4 w-100 toBlockchain">
            <input [class.errorInput]="toBlockchain?.invalid && (toBlockchain?.dirty || toBlockchain?.touched)" class="form-check-input" type="checkbox" value="true" formControlName="toBlockchain" id="toBlockchain">
            <label class="form-check-label mb-0" style="font-size:1rem;" for="toBlockchain">DESPLEGAR EN LA BLOCKCHAIN</label>
        </div>

        <div class="form-check mb-4 w-100 toBlockchain">
            <input [class.errorInput]="onlyInversors?.invalid && (onlyInversors?.dirty || onlyInversors?.touched)" class="form-check-input" type="checkbox" formControlName="onlyInversors" id="onlyInversors">
            <label class="form-check-label mb-0" style="font-size:1rem;" for="onlyInversors">SOLO INVERSORES</label>
        </div>

        <div class="mb-4" *ngIf="onlyInversors?.getRawValue()">
            <label for="percentageToPivateSale" class="form-label">Porcenaje de venta privada:</label>
            <input [class.errorInput]="percentageToPivateSale?.invalid && (percentageToPivateSale?.dirty || percentageToPivateSale?.touched)" formControlName="percentageToPivateSale" type="number" class="form-control" id="percentageToPivateSale">
        </div>

        <div class="mb-4 w-100">
            <label for="atributos" class="form-label">Atributos:</label>
            <textarea [class.errorInput]="atributos?.invalid && (atributos?.dirty || atributos?.touched)" cols="2" formControlName="atributos" class="form-control" id="atributos" placeholder='"Garaje": "Sí", "Habitaciones": 4, "Ascensor": "No", "Metros útiles": 135, ...'></textarea>
            <p class="small mb-0">Nombres de los atributos siempre entrecomillados y valores también salvo los numeros, separación con comas. Entre los nombres y valores usar ':'</p>
        </div>

        <div class="w-100 mb-3 border p-2">
            <p class="w-100 small mb-1">Whitepaper (PDF):</p>
            <input [class.errorInput]="urlWhitepaperPdf?.invalid && (urlWhitepaperPdf?.dirty || urlWhitepaperPdf?.touched)" (change)="uploadWhitepaper($event)" type="file" id="whitePaper" formControlName="urlWhitepaperPdf" placeholder="Subir whitepaper en formato PDF">
            <a *ngIf="whitePaper !== null" [href]="whitePaper" target="_blank" class="docsPDF">{{whitePaper}}</a>
        </div>

        <div class="w-100 mb-3 border p-2">
            <p class="w-100 small mb-1">Dosier(PDF):</p>
            <input [class.errorInput]="urlDosierPdf?.invalid && (urlDosierPdf?.dirty || urlDosierPdf?.touched)" (change)="uploadDosier($event)" type="file" id="dosier" formControlName="urlDosierPdf" placeholder="Subir dosier en formato PDF">
            <a *ngIf="dosier !== null" [href]="dosier" target="_blank" class="docsPDF">{{dosier}}</a>
        </div>

        <div class="w-100 mb-3 border p-2">
            <p class="w-100 small mb-1">Información token (PDF):</p>
            <input [class.errorInput]="urlInfoTokenPdf?.invalid && (urlInfoTokenPdf?.dirty || urlInfoTokenPdf?.touched)" (change)="uploadInfoToken($event)" type="file" id="infoToken" formControlName="urlInfoTokenPdf" placeholder="Subir dosier en formato PDF">
            <a *ngIf="infoToken !== null" [href]="infoToken" target="_blank" class="docsPDF">{{infoToken}}</a>
        </div>

        <div class="w-100 mb-3 border p-2">
            <p class="w-100 small mb-1">Imagen principal (Importante: formato horizontal):</p>
            <input [class.errorInput]="urlImagenPrincipal?.invalid && (urlImagenPrincipal?.dirty || urlImagenPrincipal?.touched)" (change)="uploadImg($event, 'fotoPrincipal')" type="file" id="fotoPrincipal" formControlName="urlImagenPrincipal" placeholder="Subir imagen">
            <div class="w-100">
                <img *ngIf="fotoMain !== null" [src]="fotoMain" class="imgs p-3">
            </div>
        </div>

        <p class="w-100 mb-2 small">Otras imágenes (Opcionales):</p>
        <div class="imagenes">
            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto1')" type="file" id="foto1" name="foto1">
                <div class="w-100">
                    <img *ngIf="foto1 !== null" [src]="foto1" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto2')" type="file" id="foto2" name="foto2">
                <div class="w-100">
                    <img *ngIf="foto2 !== null" [src]="foto2" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto3')" type="file" id="foto3" name="foto3">
                <div class="w-100">
                    <img *ngIf="foto3 !== null" [src]="foto3" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto4')" type="file" id="foto4" name="foto4">
                <div class="w-100">
                    <img *ngIf="foto4 !== null" [src]="foto4" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto5')" type="file" id="foto5" name="foto5">
                <div class="w-100">
                    <img *ngIf="foto5 !== null" [src]="foto5" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto6')" type="file" id="foto6" name="foto6">
                <div class="w-100">
                    <img *ngIf="foto6 !== null" [src]="foto6" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto7')" type="file" id="foto7" name="foto7">
                <div class="w-100">
                    <img *ngIf="foto7 !== null" [src]="foto7" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto8')" type="file" id="foto8" name="foto8">
                <div class="w-100">
                    <img *ngIf="foto8 !== null" [src]="foto8" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto9')" type="file" id="foto9" name="foto9">
                <div class="w-100">
                    <img *ngIf="foto9 !== null" [src]="foto9" class="imgs p-3">
                </div>
            </div>

            <div class="mb-2 border p-2">
                <input (change)="uploadImg($event, 'foto10')" type="file" id="foto10" name="foto10">
                <div class="w-100">
                    <img *ngIf="foto10 !== null" [src]="foto10" class="imgs p-3">
                </div>
            </div>
        </div>
        
        <button [disabled]="loadingFlag" (click)="newNft()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3 mb-3">Actualizar proyecto</button>
        <button (click)="deleteProyect()" class="btn btn-danger deleteProyect">Eliminar proyecto</button>
    </form>
</div>
