import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';
import { ModalKycComponent } from '../modal-kyc.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-kyc-survey',
  templateUrl: './kyc-survey.component.html',
  styleUrls: ['./kyc-survey.component.css']
})
export class KycSurveyComponent implements OnInit {

  otherOption: string = "";
  selectedOption = '';

  constructor(
    private kycSrv: KycService, 
    private authSrv: AuthService,
    public dialogRef: MatDialogRef<ModalKycComponent>,
    private router: Router,
    private afs:AngularFirestore
  ) { }

  ngOnInit(): void {
  }

  async next() {
    console.log("Next")
    const uid = this.authSrv.userAuth.getValue().uid;
    if(!uid){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Usuario sin logear'
      });
      return;
    }
    await this.afs.collection('pre-registerdata').doc(uid).set({comesFrom: this.selectedOption == 'other' ? this.otherOption : this.selectedOption},{merge: true});
    this.kycSrv.setModalContent('stages');
  }

  async logOut() {
    this.dialogRef.close();
    await this.authSrv.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/login');
    this.authSrv.reload();
  }

}
