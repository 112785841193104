import { ChangeDetectorRef, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-real-estate',
  templateUrl: './detail-real-estate.component.html',
  styleUrls: ['./detail-real-estate.component.css']
})
export class DetailRealEstateComponent implements OnInit {
  @Input() info: any | undefined;
  @Input() cantInvest: boolean = true;
  @Output() closeDetailOpenInvest = new EventEmitter<string>();
  keys: any[] | undefined;
  images: string[] | undefined;

  constructor(private crf: ChangeDetectorRef) {}

  closeOpen() {
    this.closeDetailOpenInvest.emit(this.info.id);
  }

  transformDecimals(num: number) {
    const numberFormated = new Intl.NumberFormat('de-DE').format(num);
    return numberFormated;
  }

  getUriIframeGoogle(iframe: string) {
    let googleUrl: any = iframe;
    googleUrl = googleUrl.replace('<iframe src="', '');
    googleUrl = googleUrl.split('" width');
    return googleUrl[0];
  }

  goPolyscan(sellerAddress: string) {
    window.open(`https://polygonscan.com/address/${sellerAddress}`, '_blank');
  }

  ngOnInit(): void {
    this.keys = Object.keys(this.info.attributes);

    let imgs: any[] = [];
    this.info.images.forEach((url: string) => {
      imgs.push(url)
    });
    (imgs.length > 0) ? this.images = imgs : null;

    this.crf.detectChanges();
  }
}
