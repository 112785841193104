<div class="proyectsDataToChange-container">
    <div *ngIf="proyectData === null && proyectsToChange.length == 0" class="noProyectsToEdit">
        <p class="m-0 p-4 text-white">No hay proyectos para editar o borrar</p>
    </div>

    <p *ngIf="proyectData === null && proyectsToChange.length > 0"  class="small w-100 text-white mb-3">* Los proyectos que ya han sido desplegados en la blockchain no pueden ser modificados (no se muestran).</p>
    <ng-container *ngIf="proyectData === null">
        <div *ngFor="let proyect of proyectsToChange" class="bradius p-3 text-white mb-4 containerDataForm">
            <h5 class="text-center mb-4 pt-2">{{proyect.title}}</h5>

            <hr class="mb-4">

            <div class="currentData mb-4">
                <img [src]="proyect.mainImage">
                <div class="dataRef">
                    <p class="small mb-0">{{(proyect.description.length > 350) ? (proyect.description | slice: 0:350) + ' ...' : proyect.description}}</p>
                </div>
            </div>

            <div class="setData">
                <button (click)="editProyect(proyect)" class="btn btn-success">Editar este proyecto</button>
            </div>
        </div>
    </ng-container>

    <app-proyect-set-data *ngIf="proyectData !== null" [proyect]="proyectData" (close)="proyectData = null"></app-proyect-set-data>
</div>