<div class="profile-container">
    <p *ngIf="kycState !== 'KYC implementado correctamente'" class="text-white bradius mb-4">{{kycState}}</p>
    <p *ngIf="!verification" class="text-white bradius mb-4">Email pendiente de verificación</p>

    <form class="p-4 bradius" *ngIf="formDisplayName !== undefined || formDisplayName !== null" [formGroup]="formDisplayName">
        <p class="h3 text-center mb-5 mt-3">Ajustes generales</p>

        <div class="mb-4">
            <label for="displayName" class="form-label text-white">Alias (No modifica el KYC):</label>
            <input formControlName="displayName" type="text" class="form-control" id="displayName" placeholder="Introduce tu alias">
        </div>

        <div class="mb-5">
            <button (click)="setDisplayName()" [disabled]="formDisplayName.invalid" class="btn btn-success pt-3 pb-3 mb-4">Cambiar alias</button>
            <button (click)="resetPasword()" class="btn btn-outline-success pt-3 pb-3">Cambiar contraseña de login</button>
            <button *ngIf="!verification" (click)="verificateEmail()" class="btn btn-outline-success pt-3 pb-3 mt-3">Verificar email</button>
            <div class="form-check form-switch mt-3 position-relative">
                <input class="form-check-input position-absolute" type="checkbox" id="flexSwitchCheckDefault" [checked]="hideBlockchain" (click)="updateHideBlockchain()">
                <label class="form-check-label" for="flexSwitchCheckDefault">Desactivar Funciones Blockchain</label>
            </div>
        </div>
        
        
        <div class="legal">
            <div *ngIf="urlTerminos != null"><a [href]="urlTerminos" target="_blank" download>Términos legales y condiciones</a></div>
            <div *ngIf="urlAviso != null" class="mt-1"><a [href]="urlAviso" target="_blank" download>Aviso legal</a></div>
            <div *ngIf="urlPrivacidad != null" class="mt-1"><a [href]="urlPrivacidad" target="_blank" download>Política de Privacidad</a></div>
            <div *ngIf="urlCookies != null" class="mt-1"><a [href]="urlCookies" target="_blank" download>Política de Cookies</a></div>
            <div class="mt-3"><a href="https://www.jotform.com/form/223313245145042" target="_blank"><p class="btn btn-outline-success mb-xl-0">Contactar con la empresa</p></a></div>
        </div>
    </form>

    <form class="p-4 bradius" *ngIf="(formNewWallet !== undefined || formNewWallet !== null) && !hideBlockchain" [formGroup]="formNewWallet">
        <p class="h3 text-center mb-2 mt-3">Configuración de wallets</p>
        <p class="mb-5 text-center">Añade una dirección donde recibir tus tokens</p>

        <div class="mb-4">
            <label for="wallet" class="form-label text-white">Wallet para usar (Max 3):</label>
            <input formControlName="wallet" type="text" class="form-control" id="wallet" placeholder="Introduce una address">
        </div>

        <div>
            <button (click)="addWallet()" [disabled]="formNewWallet.invalid || walletsWhitelist.length >= 3" class="btn btn-success pt-3 pb-3">Añadir wallet</button>
            <p class="small wallet pb-3 mt-5" *ngFor="let w of walletsWhitelist">{{truncate(w)}} <button (click)="deleteWallet(w)" class="btn btn-danger">Eliminar</button></p>
        </div>
    </form>
</div>