import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euros'
})
export class EurosPipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): unknown {
    if (value === undefined) {
      return null;
    }
    
    const numberFormated = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
    return numberFormated;
  }

}
