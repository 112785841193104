<div class="documentsTable">
    <form class="bradius mb-5" *ngIf="formNewDoc !== undefined || formNewDoc !== null" [formGroup]="formNewDoc">
        <div class="mb-4">
            <label for="title" class="form-label">Título:</label>
            <input maxlength="60" [class.errorInput]="title?.invalid && (title?.dirty || title?.touched)" formControlName="title" type="text" class="form-control" id="title" placeholder="Introduce el título del documento">
        </div>

        <div class="mb-4">
            <label for="description" class="form-label">Descripción:</label>
            <textarea maxlength="900" [class.errorInput]="description?.invalid && (description?.dirty || description?.touched)" formControlName="description" class="form-control" id="description" placeholder="Introduce la descripción del documento"></textarea>
        </div>

        <div class="w-100 mb-3 p-2">
            <p class="w-100 small mb-1">Archivo en formato PDF:</p>
            <input [class.errorInput]="link?.invalid && (link?.dirty || link?.touched)" (change)="uploadFile($event)" type="file" id="link" formControlName="link" placeholder="Subir pdf">
        </div>

        <button [disabled]="linkNewDoc === null || formNewDoc.invalid" (click)="newDoc()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">
            <span *ngIf="(id === null && linkNewDoc === null) || (id !== null && linkNewDoc !== null)">Subir documento</span>
            <span *ngIf="id !== null && linkNewDoc === null" class="parpadea">Cargando archivo...</span>
        </button>
    </form>

    <table class="table">
        <tr>
            <th *ngFor="let doc of documents" class="bradius">
                <div>
                    <i class="bi bi-filetype-pdf h1 mb-0"></i>
                    <p class="m-0"><a [href]="doc.link" target="_blank" class="text-white">{{doc.title}}</a></p>
                </div>
                <span (click)="deleteDoc(doc)" class="h2 m-0 p-0"><i class="bi bi-x-octagon-fill"></i></span>
            </th>
        </tr>
    </table>
</div>
