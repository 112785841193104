import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { KycService } from '../../../services/kyc.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import truncateEthAddress from 'truncate-eth-address';
import * as jsonWhitelist from '../../../abis/WhiteList.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import firebase from 'firebase/compat/app'; 
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  web3: any | undefined;
  ourAddress: string | undefined;
  abiWhitelist: any = jsonWhitelist;
  contractWhitelist: any | undefined;
  kycState: string = 'KYC sin completar';
  verification: boolean = true;
  walletsWhitelist: string[] = [];
  hideBlockchain: boolean = false;
  formDisplayName: FormGroup = new FormGroup({
    displayName: new FormControl(null, [Validators.required])
  });
  formNewWallet: FormGroup = new FormGroup({
    wallet: new FormControl(null, [Validators.required])
  });

  get displayName() { return this.formDisplayName?.get('displayName'); }
  get wallet() { return this.formNewWallet?.get('wallet'); }

  urlTerminos: string | null = null;
  urlAviso: string | null = null;
  urlPrivacidad: string | null = null;
  urlCookies: string | null = null;

  constructor(
    private afs: AngularFirestore, 
    private kycSrv: KycService, 
    private authSrv: AuthService, 
    private connSrv: ConnectionsService,
    private fns: AngularFireFunctions,
    private crf: ChangeDetectorRef
  ) {
    this.getVerification();

    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => { 
      this.ourAddress = res;
    });

    this.contractWhitelist = new this.web3.eth.Contract(this.abiWhitelist.abi, environment.config.ADDRESS_WHITELIST);

    this.kycSrv.kycSuccess.subscribe((res: boolean) => {
      (res) ? this.kycState = 'KYC implementado correctamente' : null;
    });

    this.kycSrv.kycReview.subscribe((res: boolean) => {
      (res) ? this.kycState = 'KYC Pendiente de revisión' : null;
    });
  }

  async getVerification() {
    const info = await this.authSrv.userAuth.getValue();
    (info !== undefined && info.emailVerified !== undefined) ? this.verification = info.emailVerified : null;
  }

  async updateHideBlockchain(){
    this.hideBlockchain = !this.hideBlockchain;
    const uid = await this.authSrv.userAuth.getValue().uid;
    this.afs.collection('users').doc(uid).set({
      hideBlockchain: this.hideBlockchain
    }, {merge: true})
  }

  async setDisplayName() {
    if (this.formDisplayName.invalid) {
      this.formDisplayName.markAsTouched();
      return;
    }

    const uid = await this.authSrv.userAuth.getValue().uid;

    this.afs.collection('users').doc(uid).set({
      displayName: this.displayName?.getRawValue()
    }, {merge: true})
      .then(() => {

        Swal.fire({
          icon: 'success',
          text: `El nombre se ha actualizado con éxito`
        });
      })
      .catch(() => { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  verificateEmail() {
    this.authSrv.sendOtherEmailVerification();
  }

  async addWallet() {
    if (this.formNewWallet.invalid) {
      this.formNewWallet.markAsTouched();
      return;
    }

    if (this.kycState !== 'KYC implementado correctamente') {
      Swal.fire({
        icon: 'error',
        title: 'Erro KYC',
        text: 'Es necesario completar el KYC para poder añadir wallets'
      });

      return;
    }

    if (this.web3.utils.toChecksumAddress(this.ourAddress) !== this.web3.utils.toChecksumAddress(this.wallet?.getRawValue())) {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona la wallet en tu MetaMask',
        text: 'La wallet insertada no coincide con la de tu MetaMask'
      });

      return;
    }

    for (let i = 0; i < this.walletsWhitelist.length; i++) {
      if (
        this.web3.utils.toChecksumAddress(this.walletsWhitelist[i]) === this.web3.utils.toChecksumAddress(this.wallet?.getRawValue())
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Selecciona una wallet nueva',
          text: 'La wallet insertada ya está incluida'
        });
  
        return;
      }
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    await this.web3.eth.personal.sign(
      'Set wallet', 
      this.ourAddress, 
      environment.config.PASSWORD_BACK_AUTH
    )
      .then(async (signature: string) => {
        console.log(signature);
        await this.fns.httpsCallable('newWalletUser')({
          address: this.wallet?.getRawValue(),
          signature: signature
        }).subscribe(async (res: any) => {
          console.log(res);
          if (res === undefined || res.success === undefined || !res.success) {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });

            return;
          }

          const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
            return Number(block.baseFeePerGas) + 100000000000; 
          });

          this.contractWhitelist.methods.setStatusWithSignature(
            this.wallet?.getRawValue(),
            1,
            res.timeStamp,
            res.signature
          ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
            .then(async () => {
              const uid = await this.authSrv.userAuth.getValue().uid;

              if (this.walletsWhitelist.length > 0) {
                this.afs.collection('users').doc(uid).set({
                  walletsWhitelist: firebase.firestore.FieldValue.arrayUnion(this.wallet?.getRawValue())
                }, {merge: true});
              } else {
                this.afs.collection('users').doc(uid).set({
                  walletsWhitelist: [this.wallet?.getRawValue()]
                }, {merge: true});
              }
              

              Swal.close();
              Swal.fire({
                icon: 'success',
                text: `La wallet se ha añadido con éxito`
              });
    
              this.formNewWallet.reset();
            })
            .catch((res: any) => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'Transacción errónea',
                text: 'Algo ha ido mal, la transacción NO se ha realizado'
              });
            }, {timeout: 300000});
        });
      })
      .catch(() => { 
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });

        return; 
      });
  }

  async deleteWallet(addressDelete: string) {
    if (this.web3.utils.toChecksumAddress(this.ourAddress) !== this.web3.utils.toChecksumAddress(addressDelete)) {
      Swal.fire({
        icon: 'error',
        title: 'Selecciona la wallet en tu MetaMask',
        text: 'La wallet insertada no coincide con la de tu MetaMask'
      });

      return;
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    await this.web3.eth.personal.sign(
      'Set wallet', 
      addressDelete, 
      environment.config.PASSWORD_BACK_AUTH 
    )
      .then(async (signature: string) => { 
        await this.fns.httpsCallable('deleteWalletUser')({
          address: addressDelete,
          signature
        }).subscribe(async (res: any) => {
          if (res === undefined || res.success === undefined || !res.success) {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });

            return;
          }

          const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
            return Number(block.baseFeePerGas) + 100000000000; 
          });

          this.contractWhitelist.methods.setStatusWithSignature(
            addressDelete,
            0,
            res.timeStamp,
            res.signature
          ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
            .then(async () => {
              const uid = await this.authSrv.userAuth.getValue().uid;
              this.afs.collection('users').doc(uid).set({
                walletsWhitelist: firebase.firestore.FieldValue.arrayRemove(addressDelete)
              }, {merge: true});
              
              Swal.close();
              Swal.fire({
                icon: 'success',
                text: `La wallet se ha eliminado con éxito`
              });
            })
            .catch(() => {
              Swal.fire({
                icon: 'error',
                title: 'Transacción errónea',
                text: 'Algo ha ido mal, la transacción NO se ha realizado'
              });
            }, {timeout: 300000});
        });
      })
      .catch(() => { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });

        return; 
      });
  }

  truncate(address: string) {
    return truncateEthAddress(address);
  }

  async resetPasword() {
    this.authSrv.resetPassword(await this.authSrv.userAuth.getValue().email);
  }

  async ngOnInit() {
    const uid = await this.authSrv.userAuth.getValue().uid;

    this.afs.collection('users').doc(uid).valueChanges().subscribe((res: any) => {
      this.displayName?.patchValue(res.displayName);

      if (typeof res.walletsWhitelist == 'object') {
        this.walletsWhitelist = res.walletsWhitelist;
      }
      this.hideBlockchain = res.hideBlockchain;
      this.crf.detectChanges();
    });

    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').get().subscribe((res: any) => {
      if (res.exists) {
        this.urlTerminos = res.data().urlTerminos;
        this.urlAviso = res.data().urlAviso;
        this.urlPrivacidad = res.data().urlPrivacidad;
        this.urlCookies = res.data().urlCookies;
      }
    });
  }
}
