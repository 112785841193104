<div class="cards-container scrollable-container" id="cards-container">
    <!-- <button (click)="openKycModal()">aaa</button> -->
    <ng-container *ngFor="let card of cards;">
        <app-card-real-estate  [info]="card" [userInversor]="isInversor" [userWallets]="userWallets" [loadingButtons]="loadingButtons"></app-card-real-estate>
    </ng-container>

    <div class="cardsLeft">
        <ng-container *ngFor="let card of cardsLeft;">
            <app-card-real-estate  [info]="card" [userInversor]="isInversor" [userWallets]="userWallets" [loadingButtons]="loadingButtons"></app-card-real-estate>
        </ng-container>
    </div>

</div>



