import { Component, OnInit } from '@angular/core';

interface Investment {
  amount: number;
  method: string;
  date: string;
}

@Component({
  selector: 'app-transactions-history',
  templateUrl: './transactions-history.component.html',
  styleUrls: ['./transactions-history.component.css']
})

export class TransactionsHistoryComponent implements OnInit {
  investments: Investment[] = [
    { amount: 1000, method: 'Tarjeta de crédito', date: '2024-07-14' },
    { amount: 1000, method: 'Transferencia bancaria', date: '2023-09-21' },
    { amount: 200, method: 'Transferencia bancaria', date: '2023-07-21' }
  ];

  sortColumn: 'amount' | 'date' = 'date';
  sortDirection: 'asc' | 'desc' = 'desc';

  constructor() { }

  ngOnInit(): void {
  }


  sortBy(column: 'amount' | 'date'): void {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'desc';
    }

    this.investments.sort((a, b) => {
      const valueA = column === 'amount' ? a.amount : new Date(a.date).getTime();
      const valueB = column === 'amount' ? b.amount : new Date(b.date).getTime();

      if (valueA < valueB) return this.sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

  getSortIcon(column: 'amount' | 'date'): string {
    if (this.sortColumn !== column) return 'bi-arrow-down-up';
    return this.sortDirection === 'asc' ? 'bi-arrow-up' : 'bi-arrow-down';
  }

}
