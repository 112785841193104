import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(url: string | undefined, ...args: unknown[]): unknown {
    if (url === undefined) { return 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m…a!5e0!3m2!1ses!2sus!4v1681837268420!5m2!1ses!2sus'; }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
