import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleTime: number = 0;
  private idleInterval: any;
  loggedIn: boolean = false;

  constructor(private ngZone: NgZone, private router: Router, private authSrv: AuthService) {
    this.startWatching();

    this.authSrv.loggedIn.subscribe((res: any) => {
      this.loggedIn = res;
    });
  }

  // Método para iniciar la observación del tiempo de inactividad
  private startWatching() {
    // Ejecuta el código fuera de Angular para evitar la detección de cambios innecesaria
    this.ngZone.runOutsideAngular(() => {
      // Establece un intervalo que llama a timerIncrement cada 1 minuto (60000 ms)
      this.idleInterval = setInterval(() => this.timerIncrement(), 60000); // 1 minute

      window.addEventListener('mousemove', () => this.resetIdleTime());
      window.addEventListener('keypress', () => this.resetIdleTime());
    });
  }

  // Método para reiniciar el contador de inactividad
  private resetIdleTime() {
    this.idleTime = 0;
  }

  // Método que incrementa el contador de inactividad
  private timerIncrement() {
    if(!this.loggedIn) {
      console.log('No hay usuario logueado');
      return;
    }
    this.idleTime += 1;
    console.log(`van ${this.idleTime} minutos de inactividad`);
    if (this.idleTime >= 10) { // 30 minutes
      this.ngZone.run(() => {
        this.logOut();
      });
    }
  }

  // Método para cerrar sesión
  async logOut() {
    await this.authSrv.logout();
    this.router.navigateByUrl('/login');
    this.authSrv.reload();
    Swal.fire({
      icon: 'info',
      title: 'Sesión cerrada por inactividad, por favor inicia sesión nuevamente',
      showConfirmButton: false,
      timer: 2500
    })
  }

  // Método que se llama cuando el servicio se destruye
  ngOnDestroy() {
    clearInterval(this.idleInterval);
    window.removeEventListener('mousemove', this.resetIdleTime);
    window.removeEventListener('keypress', this.resetIdleTime);
  }
}
