<div class="loaderContainer">
    <div class="loaderCell">
        <div class="loaderCellInner">
            <div class="loaderElement">
                <mat-spinner diameter="75"></mat-spinner>
            </div>
            <p class="loading-text" *ngIf="loadingText" translate>{{loadingText}}</p>
        </div>
    </div>
</div>
