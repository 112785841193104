<div *ngIf="info !== undefined" class="card bradius">
    <div class="containerImg" [ngStyle]="{'background-image': getUrlMainImage()}">
    </div>
    <div class="card-body">
        <div class="title mb-4 mt-2">
            <img loading="lazy" src="../../../assets/img/grafismo-gris.png">
            <div>
                <p *ngIf="info.nameTokens !== null" class="fw-bold">{{info.nameTokens}}</p>
                <p *ngIf="info.nameTokens === null" class="fw-bold">{{info.location}}</p>
                <p *ngIf="info?.onlyInversors && info?.proyectStatus == 'Activo' && !(eurosSold >= info.amountToSell * (info.priceToken / 100))"
                    class="bradius small estado text-center" style="background-color: #a2cb6c;">
                    Venta privada
                </p>
                <p *ngIf="info?.proyectStatus == 'En estudio'" class="bradius small estado text-center"
                    style="background-color: #FA629C;">{{info?.proyectStatus}}</p>
                <p *ngIf="!info?.onlyInversors && info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) != 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #a2cb6c;">{{info?.proyectStatus}}
                </p>
                <p *ngIf="info?.proyectStatus == 'Activo' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #848484;">Financiado</p>
                <p *ngIf="info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) == 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #848484;">Finalizado</p>
                <p *ngIf="info?.proyectStatus == 'Repartiendo dividendos'" class="bradius small estado text-center"
                    style="background-color: #6EC8CA;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Finalizado'" class="bradius small estado text-center"
                    style="background-color: #848484;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Vendido'" class="bradius small estado text-center"
                    style="background-color: #0093A2;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Financiado'" class="bradius small estado text-center"
                    style="background-color: #848484;">{{info?.proyectStatus}}</p>
            </div>
        </div>
        <div class="mb-3 w-100 containerProgress">
            <div class="pdata">
                <div>
                    <p class="small mb-1">Financiación</p>
                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{eurosSold | euros}}</p>
                </div>
                <div>
                    <p class="small mb-1">Objetivo</p>
                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{info.amountToSell * info.priceToken/100 |
                        euros}}</p>
                </div>
            </div>
            <div class="progress">
                <div *ngIf="eurosSold > 0 && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-label="Animated striped example"
                    [attr.aria-valuenow]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'"
                    aria-valuemin="0" aria-valuemax="100"
                    [style.width.%]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'">
                    {{percentageSold}}%
                </div>
                <div *ngIf="eurosSold >= info.amountToSell * (info.priceToken / 100)"
                    class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                    style="width: 100%">
                    100%
                </div>
            </div>
            <div class="pdata">
                <div>
                    <p class="small mb-1">{{info.numberInvestors}} Inversiones</p>
                </div>
                <div>
                    <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                        class="small mb-1">Inversión completa</p>
                    <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                        class="small mb-1">{{toFloor(info.endOfSale)}}</p>
                </div>
            </div>
        </div>
        <div class="mb-4 w-100 pdatas">
            <div class="pdata1">
                <p class="small w-100" style="font-size: .9rem;">Plazo Estimado</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.estimatedDeliveryTime}}</p>
            </div>
            <div class="pdata2">
                <p class="small w-100" style="font-size: .9rem;">Rentabilidad Anual</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.annualReturn}}%</p>
            </div>
            <div class="pdata3">
                <p class="small w-100" style="font-size: .9rem;">Precio Ticket</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.priceToken/100 | euros}}</p>
            </div>
        </div>
        <div class="containerButtons">
            <button [disabled]="!logged || loadingButtons" type="button" data-bs-toggle="modal"
                [attr.data-bs-target]="'#modalDetail' + info.id" class="btn btn-outline-success mb-2"><i
                    class="bi bi-search"></i><span style="margin-left:10px">{{ loadingButtons ? 'Cargando detalle' : 'Ver detalle'}}</span></button>
            <button (click)="this.setSellerAddress(info.sellerAddress)"
                [disabled]="(!info.blockchain || info?.proyectStatus != 'Activo' || eurosSold >= info.amountToSell * (info.priceToken / 100) || toFloor(info.endOfSale) == 'Tiempo agotado'  || (info?.onlyInversors && !userInversor) ||( info?.onlyInversors && (percentageSoldNumber >= percentageToPrivateSale) && percentageToPrivateSale > 0)) || loadingButtons"
                type="button" data-bs-toggle="modal" [attr.data-bs-target]="'#modalDetail2' + info.id"
                [attr.id]="'modalOpen' + info.id" class="btn btn-success text-center">
                <i class="bi bi-graph-up-arrow"></i><span style="margin-left:10px">{{ loadingButtons ? 'Cargando inversión' : 'Invertir'}}</span>
            </button>
        </div>
    </div>
</div>

<div class="modal fade modalDetail modalDetail1" [attr.id]="'modalDetail' + info.id" tabindex="-1"
    aria-labelledby="modalDetailFullscreenLabel" aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content modal-content1 text-white">
            <div class="modal-header pt-5">
                <h1 class="modal-title fs-4">{{info?.title}}</h1>
                <button type="button" [attr.id]="'modalClose' + info.id" class="btn-close"
                    style="background-color:white !important;" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <app-detail-real-estate (closeDetailOpenInvest)="invertirModal($event)" [info]="info"
                    [cantInvest]="!logged || !info.blockchain || info?.proyectStatus != 'Activo' || eurosSold >= info.amountToSell * (info.priceToken / 100) || toFloor(info.endOfSale) == 'Tiempo agotado'"></app-detail-real-estate>
            </div>
        </div>
    </div>
</div>

<div class="modal fade modalDetail modalSell" [attr.id]="'modalDetail2' + info.id" tabindex="-1"
    *ngIf="!(info?.onlyInversors && !userInversor) && !(info?.onlyInversors && (percentageSoldNumber >= percentageToPrivateSale) && percentageToPrivateSale > 0)"
    aria-labelledby="modalDetailFullscreenLabel" aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-body row paddingModalBody">
                <div class="col-lg-4 col-ms-6 mb-4 mb-md-0 colorDark">
                    <div class="container btnContainer">
                        <button (click)="setAmountBuy(); closeAllInputs()" class="btnClose" id="closeModal" type="button"
                            data-bs-dismiss="modal" aria-label="Close"> <i class="fa fa-arrow-left"
                                aria-hidden="true"></i>
                            VOLVER</button>
                    </div>
                    <div class="container cardContainer">

                        <div class="cardImageModal card bradius">
                            <div class="cardImageContainerModal containerImg" [ngStyle]="{'background-image': getUrlMainImage()}">
                            </div>
                            <div class=" infoCardModal card-body">
                                <div class="title mb-4 mt-2">
                                    <img loading="lazy" src="../../../assets/img/grafismo-gris.png">
                                    <div>
                                        <p *ngIf="info.nameTokens !== null" class="fw-bold">{{info.nameTokens}}</p>
                                        <p *ngIf="info.nameTokens === null" class="fw-bold">{{info.location}}</p>
                                        <p *ngIf="info?.proyectStatus == 'En estudio'"
                                            class="bradius small estado text-center" style="background-color: #FA629C;">
                                            {{info?.proyectStatus}}</p>
                                        <p *ngIf="info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) != 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                                            class="bradius small estado text-center" style="background-color: #a2cb6c;">
                                            {{info?.proyectStatus}}</p>
                                        <p *ngIf="info?.proyectStatus == 'Activo' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                                            class="bradius small estado text-center" style="background-color: #848484;">
                                            Financiado</p>
                                        <p *ngIf="info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) == 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                                            class="bradius small estado text-center" style="background-color: #848484;">
                                            Finalizado</p>
                                        <p *ngIf="info?.proyectStatus == 'Repartiendo dividendos'"
                                            class="bradius small estado text-center" style="background-color: #6EC8CA;">
                                            {{info?.proyectStatus}}</p>
                                        <p *ngIf="info?.proyectStatus == 'Finalizado'"
                                            class="bradius small estado text-center" style="background-color: #848484;">
                                            {{info?.proyectStatus}}</p>
                                        <p *ngIf="info?.proyectStatus == 'Vendido'"
                                            class="bradius small estado text-center" style="background-color: #0093A2;">
                                            {{info?.proyectStatus}}</p>
                                        <p *ngIf="info?.proyectStatus == 'Financiado'"
                                            class="bradius small estado text-center" style="background-color: #848484;">
                                            {{info?.proyectStatus}}</p>
                                    </div>
                                </div>
                                <div class="mb-3 w-100 containerProgress">
                                    <div class="pdata">
                                        <div>
                                            <p class="small mb-1">Financiación</p>
                                            <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{eurosSold | euros}}
                                            </p>
                                        </div>
                                        <div>
                                            <p class="small mb-1">Objetivo</p>
                                            <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{info.amountToSell *
                                                info.priceToken/100 | euros}}</p>
                                        </div>
                                    </div>
                                    <div class="progress">
                                        <div *ngIf="eurosSold > 0 && eurosSold < info.amountToSell * (info.priceToken / 100)"
                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" aria-label="Animated striped example"
                                            [attr.aria-valuenow]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'"
                                            aria-valuemin="0" aria-valuemax="100"
                                            [style.width.%]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'">
                                            {{(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number
                                            : '1.2-2'}}%
                                        </div>
                                        <div *ngIf="eurosSold >= info.amountToSell * (info.priceToken / 100)"
                                            class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" aria-label="Animated striped example" aria-valuenow="100"
                                            aria-valuemin="0" aria-valuemax="100" style="width: 100%">
                                            100%
                                        </div>
                                    </div>
                                    <div class="pdata">
                                        <div>
                                            <p class="small mb-1">{{info.numberInvestors}} Inversiones</p>
                                        </div>
                                        <div>
                                            <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                                                class="small mb-1">Inversión completa</p>
                                            <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                                                class="small mb-1">{{toFloor(info.endOfSale)}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4 w-100 pdatas">
                                    <div class="pdata1">
                                        <p class="small w-100" style="font-size: .9rem;">Plazo Estimado</p>
                                        <p class="mb-1 fw-bold" style="align-self: flex-end;">
                                            {{info.estimatedDeliveryTime}}</p>
                                    </div>
                                    <div class="pdata2">
                                        <p class="small w-100" style="font-size: .9rem;">Rentabilidad Anual</p>
                                        <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.annualReturn}}%</p>
                                    </div>
                                    <div class="pdata3">
                                        <p class="small w-100" style="font-size: .9rem;">Precio Ticket</p>
                                        <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.priceToken/100 |
                                            euros}}</p>
                                    </div>
                                </div>
                                <div class="containerButtons">
                                    <button [disabled]="!logged" type="button" data-bs-toggle="modal"
                                        [attr.data-bs-target]="'#modalDetail' + info.id"
                                        class="btn btn-outline-success mb-2"><i class="bi bi-search"></i><span
                                            style="margin-left:10px">Ver detalle</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-ms- mb-4 mb-md-0 colorWhite movil-nav">
                    <div class="navMargin">
                        <div class="circle" style="    
                        margin-left: -2.3rem;
                        margin-bottom: -2rem;"><span style="margin: 7.5px;">1</span></div>
                        <div class="">
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link" [ngClass]="{'active': activeTab === 'tab1'}"
                                        (click)="setActiveTab('tab1'); closeAllInputs()">CARTERA DIGITAL</a>
                                </li>
                                <li class="nav-item" *ngIf = "hideBlockchain != true">
                                    <a class="nav-link" [ngClass]="{'active': activeTab === 'tab2'}"
                                        (click)="setActiveTab('tab2'); closeAllInputs()">BLOCKCHAIN</a>
                                </li> 
                                <!-- <li class="nav-item">
                                    <a class="nav-link" [ngClass]="{'active': activeTab === 'tab3'}"
                                        (click)="setActiveTab('tab3'); closeAllInputs()">TARJETA DE CREDITO</a>
                                </li> -->
                            </ul>
                        </div>
                    </div>

                    <div class="tab-content movil-options">
                        <!--TAB 1-->
                        <div class="tab-pane" [ngClass]="{'show active': activeTab === 'tab1'}">


                            <form class="buyform"
                                *ngIf="formBuyTransferencia !== undefined || formBuyTransferencia !== null"
                                [formGroup]="formBuyTransferencia">
                                <div class="mb-4">

                                    <div class="marginCantitad">
                                        <div class="circle"><span style="margin: 6px;">2</span></div>
                                        <h5 style="font-weight: 600;" class="titulo-movil">CANTIDAD A INVERTIR</h5>
                                    </div>
                                    <div class="containerBox mb-4">
                                        <div class="row options-mobile">
                                            <div class="col-6">
                                                <div class="box text-center"
                                                    (pointerup)="selectBoxTransferencia(this.numberOfTokens)"
                                                    [class.active]="selectedBoxTransferencia === this.numberOfTokens">
                                                    <h4>{{numberOfTokens}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox1}}€ </label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="box text-center"
                                                    (pointerup)="selectBoxTransferencia(this.numberOfTokens2)"
                                                    [class.active]="selectedBoxTransferencia === this.numberOfTokens2">
                                                    <h4>{{numberOfTokens2}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox2}}€ </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row options-mobile">
                                            <div class="col-6">
                                                <div class="box text-center specialBox"
                                                    (pointerup)="selectBoxTransferencia(this.numberOfTokens3)"
                                                    [class.active]="selectedBoxTransferencia === this.numberOfTokens3">
                                                    <h4>{{numberOfTokens3}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox3}}€ </label>
                                                    <div class="popular"><span style="font-size: 0.6rem;">MÁS POPULAR</span></div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="margin-top: 16px;">
                                                <div class="box text-center"
                                                    (pointerup)="selectBoxTransferencia(this.numberOfTokens4)"
                                                    [class.active]="selectedBoxTransferencia === this.numberOfTokens4">
                                                    <h4>{{numberOfTokens4}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox4}}€ </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box text-center no-mobile"
                                        (pointerup)="selectBoxTransferencia(this.numberOfTokens)"
                                            [class.active]="selectedBoxTransferencia === this.numberOfTokens">
                                            <h4>{{numberOfTokens}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox1}}€ </label>
                                        </div>
                                        <div class="box text-center no-mobile"
                                        (pointerup)="selectBoxTransferencia(this.numberOfTokens2)"
                                            [class.active]="selectedBoxTransferencia === this.numberOfTokens2">
                                            <h4>{{numberOfTokens2}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox2}}€ </label>

                                        </div>
                                        <div class="box text-center specialBox no-mobile"
                                        (pointerup)="selectBoxTransferencia(this.numberOfTokens3)"
                                            [class.active]="selectedBoxTransferencia === this.numberOfTokens3">
                                            <h4>{{numberOfTokens3}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox3}}€ </label>
                                            <div class="popular"><span>MÁS POPULAR</span></div>
                                        </div>
                                        <div class="box text-center no-mobile"
                                        (pointerup)="selectBoxTransferencia(this.numberOfTokens4)"
                                            [class.active]="selectedBoxTransferencia === this.numberOfTokens4">
                                            <h4>{{numberOfTokens4}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox4}}€ </label>

                                        </div>
                                        <div class="divPersonalizar">
                                            <a class="personalizar" (pointerup)="showInputTransferencia()">
                                                Personalizar
                                            </a>
                                        </div>
                                    </div>


                                    <div *ngIf="showTransferencia">
                                        <input formControlName="amountBuyTransferencia" type="number"
                                            class="form-control mb-3" id="amountBuyTransferencia" min="1"
                                            placeholder="Introduce el número de participaciones que deseas adquirir">
                                    </div>

                                    <div class="terms">
                                        <div class="circle"><span style="margin: 5.5px;">3</span></div>
                                        <div *ngIf="info.urlInfoTokenPdf !== undefined && info.urlInfoTokenPdf !== null"
                                            class="form-check mb-4">
                                            <input class="form-check-input" type="checkbox"
                                                (change)="okToLegalTransferencia($event)"
                                                id="checkedOkLegalTransferencia">
                                            <label class="form-check-label" for="checkedOkLegalTransferencia">
                                                Acepto suscribirme <a download [attr.href]="info.urlInfoTokenPdf"
                                                    target="_blank" style="color: #848484;">a las condiciones y términos
                                                    legales del contrato</a>
                                            </label>
                                        </div>
                                    </div>
                                    <p class="small mb-2">Inversión mínima: {{minimumAmount}} participaciones</p>
                                    <p class="small mb-2">Participaciones disponibles: {{maximumAmount}} participaciones
                                    </p>
                                    <div class="buttonflex">
                                        <div class="circle"><span style="margin: 5px;">4</span></div>
                                        <button type="button" class="btn btn-success"
                                            [disabled]="isButtonDisabledTransferencia()"
                                            (click)="investDigitalWallet()"
                                            >
                                            Invertir
                                        </button>
                                    </div>
                                    <div style="display: flex;
                                    gap: 10px;
                                    flex-direction: row;
                                    padding-top: 1rem;">
                                        <i style="padding-top: 5px;" class="fa fa-exclamation-triangle"
                                            aria-hidden="true"></i>
                                        <p>El mínimo de inversión por transferencia bancaria es de 500€
                                        </p>
                                    </div>

                                </div>

                            </form>
                            <hr>


                            <div class="priceData">
                                <div>
                                    <h4 class="sizeTitle">Cantidad a invertir en EUR</h4>
                                    <h2>{{euroShowTransferencia}}€</h2>

                                </div>
                                <div>
                                    <h5 class="sizeTitle">
                                        Retorno estimado
                                    </h5>
                                    <div style="display: flex;
                                    gap: 10px;
                                    flex-direction: row-reverse;
                                    justify-content: flex-end;">
                                        <select [(ngModel)]="opcionSeleccionada"
                                            (change)="calcularRentabilidadTransferencia()" style="border: none;">
                                            <option value="anual">Anual</option>
                                            <option value="mensual">Absoluto</option>
                                        </select>

                                        <div *ngIf="rentabilidadTransferencia">
                                            <h3>{{rentabilidadTransferencia}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="sizeTitle">
                                        Plazo estimado
                                    </h5>
                                    <h3>{{info.estimatedDeliveryTime}}</h3>
                                </div>
                            </div>
                        </div>

                        <!--TAB 2-->
                        <div class="tab-pane" [ngClass]="{'show active': activeTab === 'tab2'}">

                            <form class="buyform" *ngIf="formBuy !== undefined || formBuy !== null"
                                [formGroup]="formBuy">
                                <div class="mb-4">

                                    <div class="dai">
                                        <h6>¿No tienes DAIs?</h6>

                                        <div class="con-tooltip right">
                                            <i class="fa fa-question-circle iQuestion" aria-hidden="true"></i>
                                            <div class="tooltip ">
                                                <span style="color: white; font-size: 18px;">Intercambia aquí tus
                                                    criptomonedas por DAIs. <a style="color: white !important;"
                                                        target="_blank"
                                                        href='https://app.uniswap.org/swap?chain=polygon'>Click
                                                        aquí.</a></span>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="marginCantitad">
                                        <div class="circle"><span style="margin: 6px;">2</span></div>
                                        <h5 style="font-weight: 600;">CANTIDAD A INVERTIR</h5>
                                    </div>


                                    <div class="containerBox mb-4">
                                        <div class="row options-mobile">
                                            <div class="col-6">
                                                <div class="box text-center" 
                                                    (pointerup)="selectBox(this.numberOfTokens)"
                                                    [class.active]="selectedBox === this.numberOfTokens">
                                                    <h4>{{numberOfTokens}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox1}}€ </label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="box text-center"
                                                    (pointerup)="selectBox(this.numberOfTokens2)"
                                                    [class.active]="selectedBox === this.numberOfTokens2">
                                                    <h4>{{numberOfTokens2}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox2}}€ </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row options-mobile">
                                            <div class="col-6">
                                                <div class="box text-center specialBox"
                                                    (pointerup)="selectBox(this.numberOfTokens3)"
                                                    [class.active]="selectedBox === this.numberOfTokens3">
                                                    <h4>{{numberOfTokens3}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox3}}€ </label>
                                                    <div class="popular"><span style="font-size: 0.6rem;">MÁS POPULAR</span></div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="margin-top: 16px;">
                                                <div class="box text-center"
                                                    (pointerup)="selectBox(this.numberOfTokens4)"
                                                    [class.active]="selectedBox === this.numberOfTokens4">
                                                    <h4>{{numberOfTokens4}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox4}}€ </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box text-center no-mobile" (pointerup)="selectBox(this.numberOfTokens)"
                                            [class.active]="selectedBox === this.numberOfTokens">
                                            <h4>{{numberOfTokens}}</h4>
                                            <h5>Tokens</h5>
                                            <label style="color: black;">{{transformDecimalsBox1}}€ </label>
                                        </div>
                                        <div class="box text-center no-mobile" (pointerup)="selectBox(this.numberOfTokens2)"
                                            [class.active]="selectedBox === this.numberOfTokens2">
                                            <h4>{{numberOfTokens2}}</h4>
                                            <h5>Tokens</h5>
                                            <label style="color: black;">{{transformDecimalsBox2}}€ </label>

                                        </div>
                                        <div class="box text-center no-mobile" (pointerup)="selectBox(this.numberOfTokens3)"
                                            [class.active]="selectedBox === this.numberOfTokens3">
                                            <h4>{{numberOfTokens3}}</h4>
                                            <h5>Tokens</h5>
                                            <label style="color: black;">{{transformDecimalsBox3}}€ </label>
                                            <div class="popularBlockchain"><span>MÁS POPULAR</span></div>

                                        </div>
                                        <div class="box text-center no-mobile" (pointerup)="selectBox(this.numberOfTokens4)"
                                            [class.active]="selectedBox === this.numberOfTokens4">
                                            <h4>{{numberOfTokens4}}</h4>
                                            <h5>Tokens</h5>
                                            <label style="color: black;">{{transformDecimalsBox4}}€ </label>

                                        </div>
                                        <div class="divPersonalizar">
                                            <a class="personalizar" (click)="showInput()">
                                                Personalizar
                                            </a>
                                        </div>
                                    </div>


                                    <!-- <div *ngIf="show">
                                        <input formControlName="amountBuy" type="number" class="form-control mb-3"
                                            id="amountBuy" min="1"
                                            placeholder="Introduce el número de tokens que deseas adquirir">

                                    </div> -->

                                    <div *ngIf="showCrypto">
                                        <input formControlName="amountBuy" type="number" class="form-control mb-3" 
                                            id="amountBuy" min="1"
                                            placeholder="Introduce el número de participaciones que deseas adquirir">
                                    </div>

                                    <div class="terms">
                                        <div class="circle"><span style="margin: 5.5px;">3</span></div>
                                        <div *ngIf="info.urlInfoTokenPdf !== undefined && info.urlInfoTokenPdf !== null"
                                            class="form-check mb-4">
                                            <input class="form-check-input" type="checkbox" (change)="okToLegal($event)"
                                                id="okLegal">
                                            <label class="form-check-label" for="oKlegal">
                                                Acepto suscribirme <a download [attr.href]="info.urlInfoTokenPdf"
                                                    target="_blank" style="color: #848484;">a las condiciones y términos
                                                    legales del TOKEN</a>
                                            </label>
                                        </div>
                                    </div>
                                    <p class="small mb-2">Inversión mínima: {{minimumAmount}} Tokens</p>
                                    <p class="small mb-2">Tokens disponibles: {{maximumAmount}} Tokens</p>
                                    <p class="small mb-2" style="word-break: break-all;">Moneda de pago (DAI)</p>

                                    <div class="buttonflex">
                                        <div class="circle"><span style="margin: 4px;">4</span></div>
                                        <button (click)="buy(info.sellerAddress, info.stableCoinAddress);"
                                            [disabled]="isButtonDisabledBlockchain()"
                                            type="button" class="btn btn-success">Invertir</button>
                                    </div>

                                </div>

                            </form>
                            <hr>


                            <div class="priceData">
                                <div>
                                    <h4 class="sizeTitle">Cantidad a invertir en EUR</h4>
                                    <h2>{{euroShowBlockchain}}€</h2>
                                    <p>{{euroShowBlockchain}}€ <i class="fa fa-exchange" aria-hidden="true"></i>
                                        {{daiAmount}} DAI</p>

                                </div>
                                <div>
                                    <h5 class="sizeTitle">
                                        Retorno estimado
                                    </h5>
                                    <div style="display: flex;
                                    gap: 10px;
                                    flex-direction: row-reverse;
                                    justify-content: flex-end;">
                                        <select [(ngModel)]="opcionSeleccionada"
                                            (change)="calcularRentabilidadBlockchain()" style="border: none;">
                                            <option value="anual">Anual</option>
                                            <option value="mensual">Absoluto</option>
                                        </select>

                                        <div *ngIf="rentabilidadBlockchain">
                                            <h3>{{rentabilidadBlockchain}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="sizeTitle">
                                        Plazo estimado
                                    </h5>
                                    <h3>{{info.estimatedDeliveryTime}}</h3>
                                </div>
                            </div>

                        </div>

                        <!--TAB 3-->
                        <div class="tab-pane" [ngClass]="{'show active': activeTab === 'tab3'}">

                            <form class="buyform" *ngIf="formBuyTarjeta !== undefined || formBuyTarjeta !== null"
                                [formGroup]="formBuyTarjeta">
                                <div class="mb-4">

                                    <div class="marginCantitad">
                                        <div class="circle"><span style="margin: 6px;">2</span></div>
                                        <h5 style="font-weight: 600;">CANTIDAD A INVERTIR</h5>
                                    </div>


                                    <div class="containerBox mb-4">
                                        <div class="row options-mobile">
                                            <div class="col-6">
                                                <div class="box text-center"
                                                    (pointerup)="selectBoxTarjeta(this.numberOfTokens)"
                                                    [class.active]="selectedBoxTarjeta === this.numberOfTokens">
                                                    <h4>{{numberOfTokens}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox1}}€ </label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="box text-center"
                                                    (pointerup)="selectBoxTarjeta(this.numberOfTokens2)"
                                                    [class.active]="selectedBoxTarjeta === this.numberOfTokens2">
                                                    <h4>{{numberOfTokens2}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox2}}€ </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row options-mobile">
                                            <div class="col-6">
                                                <div class="box text-center specialBox"
                                                    (pointerup)="selectBoxTarjeta(this.numberOfTokens3)"
                                                    [class.active]="selectedBoxTarjeta === this.numberOfTokens3">
                                                    <h4>{{numberOfTokens3}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox3}}€ </label>
                                                    <div class="popular"><span style="font-size: 0.6rem;">MÁS POPULAR</span></div>
                                                </div>
                                            </div>
                                            <div class="col-6" style="margin-top: 16px;">
                                                <div class="box text-center"
                                                    (pointerup)="selectBoxTarjeta(this.numberOfTokens4)"
                                                    [class.active]="selectedBoxTarjeta === this.numberOfTokens4">
                                                    <h4>{{numberOfTokens4}}</h4>
                                                    <h5>Participaciones</h5>
                                                    <label style="color: black;">{{transformDecimalsBox4}}€ </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box text-center no-mobile" (pointerup)="selectBoxTarjeta(this.numberOfTokens)"
                                            [class.active]="selectedBoxTarjeta === this.numberOfTokens">
                                            <h4>{{numberOfTokens}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox1}}€ </label>
                                        </div>

                                        <div class="box text-center no-mobile" (pointerup)="selectBoxTarjeta(this.numberOfTokens2)"
                                            [class.active]="selectedBoxTarjeta === this.numberOfTokens2">
                                            <h4>{{numberOfTokens2}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox2}}€ </label>
                                        </div>

                                        <div class="box text-center no-mobile" (pointerup)="selectBoxTarjeta(this.numberOfTokens3)"
                                            [class.active]="selectedBoxTarjeta === this.numberOfTokens3">
                                            <h4>{{numberOfTokens3}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox3}}€ </label>
                                            <div class="popular"><span>MÁS POPULAR</span></div>
                                        </div>

                                        <div class="box text-center no-mobile" (pointerup)="selectBoxTarjeta(this.numberOfTokens4)"
                                            [class.active]="selectedBoxTarjeta === this.numberOfTokens4">
                                            <h4>{{numberOfTokens4}}</h4>
                                            <h5>Participaciones</h5>
                                            <label style="color: black;">{{transformDecimalsBox4}}€ </label>

                                        </div>
                                        <div class="divPersonalizar">
                                            <a class="personalizar" (pointerup)="showInputTarjeta()">
                                                Personalizar
                                            </a>
                                        </div>
                                    </div>

                                    <div *ngIf="showTarjeta">
                                        <input formControlName="amountBuyTarjeta" type="number"
                                            class="form-control mb-3" id="amountBuyTarjeta" min="1"
                                            placeholder="Introduce el número de participaciones que deseas adquirir">
                                    </div>

                                    <div class="terms">
                                        <div class="circle"><span style="margin: 5.5px;">3</span></div>
                                        <div *ngIf="info.urlInfoTokenPdf !== undefined && info.urlInfoTokenPdf !== null"
                                            class="form-check mb-4">
                                            <input class="form-check-input" type="checkbox"
                                                (change)="okToLegalTarjeta($event)" id="oKlegalTarjeta">
                                            <label class="form-check-label" for="oKlegalTarjeta">
                                                Acepto suscribirme <a download [attr.href]="info.urlInfoTokenPdf"
                                                    target="_blank" style="color: #848484;">a las condiciones y términos
                                                    legales del contrato</a>
                                            </label>
                                        </div>
                                    </div>

                                    <p class="small mb-2">Inversión mínima: {{minimumAmount}} participaciones</p>
                                    <p class="small mb-2">Participaciones disponibles: {{maximumAmount}} participaciones
                                    </p>

                                    <div class="buttonflex">
                                        <div class="circle"><span style="margin: 4px;">4</span></div>
                                        <button type="button" class="btn btn-success" [disabled]="true" (click)="openModal(info.id)">Invertir</button> <!-- [disabled]="isButtonDisabledTarjeta()" -->
                                    </div>
                                    <div style="display: flex;
                                    gap: 10px;
                                    flex-direction: row;
                                    padding-top: 1rem;">
                                        <i style="padding-top: 5px;" class="fa fa-exclamation-triangle"
                                            aria-hidden="true"></i>
                                        <!-- <p>El mínimo de inversión por tarjeta de credito es de 500€
                                        </p> -->
                                        <p style="font-weight: bold;">DISPONIBLE PROXIMAMENTE...</p>
                                    </div>
                                    <!-- <div style="display: flex;
                                    gap: 10px;
                                    flex-direction: row;
                                    padding-top: 1rem;">
                                        <i style="padding-top: 5px;" class="fa fa-exclamation-triangle"
                                            aria-hidden="true"></i>
                                        <p>La inversión por tarjeta de crédito tiene un cargo del 1%
                                        </p>
                                    </div> -->
                                </div>

                            </form>
                          
                          

                            <hr>


                            <div class="priceData">
                                <div>
                                    <h4 class="sizeTitle">Cantidad a invertir en EUR</h4>
                                    <h2>{{euroShowTarjeta}}€</h2>


                                </div>
                                <div>
                                    <h5 class="sizeTitle">
                                        Retorno estimado
                                    </h5>
                                    <div style="display: flex;
                                    gap: 10px;
                                    flex-direction: row-reverse;
                                    justify-content: flex-end;">
                                        <select [(ngModel)]="opcionSeleccionada"
                                            (change)="calcularRentabilidadTarjeta()" style="border: none;">
                                            <option value="anual">Anual</option>
                                            <option value="mensual">Absoluto</option>
                                        </select>

                                        <div *ngIf="rentabilidadTarjeta">
                                            <h3>{{rentabilidadTarjeta}}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <h5 class="sizeTitle">
                                        Plazo estimado
                                    </h5>
                                    <h3>{{info.estimatedDeliveryTime}}</h3>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>


    </div>

    
<div [id]="dinamicModalCardId(info.id)" class="modal modal1">
    <div class="modal-dialog" style="  display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;">
      <div class="modal-content">
         <div style="    display: flex;
         justify-content: flex-end;
         margin: 1rem;">
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModal(info.id)">
              </button>
         </div>
            <div class="container" style="padding: 0px 30px 30px 30px;">
                <div class="row">
                    <div class="col-lg">
                        <div>
        
                            <div style="display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 10px;
                                background: #FF9600;
                                font-size: 19px;
                                color: white;
                                border-radius: 50px;">
                                <i class="fa fa-credit-card"></i>
                                Tarjeta de crédito                            
                            </div>
                            <div class="tab-content">
        
                                <div id="nav-tab-card" class="tab-pane fade show active">
                                    
        
                                    <!-- <form (ngSubmit)="pagarConTarjeta()" [formGroup]="formTarjeta" role="form" style="padding: 5px;">
                                        


                                        <div class="form-group">
                                          <label for="cardNumber">Número de tarjeta bancaria</label>
                                          <div class="input-group">
                                            <input type="text" name="cardNumber" placeholder="4548810000000003" class="form-control" required formControlName="pan">
                                            <div class="input-group-append">
                                              <span class="input-group-text text-muted">
                                                <i class="fa fa-cc-visa mx-1"></i>
                                                <i class="fa fa-cc-amex mx-1"></i>
                                                <i class="fa fa-cc-mastercard mx-1"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="row">
                                          <div class="col-sm-8">
                                            <div class="form-group">
                                              <label><span class="hidden-xs">Fecha de expiración</span></label>
                                              <div class="input-group">
                                                <input type="number" placeholder="MM" name="month" class="form-control" required min="1" max="12" formControlName="month">
                                                <input type="number" placeholder="YY" name="year" class="form-control" required min="0" max="99" formControlName="year">
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-sm-4">
                                            <div class="form-group mb-4">
                                              <label title="Código de tres dígitos en el reverso de su tarjeta.">CVV <i class="fa fa-question-circle"></i> </label>
                                              <input type="text" placeholder="123" class="form-control" required pattern="[0-9]{3}" formControlName="cvv">
                                            </div>
                                          </div>
                                        </div>
                                        <button type="submit" class="subscribe btn btn-primary btn-block shadow-sm" style="background-color: #0D0D0E !important;">
                                            Pagar
                                          </button>
                                      </form> -->
                                
                                      <div class="my-3" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                                        <h4>Proyecto: {{info.nameTokens}}</h4>
                                        <h4>Número de participaciones: {{euroAmountTarjeta / (this.info.priceToken / 100)}}</h4>
                                        <h4>Total a pagar: {{euroShowTarjeta}}€</h4>
                                    </div>

                                    <form (ngSubmit)="pagoTarjeta()" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="post" target="_blank" #paymentCardForm>
                                        
                                        <input type="hidden" name="Ds_SignatureVersion" [value]="Ds_SignatureVersion" />
                                        <input type="hidden" name="Ds_MerchantParameters" [value]="Ds_MerchantParameters" />
                                        <input type="hidden" name="Ds_Signature" [value]="Ds_Signature" />
                                        <div class="d-flex justify-content-center align-items-center mt-3">
                                            <button type="submit" 
                                                class="subscribe btn btn-primary btn-block shadow-sm" 
                                                style="background-color: #0D0D0E !important;"
                                                [disabled]="!invertirTarjeta">
                                                 {{!invertirTarjeta ? 'Cargando datos de pago...' : 'Invertir'}}
                                              </button>
                                        </div>
                                        
                                    </form>
          
                    
                                
                                </div>
            
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>        
      </div>
    </div>
</div>

<div [id]="dinamicModalWallet(info.id)" class="modal modal1">
    <div class="modal-dialog modal-lg" style="  display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;">
      <div class="modal-content">
         <div style="    display: flex;
         justify-content: flex-end;
         margin: 1rem;">
            <button type="button" class="btn-close" aria-label="Close" (click)="closeModalWallet(info.id)">
              </button>
         </div>
            <div class="container" style="padding: 0px 30px 30px 30px;">
                <div class="row">
                    <div class="col-lg">
                        <div>
        
                            <div class="header-modal-wallet">
                                <i class="fa fa-credit-card"></i>
                                <span class="full-wallet">Elige la wallet donde te gustaría recibir los tokens</span> 
                                <span class="short-wallet">Elige donde te gustaría recibir los tokens</span>                   
                            </div>
                            <div class="tab-content">
        
                                <div id="nav-tab-card" class="tab-pane fade show active">
                        
                                    <div class="my-3" style="border: 1px solid orange; border-radius: 10px; padding: 10px;">
                                        <p>Selecciona una wallet para enviar los tokens</p>
                                        <div class="d-flex flex-column">
                                            <div class="form-check" *ngFor="let wallet of userWallets; let i = index">
                                                <input class="form-check-input" type="radio" name="flexRadioDefault" 
                                                       [id]="'flexRadioDefault' + i" [(ngModel)]="walletSeleted" [value]="wallet">
                                                <label class="form-check-label" [for]="'flexRadioDefault' + i">
                                                    <span class="full-wallet">{{wallet != 'wallet' ? wallet : 'Wallet Domoblock (Recibirás los retornos en €)'}}</span>
                                                    <span class="short-wallet">{{wallet != 'wallet' ? wallet.substr(0,6) + '....' + wallet.substr(-4) : 'Wallet Domoblock (Recibirás los retornos en €)'}}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-center align-items-center">
                                        <button type="submit" class="subscribe btn btn-primary btn-block shadow-sm" 
                                            style="background-color: #0D0D0E !important;" (click)="seletedWallet()">
                                            <!-- Asignar walllet -->
                                             Generar enlace de pago
                                        </button>
                                    </div>
                                    
        
                                
                                </div>
            
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>        
      </div>
    </div>
</div>
  
</div>

<ng-template #modalContent let-modal>
    <div class="modal-body dataTransferencia">
        <div style="    display: flex;
        justify-content: flex-end;
        margin: 1rem;">
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="container">
            <div>
                <h6 style="color: #FF9600; font-weight: bold;">Datos de la transferencia:</h6>
            </div>
            <div class="dataDisplay">
                <div>
                    <p>Banco</p>
                    <p>Beneficiario</p>
                    <p class="iban">IBAN</p>
                    <p class="importe">Importe</p>
                    <p>Concepto</p>
                    <p>SWIFT/BIC</p>

                </div>
                <div>
                    <p style="color: black;">CaixaBank (ES)</p>
                    <p style="color: black;">Domoblock Real Estate Investment S.L</p>
                    <p style="color: black;">ES13 2100 2369 5402 0036 7286</p>
                    <p style="color: black;">{{euroAmountTransferencia}}€</p>
                    <p style="color: black;">{{info.nameTokens}}</p>
                    <p style="color: black;">CAIX ES BB XXX</p>

                </div>
            </div>
            <div style="
            display: flex;
            gap: 10px;
            flex-direction: row;
            padding-top: 1rem;
            align-items: center;">
                <label style="color: black;">Envíanos el comprobante de la transferencia a <a style="color: #0075FF;"
                        href="mailto:info@domoblock.io">inversiones@domoblock.io</a> con el mismo correo que estás
                    registrado.
                </label>
            </div>
            <div style="display: flex;
                gap: 10px;
                flex-direction: row;
                padding-top: 1rem;
                align-items: center;">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <label style="color: black;">La inversión mínima por transferencia bancaria es de 500€.
                </label>
            </div>
            <div style="display: flex;
                    gap: 10px;
                    flex-direction: row;
                    padding-top: 1rem;
                    align-items: center;">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <label style="color: black;">Realizar la transferencia no te garantiza una participación en el proyecto.
                </label>
                <span class="tool"
                    data-tip="Procesaremos las transferencias en el orden en que recibamos los comprobantes; pero en momentos de alta demanda, es posible que la financiación del proyecto se complete antes de que podamos atender todas las solicitudes. En esos casos, te notificaremos por correo electrónico y te reembolsaremos el monto total a tu cuenta bancaria."
                    tabindex="1" data-toggle="tooltip" data-placement="right"> <i class="fa fa-question-circle"
                        aria-hidden="true"></i>
                </span>

            </div>
        </div>

    </div>
   

</ng-template>