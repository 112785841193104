import { Directive } from '@angular/core';

@Directive({
  selector: '[appClickTouch]'
})
export class ClickTouchDirective {

  constructor() { }

}
