<div class="buy-throught-company-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formBuyThroughCompany !== undefined || formBuyThroughCompany !== null" [formGroup]="formBuyThroughCompany">
        <p class="title text-center h4 w-100">Compra de participaciones (tokens) a través de la empresa</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="amountTokens" class="form-label">Cantidad de participaciones (tokens):</label>
            <input [class.errorInput]="amountTokens?.invalid && (amountTokens?.dirty || amountTokens?.touched)" formControlName="amountTokens" type="number" class="form-control" id="amountTokens" placeholder="Introduce la cantidad de tokens">
        </div>
        
        <div class="mb-4">
            <label for="emailUser" class="form-label">Email del usuario:</label>
            <input [class.errorInput]="emailUser?.invalid && (emailUser?.dirty || emailUser?.touched)" formControlName="emailUser" type="text" class="form-control" id="emailUser" placeholder="Introduce el email del usuario">
        </div>

        <div class="mb-4">
            <label for="addressContractSeller" class="form-label">Address del contrato de venta:</label>
            <input [class.errorInput]="addressContractSeller?.invalid && (addressContractSeller?.dirty || addressContractSeller?.touched)" formControlName="addressContractSeller" type="text" class="form-control" id="addressContractSeller" placeholder="Introduce la address del contrato de venta">
        </div>
        
        <button [disabled]="formBuyThroughCompany.invalid" (click)="buy()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Invertir</button>
    </form>
</div>
