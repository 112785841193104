import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import * as jsonSeller from '../../../abis/Seller.json';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-set-data-sale',
  templateUrl: './set-data-sale.component.html',
  styleUrls: ['./set-data-sale.component.css']
})
export class SetDataSaleComponent implements OnInit {
  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  abiSeller: any = jsonSeller;
  contractManager: any | undefined;
  proyectsToChange: any[] = [];
  lastResultProyect: number = 1;
  formDataNewSale: FormGroup = this.createFormDataSale();
  nowTimeStamp: number = Math.floor(Date.now() / 1000);

  constructor(private connSrv: ConnectionsService, private afs: AngularFirestore, private crf: ChangeDetectorRef) {
    this.web3 = this.connSrv.web3Instance;

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER);

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

    this.getProyectsToChange();
  }

  getProyectsToChange() {
    const nowTimeStamp = Date.now() / 1000;

    this.afs.collection('investments', ref => ref
      .where('proyectStatus', '==', 'Activo')
      .where('numberInvestors', '==', 0)
    ).valueChanges().subscribe((res: any) => {
      this.proyectsToChange = [];

      if (res !== undefined && res !== null) { 
        res.forEach(async (proyect: any) => {
          this.proyectsToChange?.push(proyect);
        });
      }
    });
  }

  createFormDataSale() {
    return new FormGroup({
      priceEUR: new FormControl(null),
      maxTimeHours: new FormControl(null),
      minTokensBuy: new FormControl(null)
    })
  }

  get priceEUR() { return this.formDataNewSale?.get('priceEUR'); }
  get maxTimeHours() { return this.formDataNewSale?.get('maxTimeHours'); }
  get minTokensBuy() { return this.formDataNewSale?.get('minTokensBuy'); }

  setData(id: string, contractSeller: string) {
    if (this.formDataNewSale.invalid) { 
      this.formDataNewSale.markAllAsTouched();
      return; 
    }

    if (this.priceEUR?.valid && this.priceEUR.getRawValue() !== null && this.priceEUR.getRawValue() > 0) {
      this.setPrice(id, contractSeller);
    }

    if (this.maxTimeHours?.valid && this.maxTimeHours.getRawValue() !== null && this.maxTimeHours.getRawValue() > 0) {
      this.setHours(id, contractSeller);
    }

    if (this.minTokensBuy?.valid && this.minTokensBuy.getRawValue() !== null && this.minTokensBuy.getRawValue() > 0) {
      this.setMinTokens(id, contractSeller);
    }
  }

  async setPrice(id: string, contractSeller: string) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.setPriceSeller(
      this.priceEUR?.getRawValue() * 100, 
      contractSeller
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        this.afs.collection('investments').doc(id).set({priceToken: this.priceEUR?.getRawValue() * 100}, {merge: true})
          .then(() => {
            this.priceEUR?.reset();
            
            Swal.close();
            Swal.fire({
              icon: 'success',
              text: `El precio ha sido modificado correctamente`
            });
          })
          .catch(() => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });
          });
      })
      .catch(() => { 
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  async setHours(id: string, contractSeller: string) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.setMaxTime(
      this.maxTimeHours?.getRawValue(),
      contractSeller
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        this.afs.collection('investments').doc(id).set({hoursToSell: this.maxTimeHours?.getRawValue()}, {merge: true})
          .then(async () => {
            this.maxTimeHours?.reset();

            const _contractSeller = new this.web3.eth.Contract(this.abiSeller.abi, contractSeller);
            const timeLimit = await _contractSeller.methods.getMaxTime().call();

            await this.afs.collection('investments').doc(id).set({
              endOfSale: timeLimit
            }, {merge: true}).then(() => { this.crf.detectChanges(); });
            
            Swal.close();
            Swal.fire({
              icon: 'success',
              text: `La duración en horas ha sido modificada correctamente`
            });
          })
          .catch(() => { 
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });
          });
      })
      .catch(() => { 
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  async setMinTokens(id: string, contractSeller: string) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.setMinTokensBuy(
      this.formatToWei(this.minTokensBuy?.getRawValue()),
      contractSeller
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        this.afs.collection('investments').doc(id).set({minimumInvestment: this.minTokensBuy?.getRawValue()}, {merge: true})
          .then(() => {
            this.minTokensBuy?.reset();

            Swal.close();
            Swal.fire({
              icon: 'success',
              text: `La cantidad mínima de tokens ha sido modificada correctamente`
            });
          })
          .catch(() => { 
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            });
          });
      })
      .catch(() => { 
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  toFloor(num: number)  {
    if (num === undefined || num === null || num === 0) {
      return 'No activo';
    }

    const secondsDiff =  num - this.nowTimeStamp;
    const minutesDiff = secondsDiff / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;

    if (daysDiff >= 1) {
      if (hoursDiff % 24 > 0) {
        return 'Quedan: ' + Math.floor(daysDiff) + ' días y ' + Math.floor(hoursDiff % 24) + ' minutos';
      } else if (hoursDiff % 24 == 0) {
        return 'Quedan: ' + Math.floor(daysDiff) + ' días';
      }
    }

    if (hoursDiff >= 1) {
      if (minutesDiff % 60 > 0) {
        return 'Quedan: ' + Math.floor(hoursDiff) + ' horas y ' + Math.floor(minutesDiff % 60) + ' minutos';
      } else if (minutesDiff % 60 == 0) {
        return 'Quedan: ' + Math.floor(hoursDiff) + ' horas';
      }
    }

    if (minutesDiff >= 1) {
      if (secondsDiff % 60 > 0) {
        return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos y ' + Math.floor(secondsDiff % 60) + ' segundos';
      } else if (secondsDiff % 60 == 0) {
        return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos';
      }
    }

    if (secondsDiff >= 1) {
      return 'Quedan: ' + Math.floor(secondsDiff) + ' segundos';
    }

    return 'Tiempo agotado';
  }

  ngOnInit(): void {
    setInterval(() => {
      this.nowTimeStamp = Math.floor(Date.now() / 1000);
      this.crf.detectChanges();
    }, 3000);
  }
}
