import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import Swal from 'sweetalert2';
import * as jsonHoldings from '../../abis/TokenHoldings.json';
import * as jsonErc20 from '../../abis/ERC20.json';
import * as jsonDividends from '../../abis/Dividends.json';
import * as jsonSeller from '../../abis/Seller.json';
import { doc } from '@firebase/firestore';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-user-investments',
  templateUrl: './user-investments.component.html',
  styleUrls: ['./user-investments.component.css']
})
export class UserInvestmentsComponent {
  already: boolean = false;
  web3: any | undefined;
  ourAddress: string | undefined;
  holdingsAbi: any = jsonHoldings;
  erc20Abi: any = jsonErc20;
  dividendsAbi: any = jsonDividends;
  sellerAbi: any = jsonSeller;
  investmentsUser: any[] = [];
  investmentsFiltered: any[] = [];
  proyectSelected: any | undefined;
  snapIdSelected: number | undefined;
  currentAmountReinvest: number[] = [0, 0];
  nowTimeStamp: number = Math.floor(Date.now() / 1000);
  newTokens: string = '0';
  addressByCompany: string | null = null;
  userWallets: string[] | undefined;
  map: Map<number, number>;
  sortedArray: any[] = [];
  constructor(private afs: AngularFirestore, private authSrv: AuthService, private connSrv: ConnectionsService) {
    this.authSrv.loggedIn.subscribe((logged: boolean) => {
      if (logged) { this.init() }
    });

    this.web3 = this.connSrv.web3Instance;
    this.map = new Map();

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });
  }

  async init() {
    this.already = false;
    this.investmentsUser = [];
    const uid = await this.authSrv.userAuth.getValue().uid;
    this.afs.collection('user-investments').doc(uid).get().subscribe(async (res: any) => {
      if (res.exists) {
        this.afs.collection('users').doc(uid).get().subscribe((res: any) => {
          if (res.exists) {
            let inversorData = res.data();
            if(!inversorData.isInversor)
              this.afs.collection('users').doc(uid).set({isInversor:true},{merge:true});
          }
        })
        const investments: string[] = Object.keys(res.data());

        await this.afs.collection('users-walletsByCompany').doc(uid).get().subscribe((res: any) => {
          (res.exists) ? this.addressByCompany = res.data().address : null;
        });

        for await (let investment of investments) {
    const resInv:any = await firstValueFrom(await this.afs.collection('investments').doc(investment).get());
    if (resInv.exists) {
              const contractHoldings: any = new this.web3.eth.Contract(this.holdingsAbi.abi, resInv.data().tokensAddress);
              const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, resInv.data().sellerAddress);
              const infoStablecoin: string[] = await contractSeller.methods.stableCoin().call();

              let amountDirect: number = 0;
              let amountCompany: number = 0;

              if (this.ourAddress !== undefined && this.ourAddress !== null && this.ourAddress) {
                //console.log(this.ourAddress)
                amountDirect = this.formatToEther(await contractHoldings.methods.balanceOf(this.ourAddress).call());
              }

              if (this.addressByCompany !== null && this.addressByCompany !== undefined && this.addressByCompany) {
                //console.log(this.addressByCompany);
                amountCompany = this.formatToEther(await contractHoldings.methods.balanceOf(this.addressByCompany).call());
              }

              if (!this.map.has(+resInv.data().NftID)) {
                this.map.set(Number(resInv.data().NftID),this.investmentsUser.length);
                this.investmentsUser.push({
                  ...resInv.data(),
                  symbolStableCoin: infoStablecoin[2],
                  snapshots: [],
                  snapshotsFlag: false,
                  amountTokens: amountDirect,
                  amountTokensCompany: amountCompany,
                  canRevert: await contractSeller.methods.canRevertPayment().call()
                });
                console.log(this.investmentsUser.length);
              }
              const resSnaps:any = await firstValueFrom(await this.afs.collection('snapshots').doc(resInv.data().tokensAddress).get())
              if (resSnaps.exists) {
                if(!this.ourAddress)
                  continue;  
                const snapshots: string[] = Object.keys(resSnaps.data());
                const value = this.map.get(+resInv.data().NftID)
                const contractDividends: any = new this.web3.eth.Contract(this.dividendsAbi.abi, resInv.data().dividendsAddress);

                  const infoStablecoin: string[] = await contractSeller.methods.stableCoin().call();
                  for (let i = 0; i < snapshots.length; i++) {
                    const infoSnap: any = await contractDividends.methods.amountSnapshotsAccount(resInv.data().tokensAddress, this.ourAddress , Number(snapshots[i])).call();
                    console.log(infoSnap[i]);
                    this.investmentsUser[value] = {
                      ...this.investmentsUser[value],
                      snapshotsFlag: true,
                      symbolStableCoin: infoStablecoin[2],
                      snapshots: [
                        ...this.investmentsUser[value].snapshots,
                        {
                          id: Number(snapshots[i]),
                          used: infoSnap[1],
                          amount: this.formatEtherTo2Decimals(this.formatToEther(infoSnap[0])),
                          amountTokens: this.formatToEther(await contractHoldings.methods.balanceOfAt(this.ourAddress , Number(snapshots[i])).call()),
                          retention: resSnaps.data()[snapshots[i]].retentions,
                          timeStamp: Number(resSnaps.data()[snapshots[i]].timeStamp)
                        }
                      ]
                    }
                  }

                  this.already = true;
                }
            } else {
              this.already = true;
            }
     
        }
        this.sortedArray = [...this.investmentsUser].sort((a,b)=>{
          return (+a.NftID) - (+b.NftID)
        })
        this.already=true;
      } else {
        this.already = true;
      }
    });
    // this.already = false;
    // this.investmentsUser = [];
    // const uid = await this.authSrv.userAuth.getValue().uid;
    // this.afs.collection('user-investments').doc(uid).get().subscribe(async (res: any) => {
    //   if (res.exists) {
    //     const investments: string[] = Object.keys(res.data());
    //     await this.afs.collection('users-walletsByCompany').doc(uid).get().subscribe((res: any) => {
    //       (res.exists) ? this.addressByCompany = res.data().address : null;
    //     });
    //     for await (const investment of investments) {      
    //       const resInv:any = await firstValueFrom(await this.afs.collection('investments').doc(investment).get());
    //         if (resInv.exists) {
    //           const investmentData = {
    //             ...resInv.data(),
    //             snapshots: [],
    //             snapshotsFlag: false,
    //             canRevert: false // Assuming canRevertPayment is not needed without wallet connection
    //           };
    //           // Check if wallet is connected
    //           // if (this.walletConnected()) {
    //             await this.populateInvestmentDataWithWalletInfo(investmentData);
    //           // } 
    //           // else {
    //           //   const res: any = await firstValueFrom(await this.afs.collection('users').doc(uid).valueChanges())
    //           //   if (typeof res.walletsWhitelist == 'object') {
    //           //     this.userWallets = res?.walletsWhitelist || [];
    //           //     if (this.userWallets && this.userWallets.length > 0)
    //           //       await this.populateInvestmentDataWithWalletInfo(investmentData);
    //           //   }
    //           // }
    //           if (!this.map.has(+investmentData.NftID)) {
    //             this.map.set(Number(investmentData.NftID), true);
    //             this.investmentsUser.push(investmentData);
    //           }
    //           else
    //             return

    //           const numRef = this.investmentsUser.length - 1;


    //           const resSnaps:any = await firstValueFrom(await this.afs.collection('snapshots').doc(resInv.data().tokensAddress).get())
    //             if (resSnaps.exists) {
    //               const snapshots: string[] = Object.keys(resSnaps.data());

    //               for (let i = 0; i < snapshots.length; i++) {
    //                 this.investmentsUser[numRef] = {
    //                   ...this.investmentsUser[numRef],
    //                   snapshotsFlag: true,
    //                   snapshots: [
    //                     ...this.investmentsUser[numRef].snapshots,
    //                     {
    //                       id: Number(snapshots[i]),
    //                       used: false,
    //                       amount: 0, // Placeholder value, since token balance is not available without wallet connection
    //                       amountTokens: 0, // Placeholder value, since token balance is not available without wallet connection
    //                       retention: resSnaps.data()[snapshots[i]].retentions,
    //                       timeStamp: Number(resSnaps.data()[snapshots[i]].timeStamp)
    //                     }
    //                   ]
    //                 };
    //               }
    //             }
    //         }
    //       }
    //       this.already = true;
    //     } else {
    //     console.log('the investment is:',this.investmentsUser)
    //     this.already = true;
    //   }
    // });
    //console.log(this.investmentsUser)
  }

  async init2() {
    this.already = false;
    this.investmentsUser = [];
    const uid = await this.authSrv.userAuth.getValue().uid;

    this.afs.collection('user-investments').doc(uid).get().subscribe(async (res: any) => {
      if (res.exists) {
        const investments: string[] = Object.keys(res.data());
        
        await this.afs.collection('users-walletsByCompany').doc(uid).get().subscribe((res: any) => {
          (res.exists) ? this.addressByCompany = res.data().address : null;
        });

        for (let i = 0; i < investments.length; i++) {
          console.log(investments.length)
          await this.afs.collection('investments').doc(investments[i]).get().subscribe(async (resInv: any) => {
            if (resInv.exists) {
              const contractHoldings: any = new this.web3.eth.Contract(this.holdingsAbi.abi, resInv.data().tokensAddress);
              const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, resInv.data().sellerAddress);
              const infoStablecoin: string[] = await contractSeller.methods.stableCoin().call();

              let amountDirect: number = 0;
              let amountCompany: number = 0;
              console.log('before ourAddress')
              console.log(this.ourAddress,this.addressByCompany)
              if (this.ourAddress !== undefined && this.ourAddress !== null && this.ourAddress) {
                amountDirect = this.formatToEther(await contractHoldings.methods.balanceOf(this.ourAddress).call());
              }

              if(this.addressByCompany !== null && this.addressByCompany !== undefined && this.addressByCompany){
                amountCompany = this.formatToEther(await contractHoldings.methods.balanceOf(this.addressByCompany).call());
              }

              this.investmentsUser.push({
                ...resInv.data(), 
                symbolStableCoin: infoStablecoin[2], 
                snapshots: [], 
                snapshotsFlag: false,
                amountTokens: amountDirect,
                amountTokensCompany: amountCompany,
                canRevert: await contractSeller.methods.canRevertPayment().call()
              });

              this.already = true;
              
              const numRef = this.investmentsUser.length - 1;
              console.log('getting numRef')
              
              this.afs.collection('snapshots').doc(resInv.data().tokensAddress).get().subscribe(async (resSnaps: any) => {
                if (resSnaps.exists) {
                  if(!this.ourAddress)
                    return;
                  const snapshots: string[] = Object.keys(resSnaps.data());

                  const contractDividends: any = new this.web3.eth.Contract(this.dividendsAbi.abi, resInv.data().dividendsAddress);

                  const infoStablecoin: string[] = await contractSeller.methods.stableCoin().call();
                  
                  for (let i = 0; i < snapshots.length; i++) {
                    const infoSnap: any = await contractDividends.methods.amountSnapshotsAccount(resInv.data().tokensAddress, this.ourAddress, Number(snapshots[i])).call();

                    this.investmentsUser[numRef] = {
                      ...this.investmentsUser[numRef],
                      snapshotsFlag: true,
                      symbolStableCoin: infoStablecoin[2], 
                      snapshots: [
                        ...this.investmentsUser[numRef].snapshots, 
                        {
                          id: Number(snapshots[i]), 
                          used: infoSnap[1], 
                          amount: this.formatToEther(infoSnap[0]), 
                          amountTokens: this.formatToEther(await contractHoldings.methods.balanceOfAt(this.ourAddress, Number(snapshots[i])).call()),
                          retention: resSnaps.data()[snapshots[i]].retentions, 
                          timeStamp: Number(resSnaps.data()[snapshots[i]].timeStamp)
                        }
                      ]
                    }
                  }

                  this.already = true;
                }
              });
            } else {
              this.already = true;
            }
          });
        }
      } else {
        this.already = true;
      }
    });
  }


  walletConnected() {
    // Verificar si la instancia de web3 existe y si hay una cuenta activa
    if (this.web3 && this.web3.eth && this.web3.eth.accounts && this.web3.eth.accounts.length > 0) {
      return true;
    }

    return false;
  }

  async populateInvestmentDataWithWalletInfo(investmentData: any) {
    const contractHoldings = new this.web3.eth.Contract(this.holdingsAbi.abi, investmentData.tokensAddress);
    const contractSeller = new this.web3.eth.Contract(this.sellerAbi.abi, investmentData.sellerAddress);
    const infoStablecoin = await contractSeller.methods.stableCoin().call();
    if (this.ourAddress) {
      investmentData.amountTokens = this.formatToEther(await contractHoldings.methods.balanceOf(this.ourAddress).call());
    }

    if (this.userWallets && this.userWallets.length > 0) {
      for await (const wallet of this.userWallets) {
        const amount = this.formatToEther(await contractHoldings.methods.balanceOf(wallet).call());
        if (Number(amount) > 0) {
          investmentData.amountTokens = amount;
          break;
        }
      }
    }

    if (this.addressByCompany !== null) {
      investmentData.amountTokensCompany = this.formatToEther(await contractHoldings.methods.balanceOf(this.addressByCompany).call());
    }

    investmentData.canRevert = await contractSeller.methods.canRevertPayment().call();
  }

  getDate(num: number) {
    const date = new Date(num);
    let month = date.getMonth() + 1;
    if (month == 13) { month = 12; }
    return date.getDate() + '/' + month + '/' + date.getFullYear();
  }

  async filterInvestments(proyect: any, snapId: number) {
    this.investmentsFiltered = [];
    this.proyectSelected = proyect;
    this.snapIdSelected = snapId;
    this.currentAmountReinvest = [0, 0];


    const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, proyect.sellerAddress);
    const info: string[] = await contractSeller.methods.stableCoin().call();

    this.afs.collection(
      'investments', ref => ref.where('stableCoinAddress', '==', proyect.stableCoinAddress).where('proyectStatus', '==', 'Activo'))
      .valueChanges().subscribe(async (res: any) => {
        if (res === undefined || res === null) { return; }

        for (let i = 0; i < res.length; i++) {
          const item = res[i];

          let eurosSold = 0;

          const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, item.sellerAddress);

          await contractSeller.methods.tokenAmountSold().call().then((res: number) => {
            eurosSold = this.formatToEther(res) * (item.priceToken / 100);
          });

          let flag: boolean = false;
          if (item.blockchain && item?.proyectStatus === 'Activo' && eurosSold < (item.amountToSell * (item.priceToken / 100)) && this.toFloor(item.endOfSale) !== 'Tiempo agotado') {
            for (let i = 0; i < this.investmentsFiltered.length; i++) {
              (this.investmentsFiltered[i].id === item.id) ? flag = true : null;
            }
            (!flag) ? this.investmentsFiltered.push(item) : null;
          }
        }

        for (let i = 0; i < this.investmentsFiltered.length; i++) {
          const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, this.investmentsFiltered[i].sellerAddress);

          await contractSeller.methods.tokenAmountSold().call().then((res: number) => {
            const eurosSold: number = this.formatToEther(res) * (this.investmentsFiltered[i].priceToken / 100);
            this.investmentsFiltered[i] = { ...this.investmentsFiltered[i], eurosSold: eurosSold, symbol: info[2] }
          });
        }
      });
  }

  infoTokensCompany() {
    Swal.fire({
      icon: 'info',
      text: 'Los beneficios correspondientes de estos tokens serán depositados por transferencia bancaria desde Domoblock. Estos tokens no son tenidos en consideración para los reclamos en esta sección, unicamente se muestran los saldos correspondientes de los tokens adquiridos directamente.'
    })
  }

  async returnFounds(proyect: any, id: string) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const contractTokens: any = new this.web3.eth.Contract(this.erc20Abi.abi, proyect.tokensAddress);
    const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, proyect.sellerAddress);

    const amountTokens: number = await contractTokens.methods.balanceOf(this.ourAddress).call();

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => {
      return Number(block.baseFeePerGas) + 100000000000;
    });

    contractTokens.methods.approve(proyect.sellerAddress, amountTokens).send({ from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei')) })
      .then(() => {
        contractSeller.methods.revertPayment().send({ from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei')) })
          .then(async () => {
            const element = document.getElementById(id) as HTMLElement;
            element.setAttribute('disabled', '');

            const uid = await this.authSrv.userAuth.getValue().uid;
            this.afs.collection('user-investments').doc(uid).get().subscribe((res: any) => {
              if (res.exists) {
                let newObj = res.data();
                delete newObj[proyect.id];
                this.afs.collection('user-investments').doc(uid).set(newObj);
              }
            });

            Swal.close();
            Swal.fire({
              icon: 'success',
              text: `Retiro realizado con éxito`
            });
          })
          .catch(() => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo ha ido mal!'
            })
          });
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        })
      });
  }

  async reinvest(proyectReinvest: any) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const contractDividends: any = new this.web3.eth.Contract(this.dividendsAbi.abi, this.proyectSelected.dividendsAddress);

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => {
      return Number(block.baseFeePerGas) + 100000000000;
    });

    await contractDividends.methods.claimDividends(
      this.proyectSelected.tokensAddress,
      proyectReinvest.sellerAddress,
      this.formatToWei(this.currentAmountReinvest[0]),
      this.snapIdSelected
    ).send({ from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei')) })
      .then(async () => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          text: `Inversión realizada con éxito`
        })
          .then(() => {
            this.clean();

            this.investmentsFiltered = [];
            this.proyectSelected = undefined;
            this.snapIdSelected = undefined;
            this.currentAmountReinvest = [0, 0];
            this.newTokens = '0';

            this.init();
          });
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        })
      });
  }

  async getOut(proyectSelected: any, snapIdSelected: number) {
    if (this.web3 !== null) {
      Swal.fire({
        title: 'Procesando...',
        html: 'No cierres esta pantalla!!',
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      });
      const contractDividends = new this.web3.eth.Contract(this.dividendsAbi.abi, proyectSelected.dividendsAddress);
      const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => {
        return Number(block.baseFeePerGas) + 100000000000;
      });

      await contractDividends.methods.claimDividends(
        proyectSelected.tokensAddress,
        '0x0000000000000000000000000000000000000000',
        0,
        snapIdSelected
      ).send({ from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei')) })
        .then(() => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            text: `Inversión realizada con éxito`
          });
        })
        .catch(() => {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo ha ido mal!'
          })
        });
    } else {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debe tener metamask instalado y conectado a la red de de polygon para poder realizar esta acción!',

      })
    }


  }

  formatToEther(num: number) {
    const BN = this.web3.utils.BN;

    try {
      return this.web3.utils.fromWei(new BN(num).toString(), 'ether');
    } catch (error) {
      return this.web3.utils.fromWei(num.toString(), 'ether');
    }
  }

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  transformDecimals(num: number) {
    const numberFormated = new Intl.NumberFormat('de-DE').format(num);
    return numberFormated;
  }

  toFloor(num: number) {
    if (num === undefined || num === null || num === 0) {
      return 'No activo';
    }

    const secondsDiff = num - this.nowTimeStamp;
    const minutesDiff = secondsDiff / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;

    if (daysDiff >= 1) {
      return 'Quedan: ' + Math.floor(daysDiff) + ' días';
    }

    if (hoursDiff >= 1) {
      return 'Quedan: ' + Math.floor(hoursDiff) + ' horas';
    }

    if (minutesDiff >= 1) {
      return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos';
    }

    if (secondsDiff >= 1) {
      return 'Quedan: ' + Math.floor(secondsDiff) + ' segundos';
    }

    return 'Tiempo agotado';
  }

  async onChangeReinvest(proyect: any) {
    const amount = this.currentAmountReinvest[0];

    if (amount === 0) {
      this.newTokens = '0';
    } else {
      const contractSeller: any = new this.web3.eth.Contract(this.sellerAbi.abi, proyect.sellerAddress);

      const tokens = await contractSeller.methods.amountTokenStableCoin(this.formatToWei(amount)).call();
      this.newTokens = this.formatEtherTo2Decimals(this.formatToEther(tokens));
    }
  }

  clean() {
    const modalsBtnClose: any = document.getElementsByClassName('modal-backdrop');
    for (let i = 0; i < modalsBtnClose.length; i++) {
      const element = modalsBtnClose[i] as HTMLElement;
      element.style.display = 'none';
    }
  }

  formatEtherTo2Decimals(num: any) {
    (!num.includes('.')) ? num += '.00' : null;
    num = num.split('.');
    if (num[1].length < 2) {
      const _length = 2 - num[1].length;
      for (let i = 0; i < _length; i++) {
        num[1] += '0';
      }
    } else {
      num[1] = num[1].substring(0, 2);
    }
    return num[0] + '.' + num[1];
  }
}