import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-set-kyc',
  templateUrl: './set-kyc.component.html',
  styleUrls: ['./set-kyc.component.css']
})
export class SetKycComponent {
  users: any[] = [];

  constructor(private afs: AngularFirestore) { 
    this.afs.collection('users', ref => ref.where('kyc.result', '==', 2)).valueChanges().subscribe((res: any) => {
      if (res !== undefined && res !== null) {
        this.users = res;
      }
    });
  }

  aprove(uid: string) {
    this.afs.collection('users').doc(uid).set({
      kyc: {result: 1}
    }, {merge: true});
  }

  reject(uid: string) {
    this.afs.collection('users').doc(uid).set({
      kyc: {result: 0}
    }, {merge: true});
    this.deleteData(uid);
  }

  deleteData(uid:string){
    this.afs.collection('pre-registerdata').doc(uid).delete();
  }
}
