<div class="new-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formDocs !== undefined || formDocs !== null" [formGroup]="formDocs">
        <p class="title text-center h4 w-100">Cambiar la URLs de los documentos legales</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="urlTerminos" class="form-label">Url de los Términos Legales:</label>
            <input [class.errorInput]="urlTerminos?.invalid && (urlTerminos?.dirty || urlTerminos?.touched)" formControlName="urlTerminos" type="text" class="form-control" id="urlTerminos" placeholder="Introduce la url de los términos legales">
        </div>

        <div class="mb-4">
            <label for="urlAviso" class="form-label">Url del Aviso Legal:</label>
            <input [class.errorInput]="urlAviso?.invalid && (urlAviso?.dirty || urlAviso?.touched)" formControlName="urlAviso" type="text" class="form-control" id="urlAviso" placeholder="Introduce la url del aviso legal">
        </div>

        <div class="mb-4">
            <label for="urlPrivacidad" class="form-label">Url de la Política de Privacidad:</label>
            <input [class.errorInput]="urlPrivacidad?.invalid && (urlPrivacidad?.dirty || urlPrivacidad?.touched)" formControlName="urlPrivacidad" type="text" class="form-control" id="urlPrivacidad" placeholder="Introduce la url de la política de privacidad">
        </div>

        <div class="mb-4">
            <label for="urlCookies" class="form-label">Url de la Política de Cookies:</label>
            <input [class.errorInput]="urlCookies?.invalid && (urlCookies?.dirty || urlCookies?.touched)" formControlName="urlCookies" type="text" class="form-control" id="urlCookies" placeholder="Introduce la url de la política de cookies">
        </div>
        
        <button (click)="setDocs()" type="button" class="btn btn-success w-100 pt-3 pb-3">Actualizar URLs</button>
    </form>
</div>