<div class="admin-container">
    <div class="containerGoAdmin">
        <button (click)="goAdmin()" *ngIf="goAdminFlag" class="btn btn-success goAdmin bradius"><i class="bi bi-arrow-left-circle"></i>Administración</button>
    </div>

    <ul *ngIf="!goAdminFlag">
        <li class="text-center"><button (click)="goNewProyect()" class="btn btn-success w-100">Crear un proyecto nuevo</button></li>
        <li class="text-center"><button (click)="goProyectsSetData()" class="btn btn-success w-100">Editar o borrar un proyecto</button></li>
        <li class="text-center"><button (click)="goHideProyect()" class="btn btn-success w-100">Ocultar un proyecto</button></li>
        <li class="text-center"><button (click)="goNewSaleSeller()" class="btn btn-success w-100">Activar una venta nueva</button></li>
        <li class="text-center"><button (click)="goSetDataSeller()" class="btn btn-success w-100">Cambiar los datos de una venta</button></li>
        <li class="text-center"><button (click)="goAddDividends()" class="btn btn-success w-100">Añadir dividendos a un proyecto</button></li>
        <li class="text-center"><button (click)="goInfoDividends()" class="btn btn-success w-100">Ver información de dividendos</button></li>
        <li class="text-center"><button (click)="goRevertPayments()" class="btn btn-success w-100">Activar devolución de inversión</button></li>
        <li class="text-center"><button (click)="goSetRol()" class="btn btn-success w-100">Quitar o poner roles de usuario</button></li>
        <li class="text-center"><button (click)="goBuyThroughCompamy()" class="btn btn-success w-100">Invertir a través de la empresa</button></li>
        <li class="text-center"><button (click)="goBuyWithoutPay()" class="btn btn-success w-100">Inversión con wallet externa</button></li>
        <li class="text-center"><button (click)="goSetWhitelist()" class="btn btn-success w-100">Quitar o poner en Whitelist</button></li>
        <li class="text-center"><button (click)="goForcedTransfer()" class="btn btn-success w-100">Forzar transferencia</button></li>
        <li class="text-center"><button (click)="goSetPause()" class="btn btn-success w-100">Pausar/Reiniciar contrato</button></li>
        <li class="text-center"><button (click)="goSetKyc()" class="btn btn-success w-100">Gestionar KYC</button></li>
        <li class="text-center"><button (click)="goDocumentation()" class="btn btn-success w-100">Añadir o quitar documentos generales</button></li>
        <li class="text-center"><button (click)="goUriYoutube()" class="btn btn-success w-100">Editar vídeo de Youtube</button></li>
        <li class="text-center"><button (click)="goDocsLegal()" class="btn btn-success w-100">Editar documentos legales</button></li>
        <li class="text-center"><button (click)="goAdminWallet()" class="btn btn-success w-100">Admin wallet</button></li>
    </ul>

    <app-new-proyect *ngIf="newProyectFlag"></app-new-proyect>
    <app-proyects-set-data *ngIf="proyectsSetDataFlag"></app-proyects-set-data>
    <app-new-sale-seller *ngIf="newSaleSellerFlag"></app-new-sale-seller>
    <app-set-data-sale *ngIf="setDataSellerFlag"></app-set-data-sale>
    <app-add-dividends *ngIf="addDividendsFlag"></app-add-dividends>
    <app-documentation-general *ngIf="documentationGeneralFlag"></app-documentation-general>
    <app-uri-youtube *ngIf="uriYoutubeFlag"></app-uri-youtube>
    <app-set-rol *ngIf="setRolFlag"></app-set-rol>
    <app-buy-through-company *ngIf="buyThroughCompanyFlag"></app-buy-through-company>
    <app-set-whitelist *ngIf="setWhitelistFlag"></app-set-whitelist>
    <app-forced-transfer *ngIf="forcedTransferFlag"></app-forced-transfer>
    <app-set-pause *ngIf="setPauseFlag"></app-set-pause>
    <app-set-kyc *ngIf="setKycFlag"></app-set-kyc>
    <app-revert-payments *ngIf="revertPaymentsFlag"></app-revert-payments>
    <app-info-dividends *ngIf="infoDividendsFlag"></app-info-dividends>
    <app-buy-without-pay *ngIf="buyWithoutPayFlag"></app-buy-without-pay>
    <app-hide-proyect  *ngIf="hideProyectFlag"></app-hide-proyect>
    <app-docs-legal *ngIf="docsLegalFlag"></app-docs-legal>
    <app-admin-wallet *ngIf="adminWalletFlag"></app-admin-wallet>
</div>