<div class="set-whitlist-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formSetWhitelist !== undefined || formSetWhitelist !== null" [formGroup]="formSetWhitelist">
        <p class="title text-center h4 w-100">Gestión directa del estado de una wallet en la whitelist</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="addressWallet" class="form-label">Wallet:</label>
            <input [class.errorInput]="addressWallet?.invalid && (addressWallet?.dirty || addressWallet?.touched)" formControlName="addressWallet" type="text" class="form-control" id="addressWallet" placeholder="Introduce la address del usuario">
        </div>
        
        <div class="mb-4 w-100">
            <label for="status" class="form-label">Activar, inactivar o congelar:</label>
            <select [class.errorInput]="status?.invalid && (status?.dirty || status?.touched)" formControlName="status" class="form-select">
                <option value="null" selected>Selecciona un opción</option>
                <option value="0">Inactivar</option>
                <option value="1">Activar</option>
                <option value="2">Congelar</option>
            </select>
        </div>
        
        <button [disabled]="formSetWhitelist.invalid" (click)="setWhitelist()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Actualizar</button>
    </form>
</div>