import { Component, HostListener, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { ModalRegisterComponent } from '../modal-register/modal-register.component';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.css']
})
export class ModalLoginComponent implements OnInit {
  showPassword: boolean = false;
  formLogin: FormGroup = this.createFormLogin();
  loginFlag: boolean = true;
  registroFlag: boolean = false;
  verifiedFlag: boolean = false;
  isLoginPage: boolean = false;
  capsLockActivated: boolean = false;

  constructor(private modal: NgbModal, private authSrv: AuthService, private route: ActivatedRoute, private router: Router) {

    $(document).ready(function () {
      $('#loginModal').modal('show');
      $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })
    });
    $(document).on('keydown', function(e: any){
      if (e.key === "Escape") {
        e.preventDefault();
      }
});

  }



  public togglePassword() {
    this.showPassword = !this.showPassword;
  }

  ngOnInit(): void {
    this.email?.valueChanges.subscribe(() => {
      if (!this.email?.errors && this.email?.value !== null) {
        this.password?.enable();
      } else {
        this.password?.disable();
        this.password?.patchValue(null);
      };
    });
    this.isLoginPage = this.route.snapshot.url[0].path === 'login';
    
  }




  closeModal() {
    $(document).ready(() => {
      $('#loginModal').modal('hide');
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    });
    this.modal.dismissAll();
    const modal = document.getElementsByClassName('modal-backdrop fade show')[0];
    if (modal) {
      modal.parentNode!.removeChild(modal);
    }
    document.body.classList.remove('modal-open');
  }

  openRegisterForm() {
    this.closeModal();
    this.router.navigateByUrl('/register');

  }


  ////////////////////////////// LOGIN ///////////////////////////////////////////////
  createFormLogin() {
    return new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(20),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
      ])
    })
  }

  get email() { return this.formLogin?.get('email'); }
  get password() { return this.formLogin?.get('password'); }

  async login() {
    if (this.formLogin.invalid) { return; }
    if (this.email?.value === null) { return; }
    if (this.password?.value === null) { return; }

    this.authSrv.login(this.email?.value, this.password?.value);
    this.formLogin.reset();
    this.closeModal();
  }

  async loginGoogle() {
    await this.authSrv.signInWithGoogle();
  }

  /** RESET PASSWORD */
  ////////////////////////////////////////////////////////////////////////////////////

  async resetPassword() {
    this.closeModal();
    const { value: email } = await Swal.fire({
      title: '',
      input: 'email',
      inputLabel: 'Necesitamos tu email',
      inputPlaceholder: 'Introduce tu email'
    })

    if (email) {
      this.authSrv.resetPassword(email);
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.capsLockActivated = event.getModifierState('CapsLock');
  }
}
