<div class="datail-container">
    <div class="modal-header pt-5 margin-tittle">
        <h1 class="modal-title fs-4 pl-4 mb-3">{{info?.title}}</h1>
        <button type="button" class="btn-close modal-close-btn mb-3" aria-label="Close" (click)="closeOpen()"></button>
    </div>
    <br>
    <div id="carouselControls" class="gallery carousel slide mb-5" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img [src]="info.mainImage" class="d-block">
            </div>
            <div *ngFor="let img of images" class="carousel-item active">
                <img [src]="img" class="d-block">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div class="location mb-5 pt-md-5">
        <div>
            <iframe 
                    [src]="getUriIframeGoogle(info.iframeGoogleMaps) | safe"
                    width="100%"
                    height="300"
                    frameborder="0"
                    style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0">
            </iframe>
        </div>
        <div>
            <p class="mb-1" style="text-align:right;">{{info.location}}, {{info.stateCCAAProvince}} ({{info?.country}})</p>
            <p style="text-align:right;">{{info?.street}}, c.p: {{info?.cp}}</p>
            <hr>
            <div [innerHTML]="info.description"></div>
        </div>
    </div>

    <p *ngIf="keys !== undefined && keys.length > 0" class="h4 mb-2">Información del proyecto:</p>
    <div *ngIf="keys !== undefined && keys.length > 0" class="atributos bradius mb-5">
        <p *ngFor="let key of keys">
            <span>{{key}}</span>: {{info.attributes[key]}}
        </p>
    </div>

    <p *ngIf="info.urlWhitepapperPdf !== null || info.urlDosierPdf !== null || info.urlInfoTokenPdf !== null" class="h4 mb-2">Documentación:</p>
    <div *ngIf="info.urlWhitepapperPdf !== null || info.urlDosierPdf !== null" class="documentation mb-5">
        <a *ngIf="info.urlWhitepapperPdf !== null" [href]="info.urlWhitepapperPdf" target="_blank"><i class="bi bi-filetype-pdf h2 mr-4" style="color:#f09502;"></i>Whitepaper</a>
        <a *ngIf="info.urlDosierPdf !== null" [href]="info.urlDosierPdf" target="_blank"><i class="bi bi-filetype-pdf h2 mr-4" style="color:#f09502;"></i>Dossier</a>
        <a *ngIf="info.urlInfoTokenPdf !== null" [href]="info.urlInfoTokenPdf" target="_blank"><i class="bi bi-filetype-pdf h2 mr-4" style="color:#f09502;"></i>Acuerdo de suscripción al token</a>
    </div>

    <hr *ngIf="!cantInvest" class="mb-5">

    <div class="dataSale mb-5" 
        [class.dataSaleLeft]="cantInvest">
        <div class="p-4 w-100" style="height: 100%; border: none;">
            <p class="title w-100">Informacion financiera</p>
            <p class="w-100">Nombre del token: <span>{{info.nameTokens}} ({{info.symbolTokens}})</span></p>
            <p class="w-100">Número de tokens: <span>{{transformDecimals(info.amountToSell)}}</span></p>
            <p class="w-100">Precio del ticket: <span>{{info.priceToken/100 | euros}}</span></p>
            <p class="w-100">Rentabilidad anual esperada: <span>{{info.annualReturn}}%</span></p>
            <p class="w-100 mb-4">Plazo estimado1: <span>{{info.estimatedDeliveryTime}}</span></p>
            <div (click)="goPolyscan(info.tokensAddress)" class="logoPolyscan mb-4 bradius"><img src="../../../assets/img/polygon-logo.svg"><p class="mb-0">Ver el token en Polygonscan</p></div>
            <button *ngIf="!cantInvest" class="btn btn-success closeOpen" (click)="invest()">Invertir</button>
        </div>
        <!-- <div *ngIf="!cantInvest"  class="p-4 bradius">
            <div>
                <p class="title w-100">¿Quieres invertir por transferencia bancaria?</p>
                <p class="fw-bold mb-4">Hazlo en dos simples pasos:</p>
                <p>1. Haz una transferencia bancaria</p>
                <div style="padding-left: 8px !important;">
                    <p class="small mb-1">Beneficiario: Domoblock Real Estate Investment S.L</p>
                    <p class="small mb-1">IBAN: <span>ES13 2100 2369 5402 0036 7286</span></p>
                    <p class="small">Concepto:  {{info.title}}</p>
                </div>
                <p class="mb-4">2. Envía justificante de la transferencia a info@domoblock.io</p>
                <p class="small ">a) ¿Tienes wallet propia? </p>
                <p class="small mb-4 custom-margin ">Envía el justificante junto con tu dirección wallet </p>
                <p class="small ">b) ¿No tienes wallet propia?</p>
                <p class="small mb-4 custom-margin">¡No te preocupes! Envía solo el justificante. </p>
                <p class="small mb-4 ">*La inversión mínima por transferencia es de 500€ </p>
                <a href="mailto:info@domoblock.io" target="_blank"><p class="btn btn-outline-success email">Enviar el email</p></a>
            </div>
        </div> -->
    </div>

    
</div>
