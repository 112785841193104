import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';

@Component({
  selector: 'app-forced-transfer',
  templateUrl: './forced-transfer.component.html',
  styleUrls: ['./forced-transfer.component.css']
})
export class ForcedTransferComponent {
  formForcedTransfer: FormGroup = this.createFormForcedTransfer();

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(private connSrv: ConnectionsService) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER); 
  }

  createFormForcedTransfer() {
    return new FormGroup({
      addressContract: new FormControl(null, [Validators.required]),
      addressFrom: new FormControl(null, [Validators.required]),
      addressTo: new FormControl(null, [Validators.required])
    })
  }

  get addressContract() { return this.formForcedTransfer?.get('addressContract'); }
  get addressFrom() { return this.formForcedTransfer?.get('addressFrom'); }
  get addressTo() { return this.formForcedTransfer?.get('addressTo'); }

  async transfer() {
    if (this.formForcedTransfer.invalid) { 
      this.formForcedTransfer.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.forcedTransferStocks( 
      this.addressContract?.getRawValue(),
      this.addressFrom?.getRawValue(),
      this.addressTo?.getRawValue()
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          text: 'Traspaso realizado con éxito'
        });

        this.formForcedTransfer.reset();
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Transacción errónea',
          text: 'Algo ha ido mal, la transacción NO se ha realizado'
        });
      });
  } 
}
