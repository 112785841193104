import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';
import { KycService as KycSrv } from 'src/app/services/kyc.service';


@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.css']
})
export class MainContainerComponent implements OnInit {

  isLoading: boolean = true;
  loadingMessage: string = 'Cargando...';
  intialLoading: boolean = false;
  initialLoadingMessage: string = 'Cargando...';

  constructor(
    private loaderService: LoaderService, 
    private kycService: KycService, 
    private kycSrv: KycSrv,
    private crf: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loaderService.initialLoadingStatus.subscribe((loading) => {
      this.intialLoading = loading?.isLoading || false;
      this.initialLoadingMessage = loading?.loadingMessage || '';
    });

    this.loaderService.dataIsLoading.subscribe((loading) => {
      this.isLoading = loading?.isLoading || false;
      this.loadingMessage = loading?.loadingMessage || '';
    });

    setTimeout(() => {
      this.kycService.openModalKyc();
    }, 1500);
  }
}
