import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})
export class WithdrawComponent implements OnInit {


  bankAccounts: string[] = ['ES 1234 1234 1234 1234 1234', 'ES 5678 5678 5678 5678 5678', 'ES 9012 9012 9012 9012 9012']; 
  iban: string = '';
  banks: string[] = ['BBVA', 'Santander', 'CaixaBank', 'Bankia', 'Bankinter', 'Sabadell', 'ING', 'Openbank', 'EVO', 'N26'];
  
  constructor() { }

  ngOnInit(): void {
  }

  deleteBankAccount(index: number) {
    this.bankAccounts.splice(index, 1);
  }

}
