<nav class="navbar navbarDesktop navbar-expand-lg bg-light bradius mb-4" 
*ngIf="loggedIn && (path != '/mis-inversiones' && path != '/cartera-digital' && path != '/cartera-digital/blockchain' && path != '/cartera-digital/euros')">
    <div class="container-fluid" >
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li *ngIf="acreditado" class="nav-item">
                    <a class="nav-link" style="color:#43A047;" href="#">
                        <i class="bi bi-emoji-sunglasses-fill" style="font-size:1.5rem;color:#f09502;"></i>
                        <span class="d-inline-block text-white" style="margin-left: 10px;">USUARIO ACREDITADO</span>
                    </a>
                </li>
                <li  *ngIf="loggedIn && !acreditado && !revision" class="nav-item">
                    <a class="nav-link parpadea" style="color:#EF5350;" [routerLink]="['/kyc']">
                        <i class="bi bi-bell-fill" style="font-size:1.5rem;color:#f09502;"></i>
                        <span class="d-inline-block noAcreditado text-white" style="margin-left: 10px;">USUARIO <span style="color:#f09502;">NO</span> ACREDITADO</span> 
                    </a>
                </li>
                <li  *ngIf="!acreditado && revision" class="nav-item">
                    <a class="nav-link parpadea" style="color:#EF5350;" [routerLink]="['/kyc']">
                        <i class="bi bi-bell-fill" style="font-size:1.5rem;color:#f09502;"></i>
                        <span class="d-inline-block noAcreditado text-white" style="margin-left: 10px;">USUARIO <span style="color:#f09502;">EN REVISIÓN</span></span> 
                    </a>
                </li>
                <li *ngIf="addressUser !== null && hideBlockchain != true" class="nav-item">
                    <a class="nav-link address bradius text-white" [href]="'https://polygonscan.com/address/' + addressUser" target="_blank">Address: {{addressUserTruncate}}</a>
                </li>
                <li *ngIf="addressUser === null && loggedIn && acreditado && hideBlockchain != true" class="nav-item connect">
                    <button class="btn btn-success" (click)="connect()"><img src="../../../assets/img/metamask.png">Conectar Wallet</button>
                </li>
            </ul>
            <span *ngIf="loggedIn && userAuth !== null" class="navbar-text text-white login">Bienvenid@ <span style="color:#f09502;font-size:1.1rem;">{{nameUser}}</span></span>
            <!-- <span *ngIf="!loggedIn && path !== '/login'" class="navbar-text text-white login"><button [routerLink]="['/login']" class="toLogin btn btn-success">Acceder</button></span> -->
            <!-- <span *ngIf="!loggedIn" class="navbar-text text-white login"><button (click)="openLoginForm()" class="toLogin btn btn-success">Acceder</button></span> -->
        </div>
    </div>
</nav>

