// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'domoblock-devnew',
    appId: '1:633973104185:web:f7fe33720873fa1e9cf7e8',
    storageBucket: 'domoblock-devnew.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyDLyuGnZ7myYbwKnLro90GsWdTC2ldMaBU',
    authDomain: 'domoblock-devnew.firebaseapp.com',
    messagingSenderId: '633973104185',
    measurementId: 'G-Q1BKXM9833'
  },
  config: {
    PASSWORD_BACK_AUTH: '2A0Jq4p03rjqr0',
    ADDRESS_MANAGER: '0x325C7857F9aA74B8e6dB10ef4fA24616f41e2E98',
    ADDRESS_WHITELIST: '0xd5Ea5c8327099D70dF461Ee71318b2AcD1CB5433'
  }
};

// export const environment = {
//   production: false,
//   firebase: {
//     projectId: 'domoblock-dapp',
//     appId: '1:915699905655:web:fcc3a1d159dfc7b1c10272',
//     storageBucket: 'domoblock-dapp.appspot.com',
//     locationId: 'europe-west',
//     apiKey: 'AIzaSyA1KuKiDoE0UXNs-XrXzPvrXziWFvKXw-Q',
//     authDomain: 'domoblock-dapp.firebaseapp.com',
//     messagingSenderId: '915699905655',
//     measurementId: 'G-23K5SKHWXP',
//   },
//   config: {
//     PASSWORD_BACK_AUTH: '2A0Jq4p03rjqr0',
//     ADDRESS_MANAGER: '0x325C7857F9aA74B8e6dB10ef4fA24616f41e2E98',
//     ADDRESS_WHITELIST: '0xd5Ea5c8327099D70dF461Ee71318b2AcD1CB5433'
//   }
// };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
