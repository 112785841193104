<div class="forced-transfer-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formForcedTransfer !== undefined || formForcedTransfer !== null" [formGroup]="formForcedTransfer">
        <p class="title text-center h4 w-100">Trasapaso forzoso de participaciones (tokens)</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="addressContract" class="form-label">Address del contrato de participaciones (ERC-20):</label>
            <input [class.errorInput]="addressContract?.invalid && (addressContract?.dirty || addressContract?.touched)" formControlName="addressContract" type="text" class="form-control" id="addressContract" placeholder="Introduce la address del contrato">
        </div>
        
        <div class="mb-4">
            <label for="addressFrom" class="form-label">Wallet de origen:</label>
            <input [class.errorInput]="addressFrom?.invalid && (addressFrom?.dirty || addressFrom?.touched)" formControlName="addressFrom" type="text" class="form-control" id="addressFrom" placeholder="Introduce la address de origen">
        </div>

        <div class="mb-4">
            <label for="addressTo" class="form-label">Wallet de destino:</label>
            <input [class.errorInput]="addressTo?.invalid && (addressTo?.dirty || addressTo?.touched)" formControlName="addressTo" type="text" class="form-control" id="addressTo" placeholder="Introduce la address de destino">
        </div>
        
        <button [disabled]="formForcedTransfer.invalid" (click)="transfer()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Transferir</button>
    </form>
</div>

