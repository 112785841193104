import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

import * as jsonErc20 from '../../../abis/ERC20.json';

@Component({
  selector: 'app-info-dividends',
  templateUrl: './info-dividends.component.html',
  styleUrls: ['./info-dividends.component.css']
})
export class InfoDividendsComponent {
  workbook: XLSX.WorkBook = XLSX.utils.book_new();
  web3: any | undefined;
  abiToken: any = jsonErc20;
  form: FormGroup = this.createForm();

  constructor(private connSrv: ConnectionsService, private afs: AngularFirestore) {
    this.web3 = this.connSrv.web3Instance;
  }

  createForm() {
    return new FormGroup({
      tokenAddress: new FormControl(null, [Validators.required])
    })
  }

  get tokenAddress() { return this.form?.get('tokenAddress'); }

  async getExcel() {
    if (this.form.invalid) { 
      this.form.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.afs.collection('investments', ref => ref.where('tokensAddress', '==', this.tokenAddress?.getRawValue()).limit(1)).get().subscribe(async (snapDocs: any) => {
      const totalSupply = snapDocs.docs[0].data().amountToSell;
      this._getExcel(totalSupply);    
    });
  }

  async _getExcel(_totalSupply: number) {
    this.afs.collection('snapshots').doc(this.tokenAddress?.getRawValue()).get().subscribe((snapDoc: any) => {
      if (snapDoc.exists) {
        const values: any[] = Object.values(snapDoc.data() as any[]);
        let _numFull1 = values.length;
        for (const value of values) {
          this.afs.collection('w-invest').doc(this.tokenAddress?.getRawValue()).collection(value.snapShotId).get().subscribe((snapDocs: any) => {
            if (snapDocs.docs.length > 0) {
              let _data: any[] = [];

              for (let i = 0; i < snapDocs.docs.length; i++) {
                Object.values(snapDocs.docs[i].data()).forEach((doc: any) => {
                  const _dividendosTotales = value.totalAmount;
                  const _totalSupplyTokens = _totalSupply;
                  const _beneficioBrutoPorEmpresa = doc.walletByCompany ? (this.web3.utils.fromWei(doc.balance, 'ether') * _dividendosTotales) / _totalSupplyTokens : 0;
                  const _beneficioNetoPorEmpresa = _beneficioBrutoPorEmpresa > 0 
                    ? _beneficioBrutoPorEmpresa - (_beneficioBrutoPorEmpresa * (doc.percentRetentionTo / 100)) 
                    : 0;
                  const _beneficioBruto = doc.walletByCompany === false ? (this.web3.utils.fromWei(doc.balance, 'ether') * _dividendosTotales) / _totalSupplyTokens : 0;
                  const _beneficioNeto = _beneficioBruto > 0
                    ? _beneficioBruto - (_beneficioBruto * (doc.percentRetentionTo / 100))
                    : 0;
                  _data.push({
                    ...doc,
                    beneficio_bruto_por_empresa: _beneficioBrutoPorEmpresa,
                    beneficio_neto_por_empresa: _beneficioNetoPorEmpresa,
                    beneficio_bruto: _beneficioBruto,
                    beneficio_neto: _beneficioNeto
                  });
                });
              }

              let _numFull2 = _data.length;
              for (let i = 0; i < _data.length; i++) {
                const uid = _data[i].uid;
                this.afs.collection('users').doc(uid).get().subscribe((snapDoc: any) => {
                  if (snapDoc.exists) {
                    _data[i] = {
                      email: snapDoc.data().email || "",
                      nombre_particular: snapDoc.data().particular?.nombre || "",
                      apellido1_particular: snapDoc.data().particular?.apellido1 || "",
                      apellido2_particular: snapDoc.data().particular?.apellido2 || "",
                      documentIdentification_particular: snapDoc.data().particular?.documentIdentification || "",
                      pais_residencia_particular: snapDoc.data().particular?.pais_de_residencia || "",
                      nacionalidad_particular: snapDoc.data().particular?.nacionalidad || "",
                      domicilio_particular: snapDoc.data().particular?.domicilio || "",
                      codigo_postal_particular: snapDoc.data().particular?.codigo_postal || "",
                      ciudad_particular: snapDoc.data().particular?.ciudad || "",
                      trabaja_funcion_publica_particular: snapDoc.data().particular?.trabaja_funcion_publica || "",
                      vive_en_usa_particular: snapDoc.data().particular?.vive_en_usa || "",
                      telefono_particular: snapDoc.data().particular?.telefono || "",
                      datos_empresa: JSON.stringify(snapDoc.data().Empresa?.Datos_Empresa) || "",
                      representante_empresa: JSON.stringify(snapDoc.data().Empresa?.Representante) || "",
                      intermediacion_compra_domoblock: _data[i].walletByCompany ? "Sí" : "No",
                      address_usada: _data[i].addressUsed || "",
                      cantidad_tokens: _data[i].balance || 0,
                      porcentaje_retencion: _data[i].percentRetentionTo || 0,
                      beneficio_bruto_por_empresa: _data[i].beneficio_bruto_por_empresa.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
                      beneficio_neto_por_empresa: _data[i].beneficio_neto_por_empresa.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
                      beneficio_bruto: _data[i].beneficio_bruto.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
                      beneficio_neto: _data[i].beneficio_neto.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2}),
                      dai_eur: value.priceStableCoin || 0
                    };
                  }
                  _numFull2--;
                });
              }

              const _interval2 = setInterval(() => {
                if (_numFull2 === 0) {
                  clearInterval(_interval2);
                  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(_data);
                  XLSX.utils.book_append_sheet(this.workbook, worksheet, `${value.snapShotId}_${this.tokenAddress?.getRawValue().substring(0, 12)}...`);
                  _numFull1--;
                }
              }, 1000);
            }
          });
        }
        const _interval1 = setInterval(() => {
          if (_numFull1 === 0) {
            clearInterval(_interval1);
            XLSX.writeFile(this.workbook, 'info.xlsx');
            Swal.close();
          }
        }, 1000);
      }
    });
  }
}

