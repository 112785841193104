import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hide-proyect',
  templateUrl: './hide-proyect.component.html',
  styleUrls: ['./hide-proyect.component.css']
})
export class HideProyectComponent {
  formSetHiden: FormGroup = this.createFormSetHiden();

  constructor(private afs: AngularFirestore) { }

  createFormSetHiden() {
    return new FormGroup({
      nameToken: new FormControl(null, [Validators.required]),
      hide: new FormControl(null, [Validators.required])
    })
  }

  get nameToken() { return this.formSetHiden?.get('nameToken'); }
  get hide() { return this.formSetHiden?.get('hide'); }

  setHide() {
    if (this.formSetHiden.invalid) { 
      this.formSetHiden.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.afs.collection('investments', ref => ref.where('nameTokens', '==', this.nameToken?.getRawValue()).limit(1)).get()
      .subscribe((res: any) => {
        if (res.docs.length === 0) {
          Swal.close();
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Proyecto no encontrado'
          });

          return;
        } else {
          const id = res.docs[0].data().id;
          switch(this.hide?.getRawValue()){
              case 'true':{
                this.afs.collection('investments').doc(id).set({hide: true}, {merge: true})
                .then(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: `Proyecto ocultado`
                  });
  
                  this.formSetHiden.reset();
                })
                .catch(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo ha ido mal!'
                  });
                })
                break;
              }
              case 'false':{
                this.afs.collection('investments').doc(id).set({hide: false, onlyInversors:false}, {merge: true})
                .then(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: `Proyecto Visible`
                  });
  
                  this.formSetHiden.reset();
                })
                .catch(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo ha ido mal!'
                  });
                })
                break;
              }
              case 'inversors':{
                this.afs.collection('investments').doc(id).set({hide:false, onlyInversors: true}, {merge: true})
                .then(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: `Proyecto mostrado solo a inversores`
                  });
  
                  this.formSetHiden.reset();
                })
                .catch(() => {
                  Swal.close();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Algo ha ido mal!'
                  });
                })
                break;
              }
          }
        }
      });
  }
}
