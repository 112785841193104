import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import * as jsonManager from '../../abis/Manager.json';
import * as jsonSeller from '../../abis/Seller.json';
import { environment } from '../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';

@Component({
  selector: 'app-new-sale-seller',
  templateUrl: './new-sale-seller.component.html',
  styleUrls: ['./new-sale-seller.component.css']
})
export class NewSaleSellerComponent implements OnInit {
  proyectsToActivate: any[] = [];
  formDataNewSale: FormGroup = this.createFormDataNewSale();

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;
  abiSeller: any = jsonSeller;
  nowTimeStamp: number = Math.floor(Date.now() / 1000);

  constructor(private afs: AngularFirestore, private crf: ChangeDetectorRef, private connSrv: ConnectionsService) {
    this.afs.collection('investments', ref => ref.where('blockchain', '==', true).where('proyectStatus', '!=', 'Activo').where('numberInvestors', '==', 0)).valueChanges().subscribe((res: any) => {
      if (res !== undefined && res !== null) { 
        this.proyectsToActivate = res;
        this.crf.detectChanges();
      }
    });

    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER);
  }

  createFormDataNewSale() {
    return new FormGroup({
      priceEUR: new FormControl(null),
      maxTimeHours: new FormControl(null),
      minTokensBuy: new FormControl(null)
    })
  }

  get priceEUR() { return this.formDataNewSale?.get('priceEUR'); }
  get maxTimeHours() { return this.formDataNewSale?.get('maxTimeHours'); }
  get minTokensBuy() { return this.formDataNewSale?.get('minTokensBuy'); }

  setData(id: string) {
    if (this.formDataNewSale.invalid) { 
      this.formDataNewSale.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    let item = {};

    if (this.priceEUR?.valid && this.priceEUR.getRawValue() !== null && this.priceEUR.getRawValue() > 0) { item = {...item, priceToken: this.priceEUR.getRawValue() * 100} }
    if (this.maxTimeHours?.valid && this.maxTimeHours.getRawValue() !== null && this.maxTimeHours.getRawValue() > 0) { item = {...item, hoursToSell: this.maxTimeHours.getRawValue()} }
    if (this.minTokensBuy?.valid && this.minTokensBuy.getRawValue() !== null && this.minTokensBuy.getRawValue() > 0) { item = {...item, minimumInvestment: this.minTokensBuy.getRawValue()} }

    this.afs.collection('investments').doc(id).set(item, {merge: true})
      .then(() => {
        this.formDataNewSale.reset();
        
        Swal.close();
        Swal.fire({
          icon: 'success',
          text: `Los datos de venta has sido modificados`
        });
      })
      .catch(() => { 
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  async activateSale(proyect: any) {
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });
    
    this.contractManager.methods.newSaleSeller(
      proyect.priceToken,
      proyect.hoursToSell,
      this.formatToWei(proyect.minimumInvestment),
      proyect.sellerAddress
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(async () => {
        const contractSeller = new this.web3.eth.Contract(this.abiSeller.abi, proyect.sellerAddress);
        const timeLimit = await contractSeller.methods.getMaxTime().call();

        this.afs.collection('investments').doc(proyect.id).set({
          endOfSale: timeLimit,
          proyectStatus: 'Activo'
        }, {merge: true}).then(() => {
          Swal.close();
          Swal.fire({
            icon: 'success',
            text: `Proyecto puesto a la venta`
          });

          this.crf.detectChanges();
        });
      })
      .catch((err: any) => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Transacción errónea',
          text: 'Algo ha ido mal, la transacción NO se ha realizado'
        });
      });
  }

  formatToWei(num: number) {
    return this.web3.utils.toWei(num.toString(), 'ether');
  }

  toFloor(num: number)  {
    if (num === undefined || num === null || num === 0) {
      return 'No activo - Parámetro obligatorio';
    }

    const secondsDiff =  num - this.nowTimeStamp;
    const minutesDiff = secondsDiff / 60;
    const hoursDiff = minutesDiff / 60;
    const daysDiff = hoursDiff / 24;

    if (daysDiff >= 1) {
      if (hoursDiff % 24 > 0) {
        return 'Quedan: ' + Math.floor(daysDiff) + ' días y ' + Math.floor(hoursDiff % 24) + ' minutos';
      } else if (hoursDiff % 24 == 0) {
        return 'Quedan: ' + Math.floor(daysDiff) + ' días';
      }
    }

    if (hoursDiff >= 1) {
      if (minutesDiff % 60 > 0) {
        return 'Quedan: ' + Math.floor(hoursDiff) + ' horas y ' + Math.floor(minutesDiff % 60) + ' minutos';
      } else if (minutesDiff % 60 == 0) {
        return 'Quedan: ' + Math.floor(hoursDiff) + ' horas';
      }
    }

    if (minutesDiff >= 1) {
      if (secondsDiff % 60 > 0) {
        return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos y ' + Math.floor(secondsDiff % 60) + ' segundos';
      } else if (secondsDiff % 60 == 0) {
        return 'Quedan: ' + Math.floor(minutesDiff) + ' minutos';
      }
    }

    if (secondsDiff >= 1) {
      return 'Quedan: ' + Math.floor(secondsDiff) + ' segundos';
    }

    return 'Tiempo agotado';
  }

  ngOnInit(): void {
    setInterval(() => {
      this.nowTimeStamp = Math.floor(Date.now() / 1000);
      this.crf.detectChanges();
    }, 3000);
  }
}
