<div class="usersTable">
    <div *ngIf="users.length == 0" class="noUsersToActive">
        <p class="m-0 p-4">No hay usuarios pendientes de activación</p>
    </div>

    <table class="table">
        <tr>
            <th *ngFor="let user of users" class="bradius">
                <div class="info">
                    <p class="m-0">{{user.displayName}} ({{user.email}})</p>
                </div>

                <div class="actions">
                    <button (click)="aprove(user.uid)" class="btn btn-success">Aprobar</button>
                    <button (click)="reject(user.uid)" class="btn btn-outline-success">Denegar</button>
                </div>

                <div class="accordion w-100" [id]="'accordionParent' + user.uid">
                    <div class="accordion-item">
                        <h2 class="accordion-header text-white" [id]="'heading' + user.uid">
                            <span class="accordion-button text-black" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + user.uid" aria-expanded="false" [attr.aria-controls]="'collapse' + user.uid">
                            Más información
                            </span>
                        </h2>
                        <div [id]="'collapse' + user.uid" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + user.uid" [attr.data-bs-parent]="'#accordionParent' + user.uid">
                            <div class="accordion-body">
                                <ngx-json-viewer [json]="user" [expanded]="false"></ngx-json-viewer>
                            </div>
                        </div>
                    </div>
                </div>
            </th>
        </tr>
    </table>
</div>
