<div class="add-dividends-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formAddDividends !== undefined || formAddDividends !== null" [formGroup]="formAddDividends">
        <p class="title text-center h4 w-100">Ingresar dividendos</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="tokenAddress" class="form-label">Address del token (Participaciones ERC-20)</label>
            <input [class.errorInput]="tokenAddress?.invalid && (tokenAddress?.dirty || tokenAddress?.touched)" formControlName="tokenAddress" type="string" class="form-control" id="tokenAddress" placeholder="Dirección del contrato de las participaciones">
        </div>

        <div class="mb-4">
            <label for="amount" class="form-label">Cantidad en bruto de stablecoin:</label>
            <input [class.errorInput]="amount?.invalid && (amount?.dirty || amount?.touched)" formControlName="amount" type="number" class="form-control" id="amount" placeholder="Introduce la cantidad de dividendos en bruto">
        </div>

        <div class="mb-4">
            <label for="priceStableCoin" class="form-label">Precio en EUR de la stablecoin (Unidad):</label>
            <input [class.errorInput]="priceStableCoin?.invalid && (priceStableCoin?.dirty || priceStableCoin?.touched)" formControlName="priceStableCoin" type="number" class="form-control" id="priceStableCoin" placeholder="Introduce el precio en EUR">
        </div>

        <div class="mb-4 w-100">
            <label for="retentionFlag" class="form-label">Retencion</label>
            <select [class.errorInput]="retentionFlag?.invalid && (retentionFlag?.dirty || retentionFlag?.touched)" formControlName="retentionFlag" class="form-select">
                <option value="null" selected>Selecciona un opción</option>
                <option value="false">Sin retención</option>
                <option value="true">Con retención</option>
            </select>
        </div>

        <div class="form-check mb-4 w-100 toBlockchain">
            <input [class.errorInput]="finishFlag?.invalid && (finishFlag?.dirty || finishFlag?.touched)" class="form-check-input" type="checkbox" value="true" formControlName="finishFlag" id="finishFlag">
            <label class="form-check-label mb-0" style="font-size:1rem;" for="finishFlag">Último reparto de dividendos</label>
        </div>
        
        <button [disabled]="formAddDividends.invalid" (click)="addDividends()" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Invertir</button>
    </form>
</div>
