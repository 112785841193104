import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app'; 

@Component({
  selector: 'app-set-whitelist',
  templateUrl: './set-whitelist.component.html',
  styleUrls: ['./set-whitelist.component.css']
})
export class SetWhitelistComponent {
  formSetWhitelist: FormGroup = this.createFormSetWhitelist(); 

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(private connSrv: ConnectionsService, private afs: AngularFirestore) {
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER); 
  }

  createFormSetWhitelist() {
    return new FormGroup({
      addressWallet: new FormControl(null, [Validators.required]),
      status: new FormControl(null, [Validators.required])
    })
  }

  get addressWallet() { return this.formSetWhitelist?.get('addressWallet'); }
  get status() { return this.formSetWhitelist?.get('status'); }

  setWhitelist() {
    if (this.formSetWhitelist.invalid) { 
      this.formSetWhitelist.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    this.afs.collection('walletTraceability').doc(this.addressWallet?.getRawValue()).get().subscribe(async (res: any) => {
      if (res.exists) {
        const uid = res.data().uid;

        const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
          return Number(block.baseFeePerGas) + 100000000000; 
        });

        this.contractManager.methods.setStatusWhiteList(
          this.addressWallet?.getRawValue(),
          this.status?.getRawValue()
        ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
          .then(async () => {
            if (this.status?.getRawValue() != 1) {
              await this.afs.collection('users').doc(uid).set({
                kyc: {result: 2},
                walletsWhitelist: firebase.firestore.FieldValue.arrayRemove(this.addressWallet?.getRawValue())
              }, {merge: true});

              this.afs.collection('users').doc(uid).get().subscribe((res: any) => {
                if (res.exists) {
                  let wWhitelist: string[] = res.data().walletsWhitelist;

                  let txt: string | null = null;
                  if (wWhitelist.length > 0) {
                    txt = 'Hay más wallets asociadas:';
                    for (let i = 0; i < wWhitelist.length; i++) {
                      txt += '  ' + wWhitelist[i];
                    }
                  }
                  
                  if (txt !== null) {
                    Swal.close();
                    Swal.fire({
                      icon: 'info',
                      title: 'Importante',
                      text: txt
                    });

                    this.formSetWhitelist.reset();
                  } else {
                    Swal.close();
                    Swal.fire({
                      icon: 'success',
                      text: 'Cambio realizado con éxito'
                    });

                    this.formSetWhitelist.reset();
                  }
                } else {
                  Swal.close();
                  Swal.fire({
                    icon: 'success',
                    text: 'Cambio realizado con éxito'
                  });

                  this.formSetWhitelist.reset();
                }
              }); 
            }

            if (this.status?.getRawValue() == 1) {
              await this.afs.collection('users').doc(uid).set({
                kyc: {result: 1},
                walletsWhitelist: firebase.firestore.FieldValue.arrayUnion(this.addressWallet?.getRawValue())
              }, {merge: true});

              Swal.close();
              Swal.fire({
                icon: 'success',
                text: 'Cambio realizado con éxito'
              });

              this.formSetWhitelist.reset();
            }
          })
          .catch(() => {
            Swal.close();
            Swal.fire({
              icon: 'error',
              title: 'Transacción errónea',
              text: 'Algo ha ido mal, la transacción NO se ha realizado'
            });
          });
      } else {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Wallet desconocida',
          text: 'Esta wallet no ha sido registrada por un usuario o la empresa. Es necesario primero registrarla'
        });
      }
    });
  } 
}
