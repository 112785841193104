<div id="onTop" class="kyc-container pt-4">
  <div  class="mb-5" >
    <div   class="d-flex tabsPoints">
      <div *ngFor="let tab of tabItems; let i = index" class="opacity" [ngClass]="{'active': selectedTabIndex === i}">
        <div>
          <strong class="text-white">{{tab.name}}</strong>
        </div>
      </div>
    </div>
    <div class="row points">
      <div class="col-12 col-md-3 col-sm-3 line-cicle">
      </div>
      <div class="col-12 col-md-3 col-sm-3 line-one-cicle" [ngClass]="{'line-one-cicle-dark': selectedTabIndex === 1 || selectedTabIndex === 2 || selectedTabIndex === 3 }">
      </div>
      <div class="col-12 col-md-3 col-sm-3 line-one-cicle" [ngClass]="{'line-one-cicle-dark': selectedTabIndex === 2 ||  selectedTabIndex === 3   }">
      </div>
      <div class="col-12 col-md-3 col-sm-3 line-one-cicle" [ngClass]="{'line-one-cicle-dark':  selectedTabIndex === 3  }">
      </div>
    </div>
  </div>

  <div *ngIf="selectedTabIndex === 0">
    <div class="new-container">
      <form class="bradius" *ngIf="fromNewEnterprise !== undefined || fromNewEnterprise !== null" [formGroup]="fromNewEnterprise">
        <p class="title text-center h5 mb-3 w-100 text-white">Elige cómo quieres registrarte</p>

        <hr class="w-100 mb-5">  

        <div class="mb-4" >
          <label for="Empresa" class="form-label">Elige entre persona o empresa</label>
          <select [class.errorInput]="Empresa?.invalid && (Empresa?.dirty || Empresa?.touched)" name="Empresa" id="Empresa" formControlName="Empresa" type="text" class="form-control" value="No" >
            <option disabled selected>Selecciona una opción</option>
              <option value="Si" >Empresa</option>
              <option value="No">Persona</option>
          </select>
        </div>
      </form>

      <ng-container *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
        <form class="bradius mt-5" *ngIf="fromNewEnterprise !== undefined || fromNewEnterprise !== null" [formGroup]="fromNewEnterprise" >
          <p class="title text-center h5 mb-3 w-100 text-white">Información de la empresa</p>
          <hr class="w-100 mb-5"*ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="Nombre_de_la_empresa" class="form-label">Nombre de la empresa</label>
            <input [class.errorInput]="Nombre_de_la_empresa?.invalid && (Nombre_de_la_empresa?.dirty || Nombre_de_la_empresa?.touched)"  formControlName="Nombre_de_la_empresa" type="text" class="form-control" id="Nombre_de_la_empresa" placeholder="Introduce el nombre de la empresa">
          </div>
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="Razon_Social" class="form-label">Domicilio social</label>
            <input [class.errorInput]="Razon_Social?.invalid && (Razon_Social?.dirty || Razon_Social?.touched)" formControlName="Razon_Social" type="text" class="form-control" id="Razon_Social" placeholder="Introduce el domicilio social de la empresa">
          </div>
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="empresa_ciudad" class="form-label">Ciudad</label>
            <input [class.errorInput]="empresa_ciudad?.invalid && (empresa_ciudad?.dirty || empresa_ciudad?.touched)" formControlName="empresa_ciudad" type="text" class="form-control" id="empresa_ciudad" placeholder="Introduce la Ciudad donde se encuentra la empresa">
          </div>
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="empresa_codigo_postal" class="form-label">Código postal</label>
            <input [class.errorInput]="empresa_codigo_postal?.invalid && (empresa_codigo_postal?.dirty || empresa_codigo_postal?.touched)" formControlName="empresa_codigo_postal" type="text" class="form-control" id="empresa_codigo_postal" placeholder="Introduce el código postal donde se encuentra la empresa">
          </div>
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="empresa_pais" class="form-label">Pais de la empresa</label>
            <select [class.errorInput]="empresa_pais?.invalid && (empresa_pais?.dirty || empresa_pais?.touched)" formControlName="empresa_pais" type="text" class="form-control" id="empresa_pais" placeholder="Introduce el país donde se encuentra la empresa">
            <option disabled selected>Selecciona una opción</option>
              <option *ngFor="let invest of elige ">{{invest.viewValue}}</option>
            </select>

          </div>
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="CIF" class="form-label">CIF</label>
            <input [class.errorInput]="CIF?.invalid && (CIF?.dirty || CIF?.touched)" formControlName="CIF" type="text" class="form-control" id="CIF" placeholder="Introduce el CIF">
          </div>
          <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
            <label for="Forma_juridica" class="form-label">Forma juridica</label>
            <input [class.errorInput]="Forma_juridica?.invalid && (Forma_juridica?.dirty || empresa_pais?.touched)" formControlName="Forma_juridica" type="text" class="form-control" id="Forma_juridica" placeholder="Introduce la forma jurídica">
          </div>
          <form class="bradius" *ngIf="fromNewEnterprise !== undefined || fromNewEnterprise !== null" [formGroup]="fromNewEnterprise" style="margin-top:3% ;">
            <p class="title text-center h5 mb-3 w-100 text-white">Documentación</p>
            <hr class="w-100 mb-5"*ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
            <div class="w-100 mb-3 border p-2" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
              <ng-container *ngIf="!this.fileacta">
              <p class="w-100 small mb-1">Acta de la empresa (PDF):</p>
              <input  [class.errorInput]="Acta_de_la_empresa?.invalid && (Acta_de_la_empresa?.dirty || Acta_de_la_empresa?.touched)" (change)="uploadActa($event)" type="file" id="Acta_de_la_empresa" formControlName="Acta_de_la_empresa">
            </ng-container>
              <p class="mb-0" *ngIf="this.fromNewEnterprise.value.Acta_de_la_empresa !== null">Has subido correctamente el archivo {{this.fileacta}}</p>
            </div>

            <div class="w-100 mb-3 border p-2" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
              <ng-container *ngIf="!this.filescrito">
              <p class="w-100 small mb-1">Escritura de la Sociedad (PDF):</p>
              <input [class.errorInput]="Escritura_de_la_sociedad?.invalid && (Escritura_de_la_sociedad?.dirty || Escritura_de_la_sociedad?.touched)" (change)="uploadEscritura($event)" type="file" id="Escritura_de_la_sociedad" formControlName="Escritura_de_la_sociedad">
            </ng-container>
              <p class="mb-0" *ngIf="this.fromNewEnterprise.value.Escritura_de_la_sociedad !== null"> Has subido correctamente el archivo {{this.filescrito}}</p>
            </div>

            <div class="w-100 mb-3 border p-2" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
              <ng-container *ngIf="!this.fileotros">
              <p class="w-100 small mb-1">Otros Documentos que creas que deban ser conocidos (PDF):</p>
              <input [class.errorInput]="Otros_docs?.invalid && (Otros_docs?.dirty || Otros_docs?.touched)" (change)="uploadOtros($event)" type="file" id="Otros_docs" formControlName="Otros_docs" >
            </ng-container>
              <p class="mb-0" *ngIf="this.fromNewEnterprise.value.Otros_docs !== null">Has subido correctamente el archivo {{this.fileotros}}</p>
            </div>

         </form>
        </form>
      </ng-container>

      <!--aqui el formulario de usuario-->
      <ng-container *ngIf="this.fromNewEnterprise.value.Empresa == 'No'">
        <form  class="bradius" *ngIf="formNewKyc !== undefined || formNewKyc !== null" [formGroup]="formNewKyc">
          
            <p class="title text-center h5 mb-3 w-100 text-white">Información Personal</p> 
      
          <hr class="w-100 mb-5">

          <div class="mb-4">
            <label for="nombre1" class="form-label">Nombre</label>
            <input [class.errorInput]="nombre?.invalid && (nombre?.dirty || nombre?.touched)" formControlName="nombre" type="text" class="form-control" id="nombre" placeholder="Introduce el nombre" required>
          </div>

          <div class="mb-4">
            <label for="apellido1" class="form-label">Primer apellido</label>
            <input [class.errorInput]="apellido1?.invalid && (apellido1?.dirty || apellido1?.touched)" formControlName="apellido1" type="text" class="form-control" id="apellido1" placeholder="Introduce tu primer apellido">
          </div>

          <div class="mb-4">
            <label for="apellido2" class="form-label">Segundo apellido</label>
            <input [class.errorInput]="apellido2?.invalid && (apellido2?.dirty || apellido2?.touched)" formControlName="apellido2" type="text" class="form-control" id="apellido2" placeholder="Introduce tu segundo apellido">
          </div>

          <div class="mb-4">
            <label for="domicilio" class="form-label">Domicilio</label>
            <input [class.errorInput]="domicilio?.invalid && (domicilio?.dirty || domicilio?.touched)" formControlName="domicilio" type="text" class="form-control" id="domicilio" placeholder="Introduce tu dirección y número">
          </div>

          <div class="mb-4">
            <label for="telefono" class="form-label">Teléfono (Opcional)</label>
            <input [class.errorInput]="telefono?.invalid && (telefono?.dirty || telefono?.touched)" formControlName="telefono" type="text" class="form-control" id="telefono" placeholder="Introduce  prefijo + Teléfono  (Opcional)">
          </div>

          <div class="mb-4">
            <label for="ciudad" class="form-label">Ciudad</label>
            <input [class.errorInput]="ciudad?.invalid && (ciudad?.dirty || ciudad?.touched)" formControlName="ciudad" type="text" class="form-control" id="ciudad" placeholder="Introduce tu ciudad">
          </div>

          <div class="mb-4">
            <label for="codigo_postal" class="form-label">Código postal</label>
            <input [class.errorInput]="codigo_postal?.invalid && (codigo_postal?.dirty || codigo_postal?.touched)" formControlName="codigo_postal" type="text" class="form-control" id="codigo_postal" placeholder="Introduce tu código postal">
          </div>

          <div class="mb-4">
            <label for="dni" class="form-label">DNI/NIE/Pasaporte</label>
            <input [class.errorInput]="dni?.invalid && (dni?.dirty || dni?.touched)" formControlName="dni" type="text" class="form-control" id="dni" placeholder=" Introduce tu DNI/NIE/Pasaporte">
          </div>

          <div class="mb-4">
            <label for="fecha_de_nacimiento" class="form-label">Fecha de nacimiento</label>
            <input [class.errorInput]="fecha_de_nacimiento?.invalid && (fecha_de_nacimiento?.dirty || fecha_de_nacimiento?.touched)" formControlName="fecha_de_nacimiento" type="date" class="form-control" id="fecha_de_nacimiento" placeholder="Introduce tu fecha de nacimiento">
          </div>

          <div class="mb-4">
            <label for="nacionalidad" class="form-label">Nacionalidad</label>
            <select [class.errorInput]="nacionalidad?.invalid && (nacionalidad?.dirty || nacionalidad?.touched)"  name="nacionalidad" id="nacionalidad" formControlName="nacionalidad" type="text" class="form-control" >
              <ng-container *ngFor="let invest of elige">
                <option [selected]="nacionalidad?.getRawValue() == invest.value" [value]="invest.value">{{invest.viewValue}}</option>
               </ng-container>
            </select>
          </div>

          <div class="mb-4">
            <label for="pais_de_residencia" class="form-label">País de residencia</label>
            <select [class.errorInput]="pais_de_residencia?.invalid && (pais_de_residencia?.dirty || pais_de_residencia?.touched)" name="pais_de_residencia" id="pais_de_residencia" formControlName="pais_de_residencia" type="text" class="form-control" >
              <option disabled selected>Selecciona una opción</option>
               <ng-container *ngFor="let invest of elige">
                <option [selected]="pais_de_residencia?.getRawValue() == invest.value" [value]="invest.value">{{invest.viewValue}}</option>
               </ng-container>
            </select>
          </div>
        
          <div class="mb-4">
            <label for="vive_en_usa" class="form-label">¿Eres Ciudadano estadounidense?</label>
            <select [class.errorInput]="vive_en_usa?.invalid && (vive_en_usa?.dirty || vive_en_usa?.touched)" name="vive_en_usa" id="vive_en_usa" formControlName="vive_en_usa" type="text" class="form-control" value="No">
              <option disabled selected value> -- select an option -- </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
          </div>

          <div class="mb-4">
            <label for="trabaja_funcion_publica" class="form-label">¿Eres una persona políticamente expuesta?</label>
            <select [class.errorInput]="trabaja_funcion_publica?.invalid && (trabaja_funcion_publica?.dirty || trabaja_funcion_publica?.touched)" name="trabaja_funcion_publica" id="trabaja_funcion_publica" formControlName="trabaja_funcion_publica" type="text" class="form-control">
              <option disabled selected style="display:flexbox">Selecciona una opción</option>  
              <option value="Si">Si</option>
              <option value="No">No</option>
              SI/NO
            </select>
          </div>

          <div class="mb-4" *ngIf="this.formNewKyc.value.trabaja_funcion_publica == 'Si'">
            <label for="funcion_publica" class="form-label">Función pública</label>
            <input [class.errorInput]="funcion_publica?.invalid && (funcion_publica?.dirty || funcion_publica?.touched)" formControlName="funcion_publica" type="text" class="form-control" id="funcion_publica" placeholder="Introduce tu funcion pública">
          </div>
          
        </form>
      </ng-container>

      <div *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
        <p style="margin-top:5%" class="formout">Complete este paso, solo si su actividad habitual es invertir en valores u otros instrumentos financieros (Opcional).</p>
        <form class="bradius" *ngIf="formEnterpriseFin !== undefined || formEnterpriseFin !== null" [formGroup]="formEnterpriseFin" >
          <div class="mb-4">
            <label for="inversor_pro" class="form-label">Deseo ser tratado como inversor profesional (persona jurídica)</label>
            <select  name="inversor_pro" id="inversor_pro" formControlName="inversor_pro"  class="form-control">
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
          </div>

          <hr class="w-100 mb-5" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" >

            <div class="form-check" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" style="width: 100%;" >
              <input class="form-check-input" type="checkbox" name="cliente_profesional" id="cliente_profesional" formControlName="cliente_profesional" >
              <label class="form-check-label" for="cliente_profesional">
                Solicito que con carácter general se me considere “Cliente Profesional” a los efectos de la prestación de todos los servicios de inversión y de los servicios auxiliares sobre todos los instrumentos recogidos en su programa de actividades.
              </label>
            </div>

          <hr class="w-100 mb-5" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" >

          <label class="w-100 mb-5" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'">Marca la casilla correspondiente si cumple con lo siguiente;</label>
          <div class="form-check" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" style="width: 100%;">
            <input class="form-check-input" type="checkbox" value="" name="Activos_mas_de_20M" id="Activos_mas_de_20M" formControlName="Activos_mas_de_20M">
            <label class="form-check-label" for="Activos_mas_de_20M">
              El total de las partidas del activo de la Sociedad es igual o superior a 20 millones de euros.        
            </label>
          </div>

          <hr class="w-100 mb-5" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" >

          <div class="form-check" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" style="width: 100%;">
            <input class="form-check-input" type="checkbox" value="" id="Negocios_sociedad_mas_40M" formControlName="Negocios_sociedad_mas_40M" name="Negocios_sociedad_mas_40M">
            <label class="form-check-label" for="Negocios_sociedad_mas_40M">
              El importe de la cifra de negocios anual de la Sociedad es igual o superior a 40 millones de euros. 
            </label>
          </div>

          <hr class="w-100 mb-5" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" >

          <div class="form-check" *ngIf="this.formEnterpriseFin.value.inversor_pro == 'Si'" style="width: 100%;">
            <input class="form-check-input" type="checkbox" value="" id="Recursos_sociedad_sup_2M" formControlName="Recursos_sociedad_sup_2M" name="Recursos_sociedad_sup_2M">
            <label class="form-check-label" for="Recursos_sociedad_sup_2M">
              Los recursos propios de la Sociedad son iguales o superiores a 2 millones de euros
            </label>
          </div>
        </form>
      </div>
      
      <!--botones de atras alante-->
      <div *ngIf="this.fromNewEnterprise.value.Empresa == 'No'">
        <button  class="btn btn-success" (click)="moveToNextSlide(1)" [disabled]="!formNewKyc.valid">
          Continuar <i href="#miDiv" class="bi bi-arrow-right-circle"></i>
        </button>
      </div>
    
      <div *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
        <button class="btn btn-success" (click)="moveToNextSlide(1)" [disabled]="!fromNewEnterprise.valid">
          Continuar <i href="#miDiv" class="bi bi-arrow-right-circle"></i>
        </button>
     </div>
   </div>
 </div>

 <div  *ngIf="selectedTabIndex === 1" style="color: #f09502;" >
    
 <hr class="w-100 mb-5">
  <!--Aqui el segundo formulario-->
 <div class="new-container" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
    <form  class="bradius" *ngIf="formEnterpriseSocios !== undefined || formEnterpriseSocios !== null" [formGroup]="formEnterpriseSocios" style="margin-top:3%">
        <p class="title text-center h5 mb-3 w-100 text-white" >Información del representante <i (click)="popup2()" class="h3 bi bi-info-circle-fill"></i>  
        
      <hr class="w-100 mb-5">
      <div class="mb-4">
        <label for="nombre" class="form-label">Nombre</label>
        <input [class.errorInput]="rep_nombre?.invalid && (rep_nombre?.dirty || rep_nombre?.touched)" formControlName="nombre" type="text" class="form-control" id="nombre" placeholder="Introduce el nombre">
      </div>

      <div class="mb-4">
        <label for="apellido1" class="form-label">Primer apellido</label>
        <input [class.errorInput]="rep_apellido1?.invalid && (rep_apellido1?.dirty || rep_apellido1?.touched)" formControlName="apellido1" type="text" class="form-control" id="apellido1" placeholder="Introduce tu primer apellido">
      </div>

      <div class="mb-4">
        <label for="apellido2" class="form-label">Segundo apellido</label>
        <input [class.errorInput]="rep_apellido2?.invalid && (rep_apellido2?.dirty || rep_apellido2?.touched)" formControlName="apellido2" type="text" class="form-control" id="apellido2" placeholder="Introduce tu segundo apellido">
      </div>

      <div class="mb-4">
        <label for="domicilio" class="form-label">Domicilio</label>
        <input [class.errorInput]="rep_domicilio?.invalid && (rep_domicilio?.dirty || rep_domicilio?.touched)" formControlName="domicilio" type="text" class="form-control" id="domicilio" placeholder="Introduce tu dirección y número">
      </div>

      <div class="mb-4">
        <label for="telefono" class="form-label">Teléfono (Opcional)</label>
        <input [class.errorInput]="rep_telefono?.invalid && (rep_telefono?.dirty || rep_telefono?.touched)" formControlName="telefono" type="text" class="form-control" id="telefono" placeholder="Introduce  prefijo + Teléfono  (Opcional)">
      </div>

      <div class="mb-4">
        <label for="ciudad" class="form-label">Ciudad</label>
        <input [class.errorInput]="rep_ciudad?.invalid && (rep_ciudad?.dirty || rep_ciudad?.touched)" formControlName="ciudad" type="text" class="form-control" id="ciudad" placeholder="Introduce tu ciudad">
      </div>

      <div class="mb-4">
        <label for="codigo_postal" class="form-label">Código postal</label>
        <input [class.errorInput]="rep_codigo_postal?.invalid && (rep_codigo_postal?.dirty || rep_codigo_postal?.touched)" formControlName="codigo_postal" type="text" class="form-control" id="codigo_postal" placeholder="Introduce tu código postal">
      </div>

      <div class="mb-4">
        <label for="dni" class="form-label">DNI/NIE/Pasaporte</label>
        <input [class.errorInput]="rep_dni?.invalid && (rep_dni?.dirty || rep_dni?.touched)" formControlName="dni" type="text" class="form-control" id="dni" placeholder="Introduce tu DNI">
      </div>

      <div class="mb-4">
        <label for="fecha_de_nacimiento" class="form-label">Fecha de nacimiento</label>
        <input [class.errorInput]="rep_fecha_de_nacimiento?.invalid && (rep_fecha_de_nacimiento?.dirty || rep_fecha_de_nacimiento?.touched)" formControlName="fecha_de_nacimiento" type="date" class="form-control" id="fecha_de_nacimiento" placeholder="Introduce tu fecha de nacimiento">
      </div>
    
      <div class="mb-4">
        <label for="nacionalidad" class="form-label">Nacionalidad</label>
        <select [class.errorInput]="rep_nacionalidad?.invalid && (rep_nacionalidad?.dirty || rep_nacionalidad?.touched)" name="nacionalidad" id="nacionalidad" formControlName="nacionalidad" type="text" class="form-control" >
          <ng-container *ngFor="let invest of elige">
            <option [selected]="nacionalidad?.getRawValue() == invest.value" [value]="invest.value">{{invest.viewValue}}</option>
           </ng-container>
        </select>
      </div>

      <div class="mb-4">
        <label for="pais_de_residencia" class="form-label">País de residencia</label>
        <select [class.errorInput]="rep_pais_de_residencia?.invalid && (rep_pais_de_residencia?.dirty || rep_pais_de_residencia?.touched)" name="pais_de_residencia" id="pais_de_residencia" formControlName="pais_de_residencia" type="text" class="form-control" >
          <ng-container *ngFor="let invest of elige">
            <option [selected]="pais_de_residencia?.getRawValue() == invest.value" [value]="invest.value">{{invest.viewValue}}</option>
           </ng-container>
        </select>
      </div>

      <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
        <label for="empresa_titulo" class="form-label">Título con el que actuas en la empresa</label>
        <input [class.errorInput]="empresa_titulo?.invalid && (empresa_titulo?.dirty || empresa_titulo?.touched)" formControlName="empresa_titulo" type="text" class="form-control" id="empresa_titulo" placeholder="Introduce el título con el que actuas">
      </div>

      <div class="mb-4">
        <label for="vive_en_usa" class="form-label">¿Eres Ciudadano estadounidense?</label>
        <select [class.errorInput]="rep_vive_en_usa?.invalid && (rep_vive_en_usa?.dirty || rep_vive_en_usa?.touched)" name="vive_en_usa" id="vive_en_usa" formControlName="vive_en_usa" type="text" class="form-control" value="No">
          <option value="selecciona una opcion"> Selecciona una opción</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="trabaja_funcion_publica" class="form-label">¿Eres una persona políticamente expuesta?</label>
        <select [class.errorInput]="rep_trabaja_funcion_publica?.invalid && (rep_trabaja_funcion_publica?.dirty || rep_trabaja_funcion_publica?.touched)" name="trabaja_funcion_publica" id="trabaja_funcion_publica" formControlName="trabaja_funcion_publica" type="text" class="form-control">
          <option selected value="selecciona una opcion"> Selecciona una opción</option>  
          <option value="Si">Si</option>
          <option value="No">No</option>
          SI/NO
        </select>  
      </div>

      <div class="mb-4" *ngIf="this.formEnterpriseSocios.value.trabaja_funcion_publica == 'Si'">
        <label for="funcion_publica" class="form-label">Función pública</label>
        <input [class.errorInput]="rep_funcion_publica?.invalid && (rep_funcion_publica?.dirty || rep_funcion_publica?.touched)" formControlName="funcion_publica" type="text" class="form-control" id="funcion_publica" placeholder="Introduce tu función pública">
      </div>
      <!---->
      
      <!----->
    </form>
    <form class="bradius" *ngIf="formEnterpriseSocios !== undefined || formEnterpriseSocios !== null" [formGroup]="formEnterpriseSocios" style="margin-top:3%">
      <p class="title text-center h5 mb-3 w-100 text-white">Información de los titulares <i (click)="popup1()" class="h3 bi bi-info-circle-fill"></i></p>
      <hr class="w-100 mb-5"*ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
      <div class="mb-4" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
        <label for="titular_real" class="form-label alladodiv" >¿El titular real es único y coincide con el representante? </label>
        
        <select [class.errorInput]="titular_real?.invalid && (titular_real?.dirty || titular_real?.touched)" name="titular_real" id="titular_real" formControlName="titular_real" type="text" class="form-control" value="No">
          <option value="selecciona una opcion">Selecciona una opción</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
      </div>
      <hr class="w-100 mb-5"*ngIf="this.formEnterpriseSocios.value.titular_real == 'No'" >
      <div class="mb-4 w-100" *ngIf="this.formEnterpriseSocios.value.titular_real == 'No'" >
        <p class="title text-center h7 mb-3 w-100 text-white">Indica el numero de titulares reales</p>
        <hr class="w-100 mb-5"*ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
        <button class="btn btn-lg custom-btn-black float-right space bi bi-person-plus" (click)="addEmailFormGroup()"></button>
        {{this.numsocios}} Titulares
      </div>
      <hr class="w-100 mb-5"*ngIf="this.formEnterpriseSocios.value.titular_real == 'No'" >
        <div class="w-100 mb-5" *ngIf="this.numsocios >= 1" [formGroup]="emailForm" >
         <div class="kyc-container" formArrayName="emails" style="width: 98%;">
            <div class="row" *ngFor="let email of emailForm.get('emails').controls; let i = index" [formGroupName]="i" style="width: 98%;">
              <form class="bradius" [formGroup]="email" style="margin-top:3%">
                <button class="btn btn-lg custom-btn-black float-right space bi bi-person-dash" (click)="removeOrClearEmail(i)"></button>
                <p class="title text-center h5 mb-3 w-100 text-white">Titular</p>
                <hr class="w-100 mb-5"*ngIf="this.formEnterpriseSocios.value.titular_real == 'No'" >
                <div class="mb-4">
                  <label for="titulo" class="form-label">Nombre del Socio</label>
                  <input formControlName="nombre" type="text" class="form-control" id="nombre" placeholder="Introduce el nombre del socio">
                </div>
                <div class="mb-4">
                  <label for="apellido1" class="form-label">Primer Apellido</label>
                  <input  formControlName="apellido1" type="text" class="form-control" id="apellido1" placeholder="Introduce tu primer apellido">
                </div>
                <div class="mb-4">
                  <label for="apellido2" class="form-label">Segundo apellido</label>
                  <input  formControlName="apellido2" type="text" class="form-control" id="apellido2" placeholder="Introduce tu segundo apellido">
                </div>
                <div class="mb-4">
                  <label for="domicilio" class="form-label">Domicilio</label>
                  <input  formControlName="domicilio" type="text" class="form-control" id="domicilio" placeholder="Introduce tu dirección y número">
                </div>
                <div class="mb-4">
                  <label for="telefono" class="form-label">Telefono</label>
                  <input  formControlName="telefono" type="text" class="form-control" id="telefono" placeholder="Introduce  prefijo + Teléfono  (Opcional)">
                </div>
                <div class="mb-4">
                  <label for="ciudad" class="form-label">Ciudad</label>
                  <input  formControlName="ciudad" type="text" class="form-control" id="ciudad" placeholder="Introduce tu ciudad">
                </div>
                <div class="mb-4">
                  <label for="codigo_postal" class="form-label">Código postal</label>
                  <input  formControlName="codigo_postal" type="text" class="form-control" id="codigo_postal" placeholder="Introduce tu código postal">
                </div>
                <div class="mb-4">
                  <label for="dni" class="form-label">DNI/NIE/Pasaporte</label>
                  <input  formControlName="dni" type="text" class="form-control" id="dni" placeholder="Introduce tu DNI/NIE/Pasaporte">
                </div>
                <div class="mb-4">
                  <label for="fecha_de_nacimiento" class="form-label">Fecha de Nacimiento</label>
                  <input  formControlName="fecha_de_nacimiento" type="date" class="form-control" id="fecha_de_nacimiento" placeholder="Introduce tu fecha de nacimiento">
                </div>
                <div class="mb-4">
                  <label for="nacionalidad" class="form-label">Nacionalidad</label>
                  <select  name="nacionalidad" id="nacionalidad" formControlName="nacionalidad" type="text" class="form-control" >
                    <ng-container *ngFor="let invest of elige">
                      <option [selected]="nacionalidad?.getRawValue() == invest.value" [value]="invest.value">{{invest.viewValue}}</option>
                     </ng-container>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="pais_de_residencia" class="form-label">Pais de Residencia</label>
                  <select  name="pais_de_residencia" id="pais_de_residencia" formControlName="pais_de_residencia" type="text" class="form-control" >
                    <ng-container *ngFor="let invest of elige">
                      <option [selected]="pais_de_residencia?.getRawValue() == invest.value" [value]="invest.value">{{invest.viewValue}}</option>
                     </ng-container>
                  </select>
                </div>
                <div class="mb-4">
                  <label for="trabaja_funcion_publica" class="form-label">¿Eres una persona políticamente expuesta?</label>
                  <select  name="trabaja_funcion_publica" id="trabaja_funcion_publica" formControlName="trabaja_funcion_publica" type="text" class="form-control">
                    <option selected value="selecciona una opcion"> Selecciona una opción</option>  
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                    SI/NO
                  </select>  
                </div>
          
                <div class="mb-4" *ngIf="this.email.value.trabaja_funcion_publica == 'Si'">
                  <label for="funcion_publica" class="form-label">Función pública</label>
                  <input  formControlName="funcion_publica" type="text" class="form-control" id="funcion_publica" placeholder="Introduce tu función pública">
                </div>
              </form>
            </div>
          </div> 
        </div>
    </form>   
    <!--Formulario de documentacion-->
    
  </div>
   <!--TAB de formulario financiero-->
  <div class="new-container" *ngIf="this.fromNewEnterprise.value.Empresa == 'No'" >
    <form  class="bradius" *ngIf="formNewFin !== undefined || formNewFin !== null" [formGroup]="formNewFin" style="margin-top:3% ;">
      <p class="title text-center h5 mb-3 w-100 text-white">Información financiera</p>
      <hr class="w-100 mb-5">
      <div class="mb-4">
        <label for="target_invest_patri" class="form-label">¿Qué porcentaje de tu patrimonio representa la cantidad que quieres invertir en Domoblock?</label>
        <select [class.errorInput]="target_invest_patri?.invalid && (target_invest_patri?.dirty || target_invest_patri?.touched)" name="target_invest_patri" id="target_invest_patri" formControlName="target_invest_patri" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="menos el 25%">Menos el 25%</option>
          <option value="Entre el 25% y 50%">Entre el 25% y 50%</option>
          <option value="Entre el 50% y 75%">Entre el 50% y 75%</option>
          <option value="Mas del 75%">Mas del 75%</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="ingreso_anual" class="form-label">Ingresos brutos anuales:</label>
        <select [class.errorInput]="ingreso_anual?.invalid && (ingreso_anual?.dirty || ingreso_anual?.touched)" name="ingreso_anual" id="ingreso_anual" formControlName="ingreso_anual" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="menos de 21k">Hasta 21.000€</option>
          <option value="entre 21k y 40k">Entre 21.000€ y 40.000€</option>
          <option value="entre 40k y 75k">Entre 40.000€ y 75.000€</option>
          <option value="+75k">Mas de 75.000€</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="gestion_de_empresas_sector" class="form-label">¿Has invertido alguna vez en empresas no cotizadas gestionadas por ti o un tercero?</label>
        <select [class.errorInput]="gestion_de_empresas_sector?.invalid && (gestion_de_empresas_sector?.dirty || gestion_de_empresas_sector?.touched)" name="gestion_de_empresas_sector" id="ingreso_anual" formControlName="gestion_de_empresas_sector" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="knoledgement_securtoken" class="form-label" >¿Conoces lo que es un security token y los riegos que conlleva?<a href="https://cnmv.es/DocPortal/Fintech/CriteriosICOs.pdf?" 
          target="popup" 
          onclick="window.open('https://cnmv.es/DocPortal/Fintech/CriteriosICOs.pdf?','popup','width=600,height=600'); return false;">
            Leer mas
        </a>
        </label>
          <select [class.errorInput]="knoledgement_securtoken?.invalid && (knoledgement_securtoken?.dirty || knoledgement_securtoken?.touched)" name="knoledgement_securtoken" id="knoledgement_securtoken" formControlName="knoledgement_securtoken" type="text" class="form-control" value="">
            <option disabled selected>Selecciona una opción</option>
            <option value="Si">Si</option>
            <option value="No">No</option>
          </select>
      </div>

      <div class="mb-4">
        <label for="knoledgement_quiebra" class="form-label">¿Sabes que invirtiendo en empresas no cotizadas puedes perder hasta el 100% de tu inversión?</label>
        <select [class.errorInput]="knoledgement_quiebra?.invalid && (knoledgement_quiebra?.dirty || knoledgement_quiebra?.touched)" name="knoledgement_quiebra" id="knoledgement_quiebra" formControlName="knoledgement_quiebra" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="estudios" class="form-label">Nivel de estudios</label>
        <select [class.errorInput]="estudios?.invalid && (estudios?.dirty || estudios?.touched)" name="estudios" id="estudios" formControlName="estudios" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="No tengo estudios">No tengo estudios</option>
          <option value="Enseñanza obligatoria">Enseñanza obligatoria</option>
          <option value="Formación profesional/grado">Formación profesional/grado</option>
          <option value="Educación universitaria o superior">Educación universitaria o superior</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="profesion" class="form-label">Profesión</label>
        <input [class.errorInput]="profesion?.invalid && (profesion?.dirty || profesion?.touched)" formControlName="profesion" type="text" class="form-control" id="profesion" placeholder="Introduce tu Profesion">
      </div>

      <div class="mb-4">
        <label for="target_invest" class="form-label">¿Cuál es tu objetivo de inversión?</label>
        <select [class.errorInput]="target_invest?.invalid && (target_invest?.dirty || target_invest?.touched)" name="target_invest" id="target_invest" formControlName="target_invest" type="text" class="form-control">
          <option disabled selected>Selecciona una opción</option>
          <option value="Preservar el poder adquisitivo">Preservar el poder adquisitivo</option>
          <option value="Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado">Equilibrio entre rentabilidad y estabilidad, asumiendo un riesgo moderado</option>
          <option value="Obtener altas rentabilidades asumiendo fuertes riesgos">Obtener altas rentabilidades asumiendo fuertes riesgos</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="time" class="form-label">¿A qué plazo está prevista tu inversión?</label>
        <select [class.errorInput]="time?.invalid && (time?.dirty || time?.touched)" name="time" id="time" formControlName="time" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="Menos de seis meses">Menos de seis meses</option>
          <option value="Entre un año y dos años">Entre un año y dos años</option>
          <option value="Entre 2 y 5 años">Entre 2 y 5 años</option>
          <option value="Más de 5 años">Más de 5 años</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="negative_support" class="form-label">¿Qué rentabilidad negativa anual puedes soportar sin verte forzado a vender?</label>
        <select [class.errorInput]="negative_support?.invalid && (negative_support?.dirty || negative_support?.touched)" name="negative_support" id="negative_support" formControlName="negative_support" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="Mi objetivo es preservar el capital y no estoy dispuesto a sumir rentabilidades negativas">Mi objetivo es preservar el capital y no estoy dispuesto a sumir rentabilidades negativas</option>
          <option value="Hasta el -7% de mi capital">Hasta el -7% de mi capital</option>
          <option value="Hasta el -14% de mi capital">Hasta el -14% de mi capital</option>
          <option value="Hasta el -20% de mi capital">Hasta el -20% de mi capital</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="fuente_ingresos" class="form-label">¿Cuál es la mayor fuente de tus ingresos periódicos?</label>
        <select [class.errorInput]="fuente_ingresos?.invalid && (fuente_ingresos?.dirty || fuente_ingresos?.touched)" name="fuente_ingresos" id="fuente_ingresos" formControlName="fuente_ingresos" type="text" class="form-control" value="">
          <option disabled selected>Selecciona una opción</option>
          <option value="No tengo fuentes de ingresos">No tengo fuentes de ingresos</option>
          <option value="Actividad laboral">Actividad laboral</option>
          <option value="Prestación por discapacidad o jubilación">Prestación por discapacidad o jubilación</option>
          <option value="Rendimientos de inversiones financieras">Rendimientos de inversiones financieras</option>
        </select>
      </div>

      <div class="mb-4">
        <label for="origen_fondos" class="form-label">¿Cuál es el origen de los fondos que vas a invertir en Domoblock?</label>
        <select [class.errorInput]="origen_fondos?.invalid && (origen_fondos?.dirty || origen_fondos?.touched)" name="origen_fondos" id="origen_fondos" formControlName="origen_fondos" class="form-control">Selecciona una opción
          <option value="Actividad laboral">Actividad laboral</option>
          <option value="Herencia o donación">Herencia o donación</option>
          <option value="Inversiones en negocios">Inversiones en negocios</option>
          <option value="Recolocar inversiones financieras">Recolocar inversiones financieras</option>
          <option value="Otros">Otros</option>
        </select>
      </div>
    </form>
    <p style="margin-top:5% " class="formout" >Complete este paso, solo si su actividad habitual es invertir en valores u otros instrumentos financieros o si inviertes más de 10.000€ por operación (Opcional).</p>
    <form class="bradius" *ngIf="formNewFin !== undefined || formNewFin !== null" [formGroup]="formNewFin" >
      <div class="mb-4">
        <label for="inversor_pro" class="form-label">Deseo ser tratado como inversor profesional (persona física)</label>
        <select [class.errorInput]="inversor_pro?.invalid && (inversor_pro?.dirty || inversor_pro?.touched)" name="inversor_pro" id="inversor_pro" formControlName="inversor_pro"  class="form-control">
          <option disabled selected="Seleccione una opcion">Selecciona una opción</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
      </div>
      <hr class="w-100 mb-5" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" >
      <div class="form-check" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" style="width: 100%;" >
        <input [class.errorInput]="inversor_pro?.invalid && (inversor_pro?.dirty || inversor_pro?.touched)" class="form-check-input" type="checkbox" value="" >
        <label class="form-check-label" for="inversor_pro">
          Solicito que con carácter general se me considere “Cliente Profesional” a los efectos de la prestación de todos los servicios de inversión y de los servicios auxiliares sobre todos los instrumentos recogidos en su programa de actividades
        </label>
      </div>

      <hr class="w-100 mb-5" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" >

      <label style="margin-top: 5%;" class="w-100 mb-5" *ngIf="this.formNewFin.value.inversor_pro == 'Si'">Marca la casilla correspondiente si cumple con lo siguiente;</label>
    
      <div class="form-check" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" style="width: 100%;">
        <input [class.errorInput]="lastoper?.invalid && (lastoper?.dirty || lastoper?.touched)" class="form-check-input" type="checkbox" value="" name="lastoper" id="lastoper"  formControlName="lastoper">
        <label class="form-check-label" for="lastoper">
          He realizado operaciones de volumen significativo en el mercado de valores, con frecuencia media de más de diez operaciones por trimestre durante los cuatro trimestres anteriores.         
        </label>
      </div>
      <hr class="w-100 mb-5" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" >
      <div class="form-check" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" style="width: 100%;">
        <input [class.errorInput]="sup500k?.invalid && (sup500k?.dirty || sup500k?.touched)" class="form-check-input" type="checkbox" value="" id="sup500k" formControlName="sup500k" name="sup500k">
        <label class="form-check-label" for="sup500k">
          El valor del efectivo y de los valores que poseo es superior a 500.000 euros.
        </label>
      </div>
      <hr class="w-100 mb-5" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" >
      <div class="form-check" *ngIf="this.formNewFin.value.inversor_pro == 'Si'" style="width: 100%;">
        <input [class.errorInput]="oper_job?.invalid && (oper_job?.dirty || oper_job?.touched)" class="form-check-input" type="checkbox" value="" id="oper_job" formControlName="oper_job" name="oper_job">
        <label class="form-check-label" for="oper_job">
          Ocupo, o he ocupado durante al menos un año, un cargo profesional en el sector financiero que requiere conocimientos sobre las operaciones o servicios que me prestaran.
        </label>
      </div>
    </form>
    <!--botones de atras alante-->
  </div>
  <div class="bottonsToNav">
    <div *ngIf="this.fromNewEnterprise.value.Empresa == 'No'">
      <button class="btn btn-success" (click)="moveToNextSlide(0)">
        <i class="bi bi-arrow-left-circle"></i>Anterior
      </button>
     
      <button class="btn btn-success" (click)="moveToNextSlide(2)" [disabled]="!formNewFin.valid">
        Siguiente<i class="bi bi-arrow-right-circle"></i>
      </button>
    </div>
    <div *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'">
      <button class="btn btn-success" (click)="moveToNextSlide(0)">
        <i class="bi bi-arrow-left-circle"></i>Anterior
      </button>
      
      <button class="btn btn-success" (click)="moveToNextSlide(2)" [disabled]="!formEnterpriseSocios.valid">
        Siguiente<i class="bi bi-arrow-right-circle"></i>
      </button>
    </div>
  </div>
  </div>
  <!--TAB del KYC-->
  <div *ngIf="selectedTabIndex === 2" style="color:#f09502;" >
    <hr class="w-100 mb-5">
    <div class="iframe-container"  style="position:relative;">
      <iframe (load)="delayButton()" [src]="openlink" allow="microphone; camera" style="overflow: scroll !important;"></iframe>
      <div *ngIf="!disablebuton"> <h1> <strong>Cargando video verificación, por favor  espere....</strong></h1> </div>
      <!--Botones atras alante-->
      <div class="bottonsToNav mt-5">
        <div class="kycstylebuttons">
          <button class="btn btn-success" (click)="moveToNextSlide(1)">
            <i class="bi bi-arrow-left-circle"></i>Anterior
          </button>
          <button [disabled]="!disablebuton" class="btn btn-success" (click)="moveToNextSlide(3)">
            Siguiente<i class="bi bi-arrow-right-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--TAB de finalizacion y grabacion-->
  <div *ngIf="selectedTabIndex === 3" style="color:#f09502;">
    <hr class="w-100 mb-5"  >
    <div class="new-container" >
      <form class="bradius"  [formGroup]="formAccept" style="flex-direction: column;" >
        <p class="title text-center h7 mb-3 w-100 text-white">Resumen de datos y final del proceso</p>
        <hr class="w-100 mb-3"  >
        <div class="form-check" style="margin-top:3%" *ngIf="this.fromNewEnterprise.value.Empresa == 'No'"  >
          <input class="form-check-input" type="checkbox" formControlName="datos_veraces"  id="datos_veraces" (click)="this.info()">
          <label class="form-check-label" for="datos_veraces">
           Certifico que los datos aportados son veraces.
          </label>
        </div>
        <div class="form-check" style="margin-top:3%" *ngIf="this.fromNewEnterprise.value.Empresa == 'Si'" >
          <input class="form-check-input" type="checkbox" value="" id="datos_veraces" formControlName="datos_veraces" (click)="this.infoEmpresa()">
          <label class="form-check-label" for="datos_veraces">
           Certifico que los datos aportados son veraces.
          </label>
        </div>
        <div class="form-check" style="margin-top:3%" >
          <input class="form-check-input" type="checkbox" value="" id="terminos_y_condiciones_de_uso" formControlName="terminos_y_condiciones_de_uso">
          <label class="form-check-label" for="terminos_y_condiciones_de_uso" >
           Acepto los términos y condiciones de uso.
          </label>
        </div>
        <div class="form-check" style="margin-top:3%" >
          <input class="form-check-input" type="checkbox" value="" id="politica_de_privacidad" formControlName="politica_de_privacidad" >
          <label class="form-check-label mb-10" for="politica_de_privacidad" >
           Acepto la política de privacidad.
          </label>
        </div>
      </form>
      <div class="flexbox">
        <div class="col-12 col-md-12 col-sm-12" style="margin-bottom: 3%;">
         <button class="btn btn-lg custom-btn-black float-right space " (click)="this.saveUserData()" [disabled]="!formAccept.valid">
          Terminar
         </button>
       </div>
      </div>
   </div>
  </div>
</div>
