import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexLegend
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend; 
};

@Component({
  selector: 'app-my-investments',
  templateUrl: './my-investments.component.html',
  styleUrls: ['./my-investments.component.css']
})
export class MyInvestmentsComponent implements OnInit {

  selectedOption: string = 'resumen';
  resumenFlex: number = 1;
  movimientosFlex: number = 1;
  inversionesFlex: number = 1;

  @ViewChild('chart') chart: any;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [40, 30, 30],
      chart: {
        type: 'donut'
      },
      labels: ['test1', 'test2', 'test3'],
      legend: {
        show: false // Ocultar la leyenda
      },
      responsive: [
        {
          breakpoint: 2000,
          options: {
            chart: {
              height: 150
            },
            legend: {
              show: false,
              position: 'bottom'
            }
          }
        }
      ]
    };
  }

  ngOnInit(): void {
  }

  selectOption(option: string) {
    this.selectedOption = option;
  }



}
