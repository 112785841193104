import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private injector: Injector) { 

  }


  handleError(error: any) {
    
    const modalService = this.injector.get(NgbModal);
    modalService.dismissAll();

    console.error(error);
  }

}
