import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-proyects-set-data',
  templateUrl: './proyects-set-data.component.html',
  styleUrls: ['./proyects-set-data.component.css']
})
export class ProyectsSetDataComponent {
  proyectsToChange: any[] = [];
  proyectData: string | null = null;

  constructor(private afs: AngularFirestore) {
    this.afs.collection('investments', ref => ref
      .where('blockchain', '==', false)
    ).valueChanges().subscribe((res: any) => {
      if (res !== undefined && res !== null) { 
        this.proyectsToChange = []

        res.forEach(async (proyect: any) => {
          this.proyectsToChange?.push(proyect);
        });
      }
    });
  }

  editProyect(proyect: any) {
    this.proyectData = proyect;
  }

  deselectProyect() {
    this.proyectData = null;
  }
}
