import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalKycComponent } from '../modal-kyc.component';
import { KycService as KycSrv} from 'src/app/services/kyc.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom, Subject, take, takeUntil } from 'rxjs';
import { KycService } from 'src/app/services/modal-kyc/kyc.service';
import { KycService as KycServiceOriginal } from 'src/app/services/kyc.service';	
import { ModalConnector } from 'src/app/services/modal-connection.service';

@Component({
  selector: 'app-kyc-completed',
  templateUrl: './kyc-completed.component.html',
  styleUrls: ['./kyc-completed.component.css']
})
export class KycCompletedComponent implements OnInit {
  successKYC: boolean = false;
  revisionKYC: boolean = false;
  fromRevision: boolean = false;
  addedPayment: boolean = false;
  kycDuplicate: boolean = false;
  originalOwner: string = '';
  private destroy$ = new Subject<void>();
  constructor(
    private dialogRef: MatDialogRef<ModalKycComponent>, 
    private readonly kycSrv: KycSrv,
    private authSrv: AuthService,
    private router: Router,
    private afs: AngularFirestore,
    private kycService: KycService,
    private kycServiceOriginal: KycServiceOriginal
  ) {
    this.kycSrv.kycReview.pipe(takeUntil(this.destroy$)).subscribe((res:boolean)=>{
      this.successKYC = !res;
      this.revisionKYC = res;
    })
    this.kycSrv.kycSuccess.pipe(takeUntil(this.destroy$)).subscribe((res:boolean)=>{
      this.successKYC = res;
      this.revisionKYC =!res;
      if (res && !this.addedPayment) this.checkPaymentMethod(res);
    });
    this.kycService.currentPaymentAdded.subscribe((res:boolean)=>{
      this.addedPayment = res;
    })

    this.kycServiceOriginal.kycDuplicated.subscribe( async (data:Boolean | undefined | null) => {
      if(data){
        this.kycDuplicate = true;
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(){
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkPaymentMethod(success: boolean) {
    this.authSrv.userAuth.pipe(take(1)).subscribe(async (user: any) => {
      const result = await this.afs.collection('users').doc(user.uid).get();
      const userData: any  = (await firstValueFrom(result)).data();

      if (success && !userData.bankAccounts && !userData.walletsWhitelist && !this.addedPayment) {
        this.kycService.setModalContent('stages');
        this.kycService.setStageData({nextStage:3, nextStep:1});
      }
    });
  }

  close(){
    this.dialogRef.close();
  }

 async logout() {
    await this.authSrv.logout();
    this.dialogRef.close();
    this.router.navigateByUrl('/login');
    this.authSrv.reload();
  }

}
