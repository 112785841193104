<div class="scroll-wrapper">
    <div class="digital-wallet-container scrollable-container">
        <div class="menu-investment" *ngIf="(path !== '/cartera-digital/blockchain')">
            <div class="menu-options font font-weight-bold" style="position: relative;">
                <p #addFunds (click)="selectOption('addFunds')">INGRESAR DINERO</p>
                <p #withdraw (click)="selectOption('withdraw')">RETIRAR DINERO</p>
                <p #transactions (click)="selectOption('transactions')">MOVIMIENTOS</p>
            </div>

            <div class="divider">
                <div class="divider-section" [ngStyle]="{'width': selectedWidth, 'left': selectedLeft}"></div>
            </div>
        </div>

        <div>
            <div class="add-funds" *ngIf="path === '/cartera-digital/blockchain'">
                <app-blockchain></app-blockchain>
            </div>

            <div class="add-funds" *ngIf="(selectedOption === 'addFunds') && (path === '/cartera-digital/euros')">
                <app-euros></app-euros>
            </div>

            <div class="withdraw" *ngIf="selectedOption === 'withdraw' && (path === '/cartera-digital/euros')">
                <app-withdraw></app-withdraw>
            </div>

            <div class="transactions" *ngIf="selectedOption === 'transactions' && (path === '/cartera-digital/euros')">
                <!-- <app-transactions></app-transactions> -->
                <app-transactions-history></app-transactions-history>
            </div>
        </div>
    </div>
    <div style="width: 2vw; margin-top: -30px; height: 105vh; background-color: white;">
    </div>
</div>


