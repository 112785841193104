<div class="menu-desktop bradius">
    <div class="menu-desktop-1">
        <div>
            <img src="../../../assets/img/logo.png" alt="Domoblock logotipo">
        </div>

        <hr> 

        <p *ngIf="loggedIn && adminFlag" [routerLink]="['/administracion']" routerLinkActive="activo"><i class="bi bi-gear"></i> Administración</p>
        <p [routerLink]="['/oportunidades']" routerLinkActive="activo"><i class="bi bi-lightning-charge-fill"></i> Oportunidades</p>
        <p *ngIf="loggedIn" [routerLink]="['/mis-inversiones']" routerLinkActive="activo"><i class="bi bi-piggy-bank"></i> Mis inversiones</p>
        <p [routerLink]="['/documentacion']" routerLinkActive="activo"><i class="bi bi-file-pdf-fill"></i> Documentos</p>
        <!-- <p *ngIf="loggedIn && !kycFlag" (click)="openModalKyc()" routerLinkActive="activo"><i class="bi bi-person-bounding-box"></i>Continuar KYC</p> -->
        <p *ngIf="loggedIn" [routerLink]="['/perfil']" routerLinkActive="activo"><i class="bi bi-person-circle"></i> Mi perfil</p>
        <p *ngIf="loggedIn" (click)="logout()"><i class="bi bi-x-octagon-fill" style="color:brown;"></i> Cerrar sesión</p>
    </div>
</div>

<div class="card noticia bradius" style="width: 18rem;">
    <div class="card-body" style="background-color:#07070a;">
        <p class="card-title text-white mb-2">Tutorial de Inversión</p>
        <iframe id="iframeYoutube" *ngIf="urlYoutube !== null" width="100%" [src]="urlYoutube | safe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</div>

<div class="rrss">
    <a [href]="urlLinkedIn" target="_blank">
        <img src="../../../assets/img/linkedin.png" alt="Linkedin Domoblock">
    </a>
    <a [href]="urlTwitter" target="_blank">
        <img src="../../../assets/img/twitter.png" alt="Twitter Domoblock">
    </a>
    <a [href]="urlInstagram" target="_blank">
        <img src="../../../assets/img/instagram.png" alt="Instagram Domoblock">
    </a>
    <a [href]="urlTelegram" target="_blank">
        <img src="../../../assets/img/telegram.png" alt="Telegram Domoblock">
    </a>
</div>

