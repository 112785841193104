import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { KycService } from 'src/app/services/kyc.service';
import Swal from 'sweetalert2';
import { KycService as ModalKycSrv } from 'src/app/services/modal-kyc/kyc.service';

@Component({
  selector: 'app-menu-movil',
  templateUrl: './menu-movil.component.html',
  styleUrls: ['./menu-movil.component.css']
})
export class MenuMovilComponent implements OnInit {
  userAuth: any = null;
  loggedIn: boolean = false;
  adminFlag: boolean = false;
  kycFlag: boolean = false;

  constructor(
    private authSrv: AuthService, 
    private kycSrv: KycService,
    private crf: ChangeDetectorRef,
    private router: Router,
    private modalKycSrv: ModalKycSrv
  ) {}

  logout() {
    Swal.fire({
      icon: 'info',
      title: '¿Quieres salir de la aplicación?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Cancelar',
      cancelButtonText: `Ok`,
    }).then(async (result: any) => {
      if (!result.isConfirmed) {
        await this.authSrv.logout();
        this.router.navigateByUrl('/login');
      }
    });
  }

  ngOnInit(): void {
    this.authSrv.userAuth.subscribe((res: any) => {
      this.userAuth = res;
      this.crf.detectChanges();
    });

    this.authSrv.loggedIn.subscribe((res: any) => {
      this.loggedIn = res;
      this.crf.detectChanges();
    });

    this.authSrv.admin.subscribe((res: any) => {
      this.adminFlag = res;
      this.crf.detectChanges();
    });

    this.kycSrv.kycSuccess.subscribe((res: any) => {
      this.kycFlag = res;
      this.crf.detectChanges();
    });
  }

  openModalKyc() {
    this.modalKycSrv.openModalKyc();
  }
}
