import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { IdleService } from './services/idle/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'dapp';

  constructor(private authSrv: AuthService, private idleService: IdleService) {
  }

  ngOnInit(): void {
    setInterval(() => {
      this.clearBackdrops();
    }, 2000);
  }

  clearBackdrops() {
    // Se asegura de que no queden backdrops visibles
    console.log('clearing backdrops');
    const backdrops = document.querySelectorAll('.modal-backdrop');
    backdrops.forEach(backdrop => backdrop.remove());
  }
  
}
