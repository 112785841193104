import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router, Event, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-digital-wallet',
  templateUrl: './digital-wallet.component.html',
  styleUrls: ['./digital-wallet.component.css']
})
export class DigitalWalletComponent implements OnInit, AfterViewInit {

  selectedOption: string = 'addFunds';
  selectedWidth: string = '0px';
  selectedLeft: string = '0px';
  path: string = '';

  @ViewChild('addFunds') addFunds!: ElementRef;
  @ViewChild('withdraw') withdraw!: ElementRef;
  @ViewChild('transactions') transactions!: ElementRef;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.path = this.router.url;
      }
    });
    this.path = this.router.url;

    this.updateIndicator();
  }

  ngAfterViewInit(): void {
    this.updateIndicator();
    this.cdr.detectChanges();
  }

  selectOption(option: string) {
    this.selectedOption = option;
    this.updateIndicator();
  }



  updateIndicator() {
    let element: HTMLElement | undefined;
    switch (this.selectedOption) {
      case 'addFunds':
        element = this.addFunds?.nativeElement;
        break;
      case 'withdraw':
        element = this.withdraw?.nativeElement;
        break;
      case 'transactions':
        element = this.transactions?.nativeElement;
        break;
      default:
        return;
    }

    if (element) {
      const originalWidth = element.offsetWidth;
      const increasedWidth = originalWidth * 1.4;
      const offsetLeft = element.offsetLeft - (increasedWidth - originalWidth) / 2;

      this.selectedWidth = `${increasedWidth}px`;
      this.selectedLeft = `${offsetLeft}px`;
    }
  }

}
