import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { KycService } from 'src/app/services/kyc.service';
import { KycService as ModalKyc} from 'src/app/services/modal-kyc/kyc.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectorsService } from 'src/app/services/selectors.service';
import Swal from 'sweetalert2';
import axios from 'axios';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { __values } from 'tslib';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {
 //variables iniciales
  elige:any
  uid: string = '';
  kycInfo: any = null;
  title = 'Video Verificacion';
  datos_de_socios:{} = {};
  miDiv:any;
  nchivo:any

 // formularios reactivos
  formNewKyc: FormGroup = this.createFormNewKyc();
  formNewFin: FormGroup = this.createFormNewFin();
  fromNewEnterprise: FormGroup = this.createFromNewEnterpise();
  formEnterpriseSocios: FormGroup = this.createFormEnterpriseSocios();
  formEnterpriseFin: FormGroup = this.createFormFinEnterprise()
  formAccept: FormGroup = this.createFormAccept();

 // Neocheck
  url_neo_video:string ='https://neocheck.net/api/v1/VideoIdentifications/unattended/link';
  token: string='';
  openlink: any | null = null;
  configuration:{}= {};
 
 // titulos de los steps

  tabItems = [
    {name:'Informacion Personal',isActive: true},
    {name: 'Informacion Financiera',isActive: false},
    {name: 'Video Verificacion de Identidad',isActive: false},
    {name: 'Resumen de datos',isActive:false}
  ];
 // variables generales

  selectedTabIndex: number = 0;
  openlink2: any;
  id: any;
  data$: any;
  authToken: any;
  datos: any = null;
  token$: any;
  addressUser: any;
  emailForm: any;
  numsocios: number = 0;
  escrituras: string = '' ;
  acta: string = '' ;
  otrosdoc: string = '' ;
  titulares:any;
  fileacta: any;
  filescrito: any;
  fileotros: any;
  disablebuton: any = null
  
 // seleccion de los steps--> se va a desactivar

  toggleActiveClass(index: any) {
    switch (index) {
      case 0: this.selectedTabIndex = index;
        break;
      case 1: this.selectedTabIndex = index;
        break;
      case 2:this.selectedTabIndex = index;
        break;
      case 3:this.selectedTabIndex = index;
        break;
      default:this.selectedTabIndex = 0;
        break;
    }
  }

 //matriz para pasar los steps

  moveToNextSlide(slideIndex: any) {
    this.selectedTabIndex = slideIndex;
    const element = document.getElementById('onTop') as HTMLElement;
    element.scrollTo(0,0)
  }
 /////////////////////////////////////////////////7/ Constructor //////////////////////////////////

  constructor(
    private zone: NgZone,
    private router: Router, 
    private formBuilder: FormBuilder,
    private connectionsWeb3: ConnectionsService ,
    public fns: AngularFireFunctions,
    private storage: AngularFireStorage,
    public sanitizer: DomSanitizer, 
    private kycSrv: KycService, 
    private afs: AngularFirestore, 
    private authSrv: AuthService, 
    private crf: ChangeDetectorRef, 
    public select:SelectorsService,
    private kycService: ModalKyc
    ) { 
    // carga el obserbable del KYC
    this.getDataKyc();
    // carga el array de paises
    this.elige = select.nacionality;
    // Carga el obserbable del access token de la llamada del KYC
   this.neoToken();
    // Carga la conexion de web3 con metamask
    this.addressUser = connectionsWeb3.addressUser
    
  }
 ////////////////////////////////////////////////////7 Constructor ////////////////////////////////////

 // lee la db pa ver si esta verificado  o no

  async getDataKyc() {
    this.uid = await this.authSrv.userAuth.getValue().uid;
    this.afs.collection('users').doc(this.uid).valueChanges().subscribe((res: any) => {
      if (res.kyc !== undefined) {
        this.kycInfo = res.kyc;
      } else {
        this.kycInfo = null;
      }
      this.crf.detectChanges();
    });
  }
  
  // pedir el  access token
  async neoToken() {
    this.fns.httpsCallable('neoToken')({}).subscribe((res:any) =>{
      if (!res.success) { return; }
      this.token = res.token;
    });
  }

 // esta en  el html dentro del formulario de wallet

  async popup(){
    Swal.fire({
      title: 'Si quieres invertir en € y no dispones de una wallet, no te preocupes.',
      text:'Podrás adquirir los tokens de los proyectos a través de una wallet generada y asignada de forma automática. Recuerda que también podrás utilizar tu propia wallet en cualquier momento',
    })
  }
 // esta en la parte de empresa en los titulares
  async popup1(){
    Swal.fire({
      icon:'info',
      text:'El titular real es aquella/s persona/s físicas que posean o controlen directa o indirectamente un porcentaje superior al 25% del capital o poder de voto de la sociedad',
      
    })
  };
  async popup2(){
    Swal.fire({
      icon:'info',
      title: 'El representante es alguien con poderes suficientes como para representar a la sociedad en sus operaciones',
    })
  }
 // pedir el link de video verificacion 
  async getToken() {
    return new Promise(async(resolve,reject)=> {
        this.configuration = {headers: { Authorization: `Bearer ${this.token}`}};
        await axios.post(this.url_neo_video,{},
        this.configuration).then((response) => {
           this.openlink = response.data;
           this.openlink2 = response.data;
          return (this.openlink);
        });
      resolve(this.openlink)
    });
  }
  // popups de aviso legal y textos de seguridad
  // no utilizada ahora mismo //
  async info1() {

    return await Swal.fire({ 
      titleText:'AVISO LEGAL',
      customClass: 'swal-wide',
      html:
        '<embed src="../.././/../assets/aviso legal.pdf" type="application/pdf" width="100%" height="800px" />',
      })
    }
  // ok a los datos
  async info() {
    return await Swal.fire({ 
    titleText:'Declaracion',
    html:`Yo ${this.formNewKyc.value.nombre} con número de identidad ${this.formNewKyc.value.dni} certifico que todos los datos aportados son veraces`,
    confirmButtonText:'Aceptar'
   }).then(() =>{
    this.formAccept.value.datos_veraces = 'certifico que todos los datos aportados son veraces' ;
   })
  }
  async infoEmpresa() {
    return await Swal.fire({ 
    titleText:'Declaracion',
    html:`Yo ${this.formEnterpriseSocios.value.nombre} con número de identidad ${this.formEnterpriseSocios.value.dni} y como representante de  <strong>${this.fromNewEnterprise.value.Nombre_de_la_empresa}</strong> certifico que todos los datos aportados son veraces`,
    confirmButtonText:'Aceptar'
   }).then(() =>{
    this.formAccept.value.datos_veraces = 'certifico que todos los datos aportados son veraces' ;
   })
  }
  async info3() {
    return await Swal.fire({ 
    titleText:'Politica de Privacidad',
    html:'<p>El uso de este Aplicativo Web puede requerir que los Usuarios proporcionen datos personales y financieros. DOMOBLOCK trata este tipo de información de acuerdo con la legislación aplicable, y la recoge en su Política de Privacidad</p>' ,
    confirmButtonText:'Aceptar'
   })
  }

 // control de cambio de variables

  onChanges() {

    this.formNewKyc.get('fecha_de_nacimiento')!.valueChanges.subscribe((val:any) =>{

    })
    
    this.formNewKyc.get('trabaja_funcion_publica')!.valueChanges.subscribe((val: any) => {
      if (val === 'Si') {
        this.setValidatorRequired('funcion_publica', Validators.required);
      } 
      if (val === 'No') {
        this.formNewKyc.get('funcion_publica')?.setErrors(null)
      }
    });

    this.formEnterpriseSocios.get('trabaja_funcion_publica')!.valueChanges.subscribe((val: any) => {
      if (val === 'Si') {
        this.setValidatorRequired1('funcion_publica', Validators.required); 
      } 
      if (val === 'No') {
        this.setValidatorRequired1('funcion_publica', null);
      }
    });
    
    this.fromNewEnterprise.get('Empresa')!.valueChanges.subscribe((val: any) => {
      if(val == 'Si') {
        this.tabItems[0].name = 'Información Empresa';
        this.tabItems[1].name = 'Información Representante y Titulares';
      } else {
        this.tabItems[0].name = 'Información Personal';
        this.tabItems[1].name = 'Información Financiera';
      }
    });
    this.formNewFin.get('knoledgement_quiebra')!.valueChanges.subscribe((val: any) => {
      if(val == 'No') {
        Swal.fire({
          icon:'warning',
          text:'Si seleccionas “No”, no podremos validar tu perfil. Es importante para poder participar en cualquier proyecto que todo usuario conozca los riesgos que conlleva cualquier tipo de inversión'
        })
      }
    });
    
    this.formNewFin.get('knoledgement_securtoken')!.valueChanges.subscribe((val: any) => {
      if(val == 'No') {
        Swal.fire({
          icon:'warning',
          titleText:'Necesitamos confirmar que conoces lo que es un Security Token y los riesgos que la inversión en los mismos conlleva.',
          text:'Los security tokens son activos digitales registrados y almacenados en la blockchain que representan instrumentos financieros tradicionales y, por tanto, son considerados a efectos legales como un vehículo de inversión, regulados por la Ley del Mercado de Valores. ¿Qué es una STO? Un STO es un evento en el cual se comercializan títulos valor (securities) de una empresa determinada, y por consiguiente, tienen que cumplir con los requisitos legales aplicables a títulos-valor (securities) allí donde están siendo emitidos y comercializados. Esto no afecta sólo al mercado primario o venta inicial de los tokens, sino que es aplicable en los exchanges que creen mercados secundarios y que tendrán que cumplir con la regulación financiera en la jurisdicción en la que están basados'
        });
      }
    }); 
  }

  
// funcion que desactiva el boton 40 segundos en el kyc step 3
  delayButton() {
    setTimeout(() => {
      this.disablebuton = true
    }, 60000);
  }

  async saveUserData() {
    Swal.showLoading()
    const text = String(this.openlink2)
    const text1 = text.replace('https://safeonboarding.net/VideoIdentification/Index/','');
    const neoId = text1.replace('?language=es','') ;
    
    this.uid = await this.authSrv.userAuth.getValue().uid;
    this.fns.httpsCallable('neocheckKYC')(neoId).subscribe(async (res:any) => {
      if (!res.success) { 
        Swal.fire({titleText:'Ha habido algun problema con la validacion',
          text:'Revisa si has pasado correctamente la video verificacion y que el número de identidad que utilizaste coincide con el que has registrado en el formulario',  
          confirmButtonText:'OK'
        }).then((result) => {
          console.log('error',res)
        });
        this.router.navigateByUrl('/oportunidades');
        return; 
      } else {
      Swal.close()
      if(this.fromNewEnterprise.value.Empresa == 'Si') {
        const representante = {
          nombre:this.formEnterpriseSocios.value.nombre,
          apellido1:this.formEnterpriseSocios.value.apellido1,
          apellido2:this.formEnterpriseSocios.value.apellido2,
          domicilio:this.formEnterpriseSocios.value.domicilio,
          telefono:this.formEnterpriseSocios.value.telefono,
          ciudad:this.formEnterpriseSocios.value.ciudad,
          codigo_postal:this.formEnterpriseSocios.value.codigo_postal,
          documentIdentification:this.formEnterpriseSocios.value.dni,
          nacionalidad:this.formEnterpriseSocios.value.nacionalidad,
          fecha_de_nacimiento:this.formEnterpriseSocios.value.fecha_de_nacimiento,
          pais_de_residencia:this.formEnterpriseSocios.value.pais_de_residencia,
          vive_en_usa:this.formEnterpriseSocios.value.vive_en_usa,
          trabaja_funcion_publica:this.formEnterpriseSocios.value.trabaja_funcion_publica,
          funcion_publica:this.formEnterpriseSocios.value.funcion_publica,
          empresa_titulo:this.formEnterpriseSocios.value.empresa_titulo,
        };
  
        let arraytitulares = this.emailForm.get('emails') as FormArray;
        let titulares = arraytitulares.getRawValue()
  
        const empresa = {
  
          Empresa:this.Empresa?.getRawValue(),
          CIF:this.CIF?.getRawValue(),
          Razon_Social:this.Razon_Social?.getRawValue(),
          Forma_juridica:this.Forma_juridica?.getRawValue(),
          Nombre_de_la_empresa:this.Nombre_de_la_empresa?.getRawValue(),
          Acta_de_la_empresa:this.acta || 'problema con la acta',
          Escritura_de_la_sociedad:this.escrituras || 'problemas con la escritura',
          otrosdoc:this.otrosdoc || 'problemas con otros documentos'
        }
  
        const  finentrepriseinfo = {
          inversor_pro:this.formEnterpriseFin.value.inversor_pro,
          cliente_profesional:this.formEnterpriseFin.value.cliente_profesional,
          Activos_mas_de_20M:this.formEnterpriseFin.value.Activos_mas_de_20M,
          Negocios_sociedad_mas_40M:this.formEnterpriseFin.value.Negocios_sociedad_mas_40M,
          Recursos_sociedad_sup_2M:this.formEnterpriseFin.value.Recursos_sociedad_sup_2M,
        }
        const disclaimer = {
          datos_veraces:this.formAccept.value.datos_veraces,
          politica_de_privacidad:this.formAccept.value.politica_de_privacidad,
          terminos_y_condiciones_de_uso:this.formAccept.value.terminos_y_condiciones_de_uso,
        }
        await this.afs.collection('users').doc(this.uid)
        .set({
          'Empresa':{
            'Datos_Empresa':empresa,
            'Titulares':titulares,
            'Representante':representante,
            'Inversor_Pro':finentrepriseinfo,
            
          },disclaimer
          },{merge: true});
          
          Swal.fire({
            icon:'success',
            text:'Ha completado el proceso de verificación, si todo ha ido bien podrás disfrutar de los servicios en unos instantes, en caso contrario revisaremos tu petición y nos pondremos en contacto con usted lo antes posible',
            footer:'Recuerda en tu perfil añadir las wallets que desees utilizar',
             
          }).then(()=>{
            if( this.kycInfo == 2 ) {
              this.zone.run(() => { this.router.navigate( ['/oportunidades'] ); });
            } else {
              this.zone.run(() => { this.router.navigate( ['/perfil'] ); }); 
            }
            });
      } else {
        // bloque datos de usuario
  
        const particular = {
          nombre:this.nombre?.getRawValue(),
          apellido1:this.apellido1?.getRawValue(),
          apellido2:this.apellido2?.getRawValue(),
          domicilio:this.domicilio?.getRawValue(),
          telefono:this.telefono?.getRawValue(),
          ciudad:this.ciudad?.getRawValue(),
          codigo_postal:this.codigo_postal?.getRawValue(),
          documentIdentification:this.dni?.getRawValue(),
          nacionalidad:this.nacionalidad?.getRawValue(),
          fecha_de_nacimiento:this.fecha_de_nacimiento?.getRawValue(),
          pais_de_residencia:this.pais_de_residencia?.getRawValue(),
          vive_en_usa:this.vive_en_usa?.getRawValue(),
          trabaja_funcion_publica:this.trabaja_funcion_publica?.getRawValue(),
          funcion_publica:this.funcion_publica?.getRawValue(),
        }
        const fininfo = {
         // bloque datos financieros
          target_invest_patri:this.target_invest_patri?.getRawValue(),
          ingreso_anual:this.ingreso_anual?.getRawValue(),
          gestion_de_empresas_sector:this.gestion_de_empresas_sector?.getRawValue(),
          knoledgement_securtoken:this.knoledgement_securtoken?.getRawValue(),
          knoledgement_quiebra:this.knoledgement_quiebra?.getRawValue(),
          estudios:this.estudios?.getRawValue(),
          profesion:this.profesion?.getRawValue(),
          negative_support:this.negative_support?.getRawValue(),
          fuente_ingresos:this.fuente_ingresos?.getRawValue(),
          origen_fondos:this.origen_fondos?.getRawValue(),
          inversor_pro:this.inversor_pro?.getRawValue(),
          lastoper:this.lastoper?.getRawValue(),
          sup500k:this.sup500k?.getRawValue(),
          oper_job:this.oper_job?.getRawValue(),
          target_invest:this.target_invest?.getRawValue(),
          time:this.time?.getRawValue(),
        }
  
        const disclaimer = {
         datos_veraces:this.datos_veraces?.getRawValue(),
         politica_de_privacidad:this.politica_de_privacidad?.getRawValue(),
         terminos_y_condiciones_de_uso:this.terminos_y_condiciones_de_uso?.getRawValue(),
        }
        console.log(particular,disclaimer,fininfo)
        console.log(`user uid ${this.uid}`)
        // graba datos del usuario
        await this.afs.collection('users').doc(this.uid)
        .set({
          particular,
          disclaimer,
          fininfo,
        },{merge: true });
        Swal.fire({
         icon:'success',
         text:'Ha completado el proceso de verificación, si todo ha ido bien podrás disfrutar de los servicios en unos instantes, en caso contrario revisaremos tu petición y nos pondremos en contacto con usted lo antes posible',
         footer:'Recuerda en tu perfil añadir las wallets que desees utilizar' 
        }).then(()=>{
          if( this.kycInfo == 2 ) {
            this.zone.run(() => { this.router.navigate( ['/oportunidades'] ); });
          } else {
            this.zone.run(() => { this.router.navigate( ['/perfil'] ); }); 
          }
        });
      }
    }
    });
  }
//
  get nombre() { return this.formNewKyc?.get('nombre'); }
  get apellido1() { return this.formNewKyc?.get('apellido1'); }
  get apellido2() { return this.formNewKyc?.get('apellido2'); }
  get domicilio() { return this.formNewKyc?.get('domicilio'); }
  get telefono() { return this.formNewKyc?.get('telefono'); }
  get ciudad() { return this.formNewKyc?.get('ciudad'); }
  get codigo_postal() { return this.formNewKyc?.get('codigo_postal'); }
  get dni() { return this.formNewKyc?.get('dni'); }
  get nacionalidad() { return this.formNewKyc?.get('nacionalidad'); }
  get fecha_de_nacimiento() { return this.formNewKyc?.get('fecha_de_nacimiento'); }
  get pais_de_residencia() { return this.formNewKyc?.get('pais_de_residencia'); }
  get vive_en_usa() { return this.formNewKyc?.get('vive_en_usa'); }
  get trabaja_funcion_publica() { return this.formNewKyc?.get('trabaja_funcion_publica'); }
  get funcion_publica() { return this.formNewKyc?.get('funcion_publica'); }
//
  get rep_nombre() { return this.formEnterpriseSocios?.get('nombre'); }
  get rep_apellido1() { return this.formEnterpriseSocios?.get('apellido1'); }
  get rep_apellido2() { return this.formEnterpriseSocios?.get('apellido2'); }
  get rep_domicilio() { return this.formEnterpriseSocios?.get('domicilio'); }
  get rep_telefono() { return this.formEnterpriseSocios?.get('telefono'); }
  get rep_ciudad() { return this.formEnterpriseSocios?.get('ciudad'); }
  get rep_codigo_postal() { return this.formEnterpriseSocios?.get('codigo_postal'); }
  get rep_dni() { return this.formEnterpriseSocios?.get('dni'); }
  get rep_nacionalidad() { return this.formEnterpriseSocios?.get('nacionalidad'); }
  //
  get rep_fecha_de_nacimiento() { return this.formEnterpriseSocios?.get('fecha_de_nacimiento'); }
  get rep_pais_de_residencia() { return this.formEnterpriseSocios?.get('pais_de_residencia'); }
  get rep_vive_en_usa() { return this.formEnterpriseSocios?.get('vive_en_usa'); }
  get rep_trabaja_funcion_publica() { return this.formEnterpriseSocios?.get('trabaja_funcion_publica'); }
  get rep_funcion_publica() { return this.formEnterpriseSocios?.get('funcion_publica'); }
  get Escritura_de_la_sociedad() { return this.fromNewEnterprise?.get('Escritura_de_la_sociedad'); }
  get Acta_de_la_empresa() { return this.fromNewEnterprise?.get('Acta_de_la_empresa'); }
  get Otros_docs() { return this.fromNewEnterprise?.get('funcion_publica'); }
  //
  get Empresa() { return this.fromNewEnterprise?.get('Empresa'); }
  get CIF() { return this.fromNewEnterprise?.get('CIF'); }
  get Razon_Social() { return this.fromNewEnterprise?.get('Razon_Social'); }
  get Forma_juridica() { return this.fromNewEnterprise?.get('Forma_juridica'); }
  get Nombre_de_la_empresa() { return this.fromNewEnterprise?.get('Nombre_de_la_empresa'); }
  get empresa_ciudad() { return this.fromNewEnterprise?.get('empresa_ciudad'); }
  get empresa_codigo_postal() { return this.fromNewEnterprise?.get('empresa_codigo_postal'); }
  get empresa_pais() { return this.fromNewEnterprise?.get('empresa_pais'); }
  get empresa_titulo() { return this.formEnterpriseSocios?.get('empresa_titulo'); }
  get titular_real() { return this.formEnterpriseSocios?.get('titular_real'); }
  get numero_socios() { return this.fromNewEnterprise?.get('numero_socios'); }
//
  get target_invest_patri() { return this.formNewFin?.get('target_invest_patri'); }
  get ingreso_anual() { return this.formNewFin?.get('ingreso_anual'); }
  get gestion_de_empresas_sector() { return this.formNewFin?.get('gestion_de_empresas_sector'); }
  get knoledgement_securtoken() { return this.formNewFin?.get('knoledgement_securtoken'); }
  get knoledgement_quiebra() { return this.formNewFin?.get('knoledgement_quiebra'); }
  get estudios() { return this.formNewFin?.get('estudios'); }
  get profesion() { return this.formNewFin?.get('profesion'); }
  get negative_support() { return this.formNewFin?.get('negative_support'); }
  get fuente_ingresos() { return this.formNewFin?.get('fuente_ingresos'); }
  get origen_fondos() { return this.formNewFin?.get('origen_fondos'); }
  get lastoper() { return this.formNewFin?.get('lastoper'); }
  get sup500k() { return this.formNewFin?.get('sup500k'); }
  get oper_job() { return this.formNewFin?.get('oper_job'); }
  get target_invest() { return this.formNewFin?.get('target_invest'); }
  get time() { return this.formNewFin?.get('time'); }
  get inversor_pro() { return this.formNewFin?.get('inversor_pro'); }
//
  get datos_veraces() { return this.formAccept?.get('datos_veraces'); }
  get politica_de_privacidad() { return this.formAccept?.get('datos_veraces'); }
  get terminos_y_condiciones_de_uso() { return this.formAccept?.get('datos_veraces'); }
// formulario de particular datos personales
  createFormEnterpriseSocios(){
    return new FormGroup({ 
      nombre: new FormControl(null, Validators.required),
      apellido1: new FormControl(null, [Validators.required]),
      apellido2: new FormControl(null, [Validators.nullValidator]),
      domicilio: new FormControl(null, [Validators.required]),
      telefono: new FormControl(null, [Validators.nullValidator]),
      ciudad: new FormControl(null, [Validators.required]),
      codigo_postal: new FormControl(null, [Validators.required]),
      dni: new FormControl(null, [Validators.required]),
      nacionalidad:new FormControl(null, [Validators.required]),
      fecha_de_nacimiento: new FormControl([Validators.required, this.validateBirthdate]),
      pais_de_residencia: new FormControl(null, [Validators.required]),
      vive_en_usa: new FormControl(null, [Validators.required]),
      trabaja_funcion_publica: new FormControl(null, [Validators.required]),
      funcion_publica: new FormControl(null, [Validators.nullValidator]),
      empresa_titulo: new FormControl(null,Validators.required),
      titular_real: new FormControl(null,Validators.required),

    });
  }

  createFormNewKyc() {
    return new FormGroup({
      nombre: new FormControl(null, Validators.required),
      apellido1: new FormControl(null, Validators.required),
      apellido2: new FormControl(null, Validators.nullValidator),
      domicilio: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.required),
      codigo_postal: new FormControl(null, Validators.required),
      dni: new FormControl(null, Validators.required),
      nacionalidad:new FormControl(null,Validators.required),
      fecha_de_nacimiento: new FormControl([Validators.required,this.validateBirthdate]),
      pais_de_residencia: new FormControl(null,Validators.required),
      vive_en_usa: new FormControl(null, Validators.required),
      trabaja_funcion_publica: new FormControl(null, Validators.required),
      funcion_publica: new FormControl('', Validators.nullValidator),
      
    });  
  }
  createFormNewFin() {
    return new FormGroup({
      target_invest_patri:new FormControl(null,Validators.required),
      ingreso_anual:new FormControl(null,Validators.required),
      gestion_de_empresas_sector:new FormControl(null,Validators.required),
      knoledgement_securtoken:new FormControl(null,Validators.required),
      knoledgement_quiebra:new FormControl(null,Validators.required),
      estudios:new FormControl(null,Validators.required),
      profesion: new FormControl(null,Validators.required),
      negative_support:new FormControl(null,Validators.required),
      fuente_ingresos:new FormControl(null,Validators.required),
      origen_fondos:new FormControl(null,Validators.required),
      lastoper:new FormControl(null,Validators.nullValidator),
      sup500k:new FormControl(null,Validators.nullValidator),
      oper_job:new FormControl(null,Validators.nullValidator),
      target_invest:new FormControl(null,Validators.required),
      time:new FormControl(null,Validators.required),
      inversor_pro:new FormControl('No',Validators.nullValidator),
    })
  }
  createFromNewEnterpise() {
    return new FormGroup({
      Empresa: new FormControl('No',Validators.required),
      CIF: new FormControl(null,Validators.required),
      Razon_Social: new FormControl(null,Validators.required),
      Forma_juridica: new FormControl(null,Validators.required),
      Nombre_de_la_empresa: new FormControl(null,Validators.required),
      empresa_ciudad : new FormControl(null,Validators.required),
      empresa_codigo_postal : new FormControl(null,Validators.required),
      empresa_pais: new FormControl(null,Validators.required),
      numero_socios:new FormControl(this.numsocios,Validators.nullValidator),
      Escritura_de_la_sociedad:new FormControl(null,Validators.required),
      Otros_docs: new FormControl(null,Validators.nullValidator),
      Acta_de_la_empresa: new FormControl(null,Validators.required),
    })
  }
  createFormFinEnterprise() {
    return new FormGroup({
      inversor_pro:new FormControl('No',Validators.required),
      cliente_profesional:new FormControl(null,Validators.required),
      Activos_mas_de_20M:new FormControl(null,Validators.required),
      Negocios_sociedad_mas_40M:new FormControl(null,Validators.required),
      Recursos_sociedad_sup_2M:new FormControl(null,Validators.required),
    });
  }
  

  createFormAccept() {
    return new FormGroup({
       datos_veraces:new FormControl(null,Validators.requiredTrue),
       politica_de_privacidad:new FormControl(null,Validators.requiredTrue),
       terminos_y_condiciones_de_uso:new FormControl(null,Validators.requiredTrue),
    })
  }

  
  /// subiendo la documentacion de la empresa en una coleccion nueva con el filtro del uid de cada documento
  async uploadActa(e: any) {
    this.uid = await this.authSrv.userAuth.getValue().uid;
    const file = e.target.files[0];
    const filePath = `enterprisedocs/${this.uid}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.acta = res;
          this.fileacta = file.name
        })
      })).subscribe();
  }

  async uploadEscritura(e: any) {
    this.uid = await this.authSrv.userAuth.getValue().uid;
    const file = e.target.files[0]; 
    const filePath = `enterprisedocs/${this.uid}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.escrituras = res;
          this.filescrito = file.name
          console.log(this.escrituras,this.escrituras)
        });
        console.log(this.escrituras,this.escrituras)
      })).subscribe();
  }

  async uploadOtros(e: any) {
    this.uid = await this.authSrv.userAuth.getValue().uid;
    const file = e.target.files[0];
    const filePath = `enterprisedocs/${this.uid}/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe((res: string) => {
          this.otrosdoc = res;
          this.fileotros = file.name

          
        });
        console.log(this.otrosdoc,this.fileotros)
      })).subscribe();
  }

  // validacion de NIF

  validatePassportOrDNI(control: FormControl) {

    const dniRegex = /^\d{8}[A-Za-z]$/;
    if (!dniRegex.test(control.value)) {
      return { invalidFormat: true };
    }
  
    const letter = control.value.charAt(8).toUpperCase();
    const number = control.value.slice(0, 8);
    const letterExpected = 'TRWAGMYFPDXBNJZSQVHLCKE'[number % 23];
  
    if (letter !== letterExpected) {
      return { invalidLetter: true };
    }
  
    return null;
  }

  validateBirthdate( control: FormControl ) {
    const birthdate = new Date(control.value);
    const today = new Date();
    const birthdateYear = birthdate.getFullYear();
    const todayYears = today.getFullYear();
    
    if ( birthdateYear + 18 >= todayYears ) {
      return { invalidBirthdate: true }
    } else {
      return null;
    };
    
  }

  async ngOnInit(): Promise<void> {

    //this.kycService.openModalKyc();

   // carga formulario reactivo de titulares de empresa
    this.emailForm = this.formBuilder.group({
      emails: this.formBuilder.array([this.createEmailFormGroup()])
    });
    
    // popup del principio y carga link de verificacion
    //await this.popUp();

    // carga video verificacion
    let video = String(await this.getToken())
    this.openlink =  this.sanitizer.bypassSecurityTrustResourceUrl(video);
    
    this.onChanges(); 
  }
  
  setValidatorRequired(campo:any, requerido:any) {
    this.formNewKyc.controls[campo].setValidators(requerido);   
  }
  setValidatorRequired1(campo:any, requerido:any) {
    this.formEnterpriseSocios.controls[campo].setValidators(requerido);
  }
  
  // añadir titulares y quitarlos formularios
  public addEmailFormGroup() {
    if(this.numsocios == 0){
      this.numsocios = 1;
    } else {
      const emails = this.emailForm.get('emails') as FormArray
      emails.push(this.createEmailFormGroup())
      this.numsocios = emails.length
      this.titulares =emails.getRawValue()
    }
  }

  public removeOrClearEmail(i: number) {
    const emails = this.emailForm.get('emails') as FormArray
    if (emails.length > 1) {
      this.numsocios = this.numsocios -1
      emails.removeAt(i)
    } else {
      this.numsocios = this.numsocios -1
      emails.reset()
    }
  }

  private createEmailFormGroup(): FormGroup {
    return new FormGroup({
      nombre: new FormControl(null, Validators.required),
      apellido1: new FormControl(null, Validators.required),
      apellido2: new FormControl(null, Validators.nullValidator),
      domicilio: new FormControl(null, Validators.required),
      telefono: new FormControl(null, Validators.nullValidator),
      ciudad: new FormControl(null, Validators.required),
      codigo_postal: new FormControl(null, Validators.required),
      dni: new FormControl(null, Validators.required),
      nacionalidad:new FormControl(null,Validators.required),
      fecha_de_nacimiento: new FormControl([Validators.required,this.validateBirthdate]),
      pais_de_residencia: new FormControl(null,Validators.required),
      vive_en_usa: new FormControl(null, Validators.required),
      trabaja_funcion_publica: new FormControl(null, Validators.required),
      funcion_publica: new FormControl(null, Validators.nullValidator),
    });
  }

  checkLogin() {
    this.authSrv.isLoggedIn();
  }

  async popUp() {

    const logged = await this.authSrv.isLoggedIn();

    if(logged){
      let timerInterval:any;
      const { value: accept } = await Swal.fire({
        title:'Bienvenido/a al proceso de verificación',
        inputValue:1,
        text:'Por seguridad necesitamos validar su identidad (KYC) e información financiera (AML). Le llevará solo unos minutos.',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton  : false, 
        timer:8000,
        footer:'Cargando el link de verificacion',
        didOpen: () => {
          Swal.showLoading()
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      });
      if(accept){
      this.formNewKyc.value.certify_disclaimer = true;
      }
    }
  }
  

}