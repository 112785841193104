import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-uri-youtube',
  templateUrl: './uri-youtube.component.html',
  styleUrls: ['./uri-youtube.component.css']
})
export class UriYoutubeComponent implements OnInit {
  formYoutube: FormGroup = this.createFormYoutube();

  constructor(private afs: AngularFirestore) { }

  createFormYoutube() {
    return new FormGroup({
      url: new FormControl(null, [Validators.required])
    })
  }

  get url() { return this.formYoutube?.get('url'); }

  setVideo() {
    if (this.formYoutube.invalid) { 
      this.formYoutube.markAllAsTouched();
      return; 
    }

    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').set({
      urlYoutubeMenu: this.url?.getRawValue()
    }, {merge: true})
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: `Url de del vídeo actualizada`
        });
      })
      .catch(() => { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  ngOnInit(): void {
    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').get().subscribe((res: any) => {
      if (res.exists) {
        this.url?.patchValue(res.data().urlYoutubeMenu);
      }
    });
  }
}
