<div *ngIf="info !== undefined" class="card bradius">
    <div class="containerImg" [ngStyle]="{'background-image': getUrlMainImage()}">
    </div>
    <div class="card-body">
        <div class="title mb-4 mt-2">
            <img loading="lazy" src="../../../assets/img/grafismo-gris.png">
            <div>
                <p *ngIf="info.nameTokens !== null" class="fw-bold small-text">{{info.nameTokens}}</p>
                <p *ngIf="info.nameTokens === null" class="fw-bold">{{info.location}}</p>
                <p *ngIf="info?.onlyInversors && info?.proyectStatus == 'Activo' && !(eurosSold >= info.amountToSell * (info.priceToken / 100))"
                    class="bradius small estado text-center" style="background-color: #a2cb6c;">
                    Venta privada
                </p>
                <p *ngIf="info?.proyectStatus == 'En estudio'" class="bradius small estado text-center"
                    style="background-color: #FA629C;">{{info?.proyectStatus}}</p>
                <p *ngIf="!info?.onlyInversors && info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) != 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #a2cb6c;">{{info?.proyectStatus}}
                </p>
                <p *ngIf="info?.proyectStatus == 'Activo' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #848484;">Financiado</p>
                <p *ngIf="info?.proyectStatus == 'Activo' && toFloor(info.endOfSale) == 'Tiempo agotado' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="bradius small estado text-center" style="background-color: #848484;">Finalizado</p>
                <p *ngIf="info?.proyectStatus == 'Repartiendo dividendos'" class="bradius small estado text-center"
                    style="background-color: #6EC8CA;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Finalizado'" class="bradius small estado text-center"
                    style="background-color: #848484;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Vendido'" class="bradius small estado text-center"
                    style="background-color: #0093A2;">{{info?.proyectStatus}}</p>
                <p *ngIf="info?.proyectStatus == 'Financiado'" class="bradius small estado text-center"
                    style="background-color: #848484;">{{info?.proyectStatus}}</p>
            </div>
        </div>
        <div class="mb-3 w-100 containerProgress">
            <div class="pdata">
                <div>
                    <p class="small mb-1">Financiación</p>
                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{eurosSold | euros}}</p>
                </div>
                <div>
                    <p class="small mb-1">Objetivo</p>
                    <p class="small mb-1 fw-bold" style="font-size: 1rem;">{{info.amountToSell * info.priceToken/100 |
                        euros}}</p>
                </div>
            </div>
            <div class="progress">
                <div *ngIf="eurosSold > 0 && eurosSold < info.amountToSell * (info.priceToken / 100)"
                    class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-label="Animated striped example"
                    [attr.aria-valuenow]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'"
                    aria-valuemin="0" aria-valuemax="100"
                    [style.width.%]="(eurosSold * 100) / (info.amountToSell * (info.priceToken / 100)) | number : '1.2-2'">
                    {{percentageSold}}%
                </div>
                <div *ngIf="eurosSold >= info.amountToSell * (info.priceToken / 100)"
                    class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-label="Animated striped example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                    style="width: 100%">
                    100%
                </div>
            </div>
            <div class="pdata">
                <div>
                    <p class="small mb-1">{{info.numberInvestors}} Inversiones</p>
                </div>
                <div>
                    <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold >= info.amountToSell * (info.priceToken / 100)"
                        class="small mb-1">Inversión completa</p>
                    <p *ngIf="toFloor(info.endOfSale) != '0' && eurosSold < info.amountToSell * (info.priceToken / 100)"
                        class="small mb-1">{{toFloor(info.endOfSale)}}</p>
                </div>
            </div>
        </div>
        <div class="mb-4 w-100 pdatas">
            <div class="pdata1">
                <p class="small w-100" style="font-size: .9rem;">Plazo Estimado</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.estimatedDeliveryTime}}</p>
            </div>
            <div class="pdata2">
                <p class="small w-100" style="font-size: .9rem;">Rentabilidad Anual</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.annualReturn}}%</p>
            </div>
            <div class="pdata3">
                <p class="small w-100" style="font-size: .9rem;">Precio Ticket</p>
                <p class="mb-1 fw-bold" style="align-self: flex-end;">{{info.priceToken/100 | euros}}</p>
            </div>
        </div>
        <div class="containerButtons">
                    <button [disabled]="!logged || loadingButtons" type="button" class="btn btn-outline-success mb-2" (click)="openFullScreenModal()"><i
                    class="bi bi-search"></i><span style="margin-left:10px">{{ loadingButtons ? 'Cargando detalle' : 'Ver detalle'}}</span></button>
            <button (click)="goToInvestment()"
                [disabled]="(!info.blockchain || info?.proyectStatus != 'Activo' || eurosSold >= info.amountToSell * (info.priceToken / 100) || toFloor(info.endOfSale) == 'Tiempo agotado') || loadingButtons"
                class="btn btn-success text-center">
                <i class="bi bi-graph-up-arrow"></i><span style="margin-left:10px">{{ loadingButtons ? 'Cargando inversión' : 'Invertir'}}</span>
            </button>
        </div>
    </div>
</div>

<!-- <ng-template #modalContent let-modal>
    <div class="modal-body dataTransferencia">
        <div style="    display: flex;
        justify-content: flex-end;
        margin: 1rem;">
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="container">
            <div>
                <h6 style="color: #FF9600; font-weight: bold;">Datos de la transferencia:</h6>
            </div>
            <div class="dataDisplay">
                <div>
                    <p>Banco</p>
                    <p>Beneficiario</p>
                    <p class="iban">IBAN</p>
                    <p class="importe">Importe</p>
                    <p>Concepto</p>
                    <p>SWIFT/BIC</p>

                </div>
                <div>
                    <p style="color: black;">CaixaBank (ES)</p>
                    <p style="color: black;">Domoblock Real Estate Investment S.L</p>
                    <p style="color: black;">ES13 2100 2369 5402 0036 7286</p>
                    <p style="color: black;">{{euroAmountTransferencia}}€</p>
                    <p style="color: black;">{{info.nameTokens}}</p>
                    <p style="color: black;">CAIX ES BB XXX</p>

                </div>
            </div>
            <div style="
            display: flex;
            gap: 10px;
            flex-direction: row;
            padding-top: 1rem;
            align-items: center;">
                <label style="color: black;">Envíanos el comprobante de la transferencia a <a style="color: #0075FF;"
                        href="mailto:info@domoblock.io">inversiones@domoblock.io</a> con el mismo correo que estás
                    registrado.
                </label>
            </div>
            <div style="display: flex;
                gap: 10px;
                flex-direction: row;
                padding-top: 1rem;
                align-items: center;">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <label style="color: black;">La inversión mínima por transferencia bancaria es de 500€.
                </label>
            </div>
            <div style="display: flex;
                    gap: 10px;
                    flex-direction: row;
                    padding-top: 1rem;
                    align-items: center;">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <label style="color: black;">Realizar la transferencia no te garantiza una participación en el proyecto.
                </label>
                <span class="tool"
                    data-tip="Procesaremos las transferencias en el orden en que recibamos los comprobantes; pero en momentos de alta demanda, es posible que la financiación del proyecto se complete antes de que podamos atender todas las solicitudes. En esos casos, te notificaremos por correo electrónico y te reembolsaremos el monto total a tu cuenta bancaria."
                    tabindex="1" data-toggle="tooltip" data-placement="right"> <i class="fa fa-question-circle"
                        aria-hidden="true"></i>
                </span>

            </div>
        </div>

    </div>
   

</ng-template> -->