import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import { BehaviorSubject, take } from 'rxjs';
import { ConnectionsService } from './connectionsWeb3/connections.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from '../components/modal-register/modal-register.component';
import { ModalLoginComponent } from '../components/modal-login/modal-login.component';
import { KycService } from './modal-kyc/kyc.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  loggedIn: any = new BehaviorSubject<boolean>(false);
  userAuth: any = new BehaviorSubject<any>(null);
  admin: any = new BehaviorSubject<any>(null);
  private kycService!: KycService | null;

  constructor(
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    private fns: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    private router: Router,
    private web3Connections: ConnectionsService,
    private modal: NgbModal,
    private injector: Injector
  ) {
 

  }


  openKyc(){
  this.kycService = this.injector.get(KycService);
  if (this.kycService) {
    this.kycService.openModalKyc();
  }
}

  async isLoggedIn(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.afAuth.authState.pipe(take(1)).subscribe((userAuth: any) => {
        if (userAuth) {
          this.userAuth.next(userAuth);
          this.loggedIn.next(true);
          this.checkAdmin();
          // if(!userAuth.hideBlockchain)
          this.afs.collection('users').doc(userAuth.uid).valueChanges().subscribe((user: any) => { 
            if(!user.hideBlockchain)
              this.web3Connections.connect();
          })
          resolve(true);
        } else {
          this.userAuth.next(null);
          this.loggedIn.next(false);
          resolve(false);
        }
      });
    });
  }
  
  registerWithEmail(email: string, password: string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Enviando los datos ...'
    });
    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    firebase.auth().createUserWithEmailAndPassword(email, password).then((resProfile: any) => {

      resProfile.user.sendEmailVerification()
        .then(() => {
          Swal.fire({
            imageUrl: '../../assets/img/email.png',
            imageAlt: 'Email Img',
            title: '¡Revisa tu correo!',
            html: '<p style="color: #292929">Verifica tu email a través del correo que te hemos enviado para completar tu registro en Domoblock. Si no lo ves, recuerda revisar la carpeta de spam.</p>' + '<br><br><label style="color: #292929">¿No lo has recibido? <span style="color: #0093A2; cursor: pointer;" id="resendEmail">Enviar de nuevo</span></label>',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCloseButton: true
          }).then(() => {
            Swal.close();
            this.logout();
            this.closeModalRegister();
            this.openLoginForm();
          });


          const resendEmailElement = document.getElementById('resendEmail');
          if (resendEmailElement) {
            resendEmailElement.addEventListener('click', () => {
              resProfile.user.sendEmailVerification();
              Swal.fire({
                icon: 'success',
                text: '¡Email enviado!',
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then(() => {
                this.logout();
                this.router.navigateByUrl('/login');
              });
            });
          }
        })
        .catch(() => {
          firebase.auth().currentUser?.delete();

          Swal.close();
          Swal.fire({
            icon: 'error',
            text: '¡Algo ha ido mal! Inténtalo más tarde.'
          });

          this.logout();
        });
    })
      .catch((error: any) => {
        Swal.close();
        if (error.code === 'auth/email-already-in-use') {
          Swal.fire({
            icon: 'error',
            text: '¡Este email ya existe!'
          }).then(() => {
            Swal.close();
            this.logout();
            this.closeModalRegister();
            this.openRegisterForm();
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: '¡Algo ha ido mal! Inténtalo más tarde.'
          }).then(() => {
            Swal.close();
            this.logout();
            this.closeModalRegister();
            this.openRegisterForm();
          })
        }
      });
  }

  async signInWithGoogle() {
    await this.afAuth.setPersistence('local'); // Habilita la persistencia de sesión
    await this.afAuth.signInWithPopup(
      new firebase.auth.GoogleAuthProvider()
    ).then((res: any) => {
      this.closeModalLogin();
      this.afs.collection('users').doc(res.user.uid).get().subscribe((userDoc: any) => {
        if (userDoc.exists) {
          this.fns.httpsCallable('addCustomClaims')({}).subscribe((res: any) => {
            (res.success) ? this.checkAdmin() : null;
          });

          const user = userDoc.data();
          console.log(user);
          this.router.navigateByUrl('/oportunidades');
          this.openKyc();
          // (user.kyc === undefined) ? this.router.navigateByUrl('/kyc') : null;
          // (user.kyc.result === undefined) ? this.router.navigateByUrl('/kyc') : null;
          // (user.kyc.result === 1 || 2) ? this.router.navigateByUrl('/oportunidades') : this.router.navigateByUrl('/kyc');
        } else {
          this.createUser(res.user.uid, res.user.email, res.user.displayName);

        }
      });
    })
  }

  async registerWithGoogle() {
    try {
      await this.afAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider())
        .then((res: any) => {
          this.closeModalRegister();
          this.afs.collection('users').doc(res.user._delegate.uid).get().subscribe((userDoc: any) => {
            console.log('entre aqui')
            if (userDoc.exists) {
              this.fns.httpsCallable('addCustomClaims')({}).subscribe((res: any) => {
                (res.success) ? this.checkAdmin() : null;
              });

              const user = userDoc.data();
              this.router.navigateByUrl('/oportunidades');
              this.openKyc();
              // (user.kyc === undefined) ? this.router.navigateByUrl('/kyc') : null;
              // (user && user.kyc && user.kyc.result === undefined) ? this.router.navigateByUrl('/kyc') : null;
              // (user.kyc.result === 1 || user.kyc.result === 2) ? this.router.navigateByUrl('/oportunidades') : this.router.navigateByUrl('/kyc');
            } else {
              this.createUser(res.user.uid, res.user.email, res.user.displayName)
            }
          });
        })
    } catch (error) {
      console.log(error);
    }
  }

  login(email: string, password: string) {
    firebase.auth().signInWithEmailAndPassword(email, password)
      .then((res: any) => {
        if (!res.user.emailVerified) {
          this.sendOtherEmailVerification();
          return;
        }

        this.afs.collection('users').doc(res.user.uid).get().subscribe((userDoc: any) => {
          if (!userDoc.exists) {
            this.logout();
            return;
          }

          this.fns.httpsCallable('addCustomClaims')({}).subscribe((res: any) => {
            (res.success) ? this.checkAdmin() : null;
          });

          const user = userDoc.data();
          this.router.navigateByUrl('/oportunidades');
          this.openKyc();
          // (user.kyc === undefined) ? this.router.navigateByUrl('/kyc') : null;
          // (user.kyc.result === undefined) ? this.router.navigateByUrl('/kyc') : null;
          // (user.kyc.result === 1 || 2) ? this.router.navigateByUrl('/oportunidades') : this.router.navigateByUrl('/kyc');
        });
      })
      .catch((error: any) => {
        if (error.code === 'auth/user-not-found') {
          Swal.fire({
            icon: 'error',
            text: 'El correo electrónico ingresado no está registrado.'
          }).then(() => {
            Swal.close()
            this.logout();
            this.closeModalLogin();
            this.openLoginForm();
          })
        } else if (error.code === 'auth/wrong-password') {
          Swal.fire({
            icon: 'error',
            text: 'La contraseña ingresada es incorrecta.',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            Swal.close()
            this.logout();
            this.closeModalLogin();
            this.openLoginForm();
          })
        } else if (error.code === 'auth/invalid-login-credentials') {
          Swal.fire({
            icon: 'error',
            text: 'El correo o la contraseña ingresada son incorrectos.',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            Swal.close()
            this.logout();
            this.closeModalLogin();
            this.openLoginForm();
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: '¡Algo ha ido mal! Inténtalo más tarde.',
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then(() => {
            Swal.close()
            this.logout();
            this.closeModalLogin();
            this.openLoginForm();
          })
        }


      });
  }

  async checkAdmin() {
    firebase.auth().currentUser?.getIdTokenResult(true).then((res: any) => {
      (res.claims.admin !== undefined && res.claims.admin !== null) ? this.admin.next(res.claims.admin) : this.admin.next(false);
    });
  }

  async logout() {
    await firebase.auth().signOut();
  }

  reload() {
    location.reload();
  }

  sendOtherEmailVerification() {
    Swal.fire({
      imageUrl: '../../assets/img/email.png',
      imageAlt: 'Email Img',
      title: '¡Correo electrónico sin verificar!',
      html: '<p style="color: #292929">Para acceder a la plataforma de inversión, antes debes verificar tu correo. Si no lo ves, recuerda revisar la carpeta de spam.</p>' + '<br><br><span style="color: #0093A2; cursor: pointer;" id="resendEmail">Enviar correo de verificación de nuevo.</span>',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true
    }).then(() => {
      Swal.close();
      this.logout();
      this.closeModalRegister();
      this.openLoginForm();
    });

    const resendEmailElement = document.getElementById('resendEmail');
    if (resendEmailElement) {
      resendEmailElement.addEventListener('click', () => {
        firebase.auth().currentUser?.sendEmailVerification();
        Swal.fire({
          icon: 'success',
          text: '¡Email enviado!',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          Swal.close();
          this.logout();
          this.closeModalLogin();
          this.openLoginForm();
        }).catch(() => {
          firebase.auth().currentUser?.delete();
          Swal.close();
          Swal.fire({
            icon: 'error',
            text: '¡Algo ha ido mal! Inténtalo más tarde.'
          }).then(() => {
            Swal.close();
            this.logout();
            this.closeModalLogin();
            this.openLoginForm();
          })
        });

      })

    }



  }


  resetPassword(email: string) {
    firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        Swal.close();
        Swal.fire({
          icon: 'success',
          text: 'Te hemos enviado un email',
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          Swal.close();
          this.logout();
          this.closeModalLogin();
          this.openLoginForm();
        })
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          text: `Algo ha ido mal... Parece que el email "${email}" es erróneo.`,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(() => {
          Swal.close();
          this.logout();
          this.closeModalLogin();
          this.openLoginForm();
        })
      });
  }

  createUser = async (uid: string, email: string, displayName: string) => {
    try{
      await this.afs.collection('users').doc(uid).set({ uid, email, displayName }, { merge: true });
      this.router.navigateByUrl('/oportunidades');
      setTimeout(() => {
        this.openKyc();
      }, 1000);
    } catch (error: any) {

      // Swal.fire({
      //   icon: 'error',
      //   text: '¡Algo ha ido mal al momento del registro! Vuelve a intentarlo.'
      // }).then(() => {
      //   Swal.close();
      //   this.logout();
      // });

      if (error.code !== 'permission-denied') { 
        Swal.fire({
          icon: 'error',
          text: '¡Algo ha ido mal! Inténtalo más tarde.'
        }).then(() => {
          Swal.close();
          this.logout();
        })
      } else {
        this.router.navigateByUrl('/oportunidades')
        setTimeout(() => {
          this.openKyc();
        }, 1000);
      }
    
    }
    
  }

  openRegisterForm() {
    const modalRef = this.modal.open(ModalRegisterComponent);
    modalRef.closed.subscribe(() => {
      console.log('Modal cerrado');
    });
  }

  openLoginForm() {
    const modalRef = this.modal.open(ModalLoginComponent);
    modalRef.closed.subscribe(() => {
      console.log('Modal cerrado');
    });
  }

  closeModalLogin() {
    $(document).ready(() => {
      $('#loginModal').modal('hide');
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    });
    this.modal.dismissAll();
    const modal = document.getElementsByClassName('modal-backdrop fade show')[0];
    if (modal) {
      modal.parentNode!.removeChild(modal);
    }
    document.body.classList.remove('modal-open');
  }

  closeModalRegister() {
    $(document).ready(() => {
      $('#registerModal').modal('hide');
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    });
    this.modal.dismissAll();
    const modal = document.getElementsByClassName('modal-backdrop fade show')[0];
    if (modal) {
      modal.parentNode!.removeChild(modal);
    }
    document.body.classList.remove('modal-open');
  }
}


