import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuDesktopComponent } from './components/menu-desktop/menu-desktop.component';
import { MenuMovilComponent } from './components/menu-movil/menu-movil.component';
import { CardRealEstateComponent } from './components/card-real-estate/card-real-estate.component';
import { DetailRealEstateComponent } from './components/detail-real-estate/detail-real-estate.component';
import { AdminComponent } from './components/admin/admin.component';
import { UserInvestmentsComponent } from './components/user-investments/user-investments.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { NavbarUserComponent } from './components/navbar-user/navbar-user.component';
import { CardsRealEstateComponent } from './components/cards-real-estate/cards-real-estate.component';
import { LoginComponent } from './components/login/login.component';
import { KycComponent } from './components/kyc/kyc.component';
import { EurosPipe } from './pipes/euros.pipe';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAnalytics,getAnalytics,ScreenTrackingService,UserTrackingService } from '@angular/fire/analytics';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { provideFunctions,getFunctions } from '@angular/fire/functions';
import { provideMessaging,getMessaging } from '@angular/fire/messaging';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { providePerformance,getPerformance } from '@angular/fire/performance';
import { provideStorage,getStorage } from '@angular/fire/storage';
import { SafePipe } from './pipes/safe.pipe';
import { NewProyectComponent } from './components/new-proyect/new-proyect.component';
import { SetRolComponent } from './components/set-rol/set-rol.component';
import { NewSaleSellerComponent } from './components/new-sale-seller/new-sale-seller.component';
import { DocumentationGeneralComponent } from './components/documentation-general/documentation-general.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SetDataSaleComponent } from './components/set-data-sale/set-data-sale/set-data-sale.component';
import { AddDividendsComponent } from './components/add-dividends/add-dividends/add-dividends.component';
import { ProyectsSetDataComponent } from './components/proyects-set-data/proyects-set-data/proyects-set-data.component';
import { ProyectSetDataComponent } from './components/proyect-set-data/proyect-set-data/proyect-set-data.component';
import { UriYoutubeComponent } from './components/uriYoutube/uri-youtube/uri-youtube.component';
import { ProfileComponent } from './components/profile/profile/profile.component';
import { BuyThroughCompanyComponent } from './components/buy-through-company/buy-through-company/buy-through-company.component';
import { SetWhitelistComponent } from './components/set-whitelist/set-whitelist/set-whitelist.component';
import { ForcedTransferComponent } from './components/forced-transfer/forced-transfer/forced-transfer.component';
import { SetPauseComponent } from './components/set-pause/set-pause/set-pause.component';
import { SetKycComponent } from './components/set-kyc/set-kyc/set-kyc.component';
import { RevertPaymentsComponent } from './components/revert-payments/revert-payments/revert-payments.component';
import { InfoDividendsComponent } from './components/info-dividends/info-dividends/info-dividends.component';
import { BuyWithoutPayComponent } from './components/buy-without-pay/buy-without-pay/buy-without-pay.component';
import { HideProyectComponent } from './components/hide-proyect/hide-proyect/hide-proyect.component';
import { DocsLegalComponent } from './components/docs-legal/docs-legal/docs-legal.component';
import { ModalLoginComponent } from './components/modal-login/modal-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalRegisterComponent } from './components/modal-register/modal-register.component';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { HammerModule } from '@angular/platform-browser';
import { ClickTouchDirective } from './click-touch.directive';
import { AuthService } from './../app/services/auth.service';
import { PaymentSuccessfulComponent } from './components/payment-result/payment-successful/payment-successful.component';
import { PaymentErrorComponent } from './components/payment-result/payment-error/payment-error.component';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { ModalKycComponent } from './components/modal-kyc/modal-kyc.component';
import { MatDialogModule } from '@angular/material/dialog';
import { KycStagesComponent } from './components/modal-kyc/kyc-stages/kyc-stages.component';
import { KycRegisterComponent } from './components/modal-kyc/kyc-register/kyc-register.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SpinnerComponent } from './components/UI/spinner/spinner.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgSelectModule } from '@ng-select/ng-select';
import { CustomDialogComponent } from './components/dialogs/custom-dialog/custom-dialog.component';
import { KycSurveyComponent } from './components/modal-kyc/kyc-survey/kyc-survey.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AdminWalletComponent } from './components/admin-wallet/admin-wallet.component';
import { KycCompletedComponent } from './components/modal-kyc/kyc-completed/kyc-completed.component';
import { MyInvestmentsComponent } from './components/my-investments/my-investments.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DigitalWalletModule } from './components/digital-wallet/digital-wallet.module';
import { SharedModule } from './components/shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    MenuDesktopComponent,
    MenuMovilComponent,
    CardRealEstateComponent,
    DetailRealEstateComponent,
    AdminComponent,
    UserInvestmentsComponent,
    DocumentationComponent,
    MainContainerComponent,
    NavbarUserComponent,
    CardsRealEstateComponent,
    LoginComponent,
    KycComponent,
    EurosPipe,
    SafePipe,
    NewProyectComponent,
    SetRolComponent,
    NewSaleSellerComponent,
    AddDividendsComponent,
    DocumentationGeneralComponent,
    SetDataSaleComponent,
    AddDividendsComponent,
    ProyectsSetDataComponent,
    ProyectSetDataComponent,
    UriYoutubeComponent,
    ProfileComponent,
    BuyThroughCompanyComponent,
    SetWhitelistComponent,
    ForcedTransferComponent,
    SetPauseComponent,
    SetKycComponent,
    RevertPaymentsComponent,
    InfoDividendsComponent,
    BuyWithoutPayComponent,
    HideProyectComponent,
    DocsLegalComponent,
    ModalLoginComponent,
    ModalRegisterComponent,
    ClickTouchDirective,
    PaymentSuccessfulComponent,
    PaymentErrorComponent,
    ModalKycComponent,
    KycStagesComponent,
    KycRegisterComponent,
    SpinnerComponent,
    CustomDialogComponent,
    KycSurveyComponent,
    AdminWalletComponent,
    KycCompletedComponent,
    MyInvestmentsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxJsonViewerModule,
    HttpClientModule,
    HammerModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    NgbModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgSelectModule,
    MatTooltipModule,
    NgApexchartsModule,
    DigitalWalletModule,
    SharedModule
  ],
  providers: [
    ScreenTrackingService,
    UserTrackingService,
    AuthService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
