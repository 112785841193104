import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as jsonManager from '../../../abis/Manager.json';
import { environment } from '../../../../environments/environment';
import { ConnectionsService } from 'src/app/services/connectionsWeb3/connections.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-set-pause',
  templateUrl: './set-pause.component.html',
  styleUrls: ['./set-pause.component.css']
})
export class SetPauseComponent {
  formPause: FormGroup = this.createFormPause(); 

  ourAddress: string | undefined;
  web3: any | undefined;
  abiManager: any = jsonManager;
  contractManager: any | undefined;

  constructor(private connSrv: ConnectionsService, private afs: AngularFirestore) { 
    this.web3 = this.connSrv.web3Instance;

    this.connSrv.addressUser.subscribe((res: string) => {
      this.ourAddress = res;
    });

    this.contractManager = new this.web3.eth.Contract(this.abiManager.abi, environment.config.ADDRESS_MANAGER);
  }

  createFormPause() {
    return new FormGroup({
      addressContract: new FormControl(null, [Validators.required]),
      flag: new FormControl(null, [Validators.required])
    })
  }

  get addressContract() { return this.formPause?.get('addressContract'); }
  get flag() { return this.formPause?.get('flag'); }

  async setPause() {
    if (this.formPause.invalid) { 
      this.formPause.markAllAsTouched();
      return; 
    }

    Swal.fire({
      title: 'Procesando...',
      html: 'No cierres esta pantalla!!',
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading()
      }
    });

    let result: string = 'Contrato pausado';
    if (this.flag?.getRawValue() != 'true' && this.flag?.getRawValue() != true) {
      result = 'Contrato reiniciado'
    }

    let flag: boolean = true;
    (this.flag?.getRawValue() != 'true' && this.flag?.getRawValue() != true) ? flag = false : null;

    const baseFee: number = await this.web3.eth.getBlock('pending').then((block: any) => { 
      return Number(block.baseFeePerGas) + 100000000000; 
    });

    this.contractManager.methods.setPause(
      this.addressContract?.getRawValue(),
      flag
    ).send({from: this.ourAddress, maxFeePerGas: this.web3.utils.toHex(baseFee), maxPriorityFeePerGas: this.web3.utils.toHex(this.web3.utils.toWei('50', 'gwei'))}) 
      .then(() => {
        let status: string;
        (result === 'Contrato pausado') ? status = 'Vendido' : status = 'Activo';
        this.afs.collection('investments', ref => ref.where('tokensAddress', '==', this.addressContract?.getRawValue()).limit(1)).get()
          .subscribe(async (snapDocs: any) => {
            this.afs.collection('investments').doc(snapDocs.docs[0].data().id).set({
              proyectStatus: status
            }, {merge: true});
    
            Swal.close();
            Swal.fire({
              icon: 'success',
              text: result
            });
    
            this.formPause.reset();
          });
      })
      .catch(() => {
        Swal.close();
        Swal.fire({
          icon: 'error',
          title: 'Transacción errónea',
          text: 'Algo ha ido mal, la transacción NO se ha realizado'
        });
      });
  } 
}
