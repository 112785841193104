import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-euros',
  templateUrl: './euros.component.html',
  styleUrls: ['./euros.component.css']
})
export class EurosComponent implements OnInit {
  
  selectedOption: string = '';
  isTransferenciaCollapsed: boolean = true;
  isTarjetaCollapsed: boolean = true;
  amount: number = 200;
  fee: number = 0;
  bankData: any 
  bankAccount: string = '';

  constructor(
    public fns: AngularFireFunctions,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.getBankData();
  }

  toggleCollapse(option: string) {
    if (option === 'transferencia') {
      this.isTransferenciaCollapsed = false;
      this.isTarjetaCollapsed = true; // Collapse the other option
    } else if (option === 'tarjeta') {
      this.isTarjetaCollapsed = false;
      this.isTransferenciaCollapsed = true; // Collapse the other option
    }
  }

  copyText() {
    if(!this.bankData?.IBAN) return;
    const textToCopy = this.bankData.IBAN;
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    Swal.fire({
      icon: 'success',
      title: 'Copiado al portapapeles',
      showConfirmButton: false,
      timer: 1500
    });
  }

  getFee(): number{
    const feeWithDecimals = this.amount * 0.005;
    const fee = Math.round(feeWithDecimals * 100) / 100;
    return fee;
  }

  getAmountWithFee(): number{
    const feeWithDecimals = this.amount * 0.005;
    const fee = Math.round(feeWithDecimals * 100) / 100;
    return this.amount + fee;
  }

  getBankData(){
    this.fns.httpsCallable('getMangoPayIban')({}).subscribe(
      (res: any) => {
        console.log('Response:', res);
        this.bankData = res.ibanData;
        const iban = this.bankData.IBAN

        this.bankAccount = `${iban.slice(0, 4)} ${iban.slice(4, 8)} ${iban.slice(8, 12)} ${iban.slice(12, 16)} ${iban.slice(16, 20)} ${iban.slice(20, 24)} ${iban.slice(24, 28)}`
      },
      (error: any) => {
        console.log('Error:', error);
      }
    );
  }

  getPaymentLink(){

    if(this.amount < 200){
      Swal.fire({
        icon: 'error',
        title: 'Cantidad mínima',
        text: 'La cantidad mínima para realizar un deposito es de 200€'
      });
      return;
    }

    const object = {
      amount: this.amount * 100,
      cardType : 'CB_VISA_MASTERCARD',
      total: this.getAmountWithFee() * 100
    }
    this.loaderService.showSpinner('Generando enlace de pago...');
    this.fns.httpsCallable('generateCreditCardPayment')(object).subscribe(
      (res: any) => {
        console.log('Response:', res);
        const paymentLink = res.paymentData.RedirectURL;
        this.loaderService.hideSpinner();

        Swal.fire({
          icon: 'success',
          title: 'Enlace de pago generado con éxito',
          text: 'Haz click en el botón para ir a la pasarela de pago',
          showConfirmButton: true,
          confirmButtonText: 'Ir a la pasarela de pago'
        }).then((result) => {
          if (result.isConfirmed) {
            window.open(paymentLink, '_blank');
          }
        });
      },
      (error: any) => {
        this.loaderService.hideSpinner();
        console.log('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error al generar el enlace de pago',
          text: 'Por favor, inténtelo de nuevo más tarde'
        });
      }
    );
  }

}