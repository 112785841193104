<ng-container *ngIf="isLoginPage">
    <app-cards-real-estate></app-cards-real-estate>
</ng-container>

<div class="modal modal1 fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered  modal-dialog-no-shadow" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="form-title text-center">
                    <h4>Iniciar sesión</h4>
                    <label class="textcolor">Invierte en nuevos proyectos y gestiona tus inversiones actuales</label>
                </div>
                <div class="d-flex flex-column text-start">
                    <div *ngIf="capsLockActivated" class="text-danger">
                        <span> *Las mayúsculas están activadas.
                        </span>
                    </div>
                    <form *ngIf="formLogin !== undefined || formLogin !== null" [formGroup]="formLogin">
                        <div class="form-group">
                            <label for="Email" class="labeltext">Email</label>
                            <input formControlName="email" class="form-control" id="email1"
                                placeholder="Ingresa tu email">
                        </div>
                        <div class="form-group pbc password-div">
                            <label for="Contraseña" class="labeltext">Contraseña</label>
                            <input formControlName="password" type="{{showPassword ? 'text' : 'password' }}"
                                class="form-control form-control-sm" placeholder="Ingresa tu contraseña">

                            <a (click)="togglePassword()" class="eye-b">
                                <i class="{{showPassword ? 'fa fa-eye-slash' : 'fa fa-eye' }}"></i>
                            </a>
                            <div class="text-end">
                                <label (click)="resetPassword()" class="changecolor">¿Has olvidado la contraseña?
                                </label>
                            </div>
                        </div>
                        <button (click)="login()" [disabled]="formLogin.invalid" type="button" class="btn ">INICIAR
                            SESIÓN</button>
                    </form>
                    <label class="pbl">¿Aun no tienes una cuenta? <span class="changecolor"
                            (click)="openRegisterForm()">Regístrate
                            gratis</span></label>

                    <div class="flex-container">
                        <div class="small-border"></div>
                        <div class="text-center">O si lo prefieres</div>
                        <div class="small-border"></div>
                    </div>
                    <button type="button" class="btngoogle">
                        <div class="flex-containerbutton" (click)="loginGoogle()">
                            <img class="img" src="../../../assets/img/devicon_google.png" alt="">
                            <label class="text" for=""> Inicia sesión con Google</label>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>