<div style="padding: 25px 10px;">

  <div *ngIf="(currentStage == 3 && currentStep > 2 && currentStep != 6) || (currentStage == 2 && currentStep == 2)" class="d-flex align-items-center" 
    style="gap: 5px; cursor: pointer;" (click)="backStep()">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    <span>ANTERIOR</span>
  </div>

  <div class="header mb-5">
    <div class="mb-4">
      <h2 class="tittle text-center">{{currentStage}} / 3</h2>
    </div>
    <div class="justify-content-center align-items-center" style="display: flex;">

      <div class="stage-completed">
        <img src="../../../assets/img/logo-circulo.svg" alt="Avatar" style="width: calc(1.375rem + 1.5vw)"/>
      </div>

      <div style="width: 125px; height: 4px;" [style.backgroundColor]="currentStage > 1 ? 'black' : '#d9d9d9'"></div>

      <div class="stage-remaining" [style.backgroundColor]="currentStage > 1 ? 'black' : ''">
        <i class="fa fa-id-card-o fa-3x"style="font-size: calc(1.375rem + 1.5vw) !important;"  [style.color]="currentStage > 1 ? '#f09500' : ''" aria-hidden="true"></i>
      </div>

      <div style="width: 125px; height: 4px;" [style.backgroundColor]="currentStage > 2 ? 'black' : '#d9d9d9'"></div>

      <div class="stage-remaining" [style.backgroundColor]="currentStage > 2 ? 'black' : ''">
        <i class="bi bi-wallet2 fs-1" [style.color]="currentStage > 2 ? '#f09500' : ''" aria-hidden="true"></i>
      </div>

    </div>
  </div>

  <div class="mt-4">
    <div *ngIf="currentStage == 1">
    <div class="text-center texto" [ngSwitch]="currentStep">
      <h2 *ngSwitchCase="1">
        ¡Tu inversión está solo a unos pasos!
      </h2>
    </div>
    <div class="text-center" class="text-center" *ngIf="currentStep < 3">
      <p>Para cumplir con la regulación y poder utilizar Domoblock deberá 
        finalizar la configuración de su cuenta. Le llevará solo 5 minutos.</p>
      <!-- <p>2. Sin preocupaciones, inquilinos, ni hipotecas</p>
      <p>3. Obtén rentabilidades superiores al 10% anual</p>
      <p>4. Hazlo tanto en euros cómo directamente a través de blockchain</p> -->
    </div>
    <div class="text-center" *ngIf="currentStep == 3">
      <p>Para cumplir con la regulación y poder utilizar Domoblock deberá finalizar la configuración de su cuenta. Le llevará solo 5 minutos.</p>
    </div>
  </div>

  <div *ngIf="currentStage == 2">
    <div class="text-center">
      <h2 *ngIf="currentStep == 1">
        ¿Qué tipo de inversor eres?
      </h2>
      <h2 *ngIf="currentStep == 2">
        Complete el KYC con su:
      </h2>
    </div>

    <div class="d-flex justify-content-center align-items-center" style="gap: 25px;" *ngIf="currentStep == 1">
      <button class="btn" [ngClass]="{'btn-outline-dark': typeOfInvestment !== 'company', 'btn-dark': typeOfInvestment === 'company'}" (click)="typeOfInvestment = 'company'">
        EMPRESA
      </button>
      <button class="btn" [ngClass]="{'btn-outline-dark': typeOfInvestment !== 'person', 'btn-dark': typeOfInvestment === 'person'}" (click)="typeOfInvestment = 'person'">
        PERSONA
      </button>
    </div>

    <div *ngIf="currentStep == 2 && typeOfInvestment === 'person'" class="d-flex mt-4 justify-content-between text-phone">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <i class="bi bi-person-fill fs-1"></i>
        <p>Información personal</p>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <img src="../../../../assets/img/cash.svg" alt="cash"  style="width: 40px;"/>
        <p>Información financiera</p>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column">
          <i class="bi bi-play-btn fs-1"></i>
          <p>Video identificación</p>
      </div>
    </div>

    <div *ngIf="currentStep == 2 && typeOfInvestment === 'company'" class="d-flex mt-4 justify-content-between text-phone">
      <div class="d-flex justify-content-center align-items-center flex-column">
        <img src="assets/img/modal-kyc/company.svg" alt="company"  style="width: 40px;"/>
        <p>Información empresa</p>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column">
        <img src="assets/img/modal-kyc/addperson.svg" alt="cash"  style="width: 40px;"/>
        <p>Representantes y titulares</p>
      </div>
      <div class="d-flex justify-content-center align-items-center flex-column">
          <i class="bi bi-play-btn fs-1"></i>
          <p>Video identificación</p>
      </div>
    </div>

  </div>

  <div *ngIf="currentStage == 3">

    <h1 class="text-center" style="margin: 0;" *ngIf="currentStep < 4">Configuración de la forma de inversión</h1>

    <div *ngIf="currentStep == 1" class="d-flex flex-column justify-content-center align-items-center">
      <div class="text-center">
        <p>¡Ya casi hemos terminado!</p>
      </div>
      <img src="../../../../assets//img/modal-kyc/houses-key.svg" alt="houses-key" />
    </div>

    <div *ngIf="currentStep == 2" class="d-flex flex-column justify-content-center align-items-center">
      <div class="text-center">
        <p>Elija qué método le gustaría utilizar para invertir en los proyectos</p>
      </div>

      <div class="row mt-5 justify-content-around">
        <div class="col-5 d-flex justify-content-center align-items-center flex-column text-center p-3" 
        style="gap: 10px; cursor: pointer;" (click)="selectInvestmentType('bankTransfer')"
        [ngClass]="{'selected': bankTransferSelected}">
          <img src="../../../../assets/img/modal-kyc/phone-cash.svg" alt="phone-cash">
          <p class="m-0">Transferencia bancaria o tarjeta de crédito</p>
        </div>

        <div class="col-5 d-flex justify-content-center align-items-center flex-column text-center p-3" 
        style="gap: 10px; cursor: pointer;" (click)="selectInvestmentType('blockchain')"
        [ngClass]="{'selected': blockchainSelected}">
          <img src="../../../../assets/img/modal-kyc/blockchain.svg" alt="blockchain">
          <p class="m-0">Blockchain, a través de una stablecoin</p>
        </div>
      </div>
    </div>

    <div *ngIf="currentStep == 3">
      <div class="d-flex gap-2 justify-content-center text-center">
        <p>¿Tienes una wallet de Metamask propia?</p>
        <div class="position-relative">
          <div class="position-absolute info-tooltip" 
              matTooltip="Si no tienes wallet propia no te preocupes, ya que no es necesario 
              tener una wallet de Metamask para disfrutar de los servicios de Domoblock."
              aria-label="Button that displays a tooltip when focused or hovered over">
              ?
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-4" style="gap: 10px;">
        <button class="btn btn-yes-or-no" 
        [ngClass]="{'btn-dark': hasMetamask === false, 'btn-outline-dark': hasMetamask !== false}" 
        (click)="hasMetamask = false">
          NO
        </button>

        <button class="btn btn-yes-or-no" 
        [ngClass]="{'btn-dark': hasMetamask === true, 'btn-outline-dark': hasMetamask !== true}" 
        (click)="hasMetamask = true">
          SI
        </button>
    </div>
    </div>

    <div *ngIf="currentStep == 4 && hasMetamask" class="d-flex flex-column justify-content-center align-items-center">
      <div class="text-center">
        <h1 style="margin: 0;">Conecta tu wallet</h1>
        <p>Conecta tu wallet y pegala para verificar este paso</p>
        <input type="text" class="form-control" placeholder="Introduce la dirección de tu wallet" [(ngModel)]="walletConnected">
      </div>
      <img src="../../../../assets/img/modal-kyc/wallet.svg">
    </div>

    <div *ngIf="currentStep == 5" class="d-flex flex-column justify-content-center align-items-center">
      <div class="text-center">
        <h1 style="margin: 0;"> Introduce el número de cuenta bancaria al que te gustaría recibir los rendimientos de tu futuras inversiones.</h1>
        <div class="row mt-5">
           <div class="col-2">
             <input type="text" class="border-0 no-focus-border w-100" placeholder="ES72" [(ngModel)]="bankFirstComponent" required>
             <div class="border-top thick-border"></div>
           </div>
           <div class="col-2">
            <input type="text" class="border-0 no-focus-border w-100" placeholder="1583" [(ngModel)]="bankSecondComponent" required>
            <div class="border-top thick-border"></div>
          </div>
          <div class="col-2">
            <input type="text" class="border-0 no-focus-border w-100" placeholder="0000" [(ngModel)]="bankThirdComponent" required>
            <div class="border-top thick-border"></div>
          </div>
          <div class="col-2">
            <input type="text" class="border-0 no-focus-border w-100" placeholder="1290" required [(ngModel)]="bankFourComponent">
            <div class="border-top thick-border"></div>
          </div>
          <div class="col-2">
            <input type="text" class="border-0 no-focus-border w-100" placeholder="1857" required [(ngModel)]="bankFiveComponent">
            <div class="border-top thick-border"></div>
          </div>
          <div class="col-2">
            <input type="text" class="border-0 no-focus-border w-100" placeholder="6110" required [(ngModel)]="bankSixComponent">
            <div class="border-top thick-border"></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="currentStep == 6" class="d-flex flex-column justify-content-center align-items-center">
      <div class="text-center">
        <h1>{{hasMetamask ? 'Wallet agregada' : 'Cuenta bancaria agregada'}}</h1>
        <p>{{hasMetamask ? '¡Felicidades, su wallet se ha añadido con éxito!' :'¡Felicidades, su cuenta bancaria se ha añadido con éxito!'}}</p>
      </div>
      <img src="../../../../assets/img/modal-kyc/applause.svg">
    </div>

  </div>

  <mat-dialog-actions class="d-flex justify-content-center align-items-center mb-3 mt-5">

    <div class="d-flex justify-content-center align-items-center" style="flex-direction: column; gap: 15px;">
        <button class="btn btn-success text-center" style=" width: 150px; border-radius: 30px;" 
          (click)="nextStep()" *ngIf="!(currentStage == 3 && currentStep == 4 && hasMetamask)"
          [disabled]="continueDisabled">
          <span>{{continueDisabled ? 'Guardando tu selección' : 'Continuar'}}</span>
        </button>
        <button class="btn btn-success text-center" style=" width: 150px; border-radius: 30px;" 
          (click)="nextStep()" *ngIf="currentStage == 3 && currentStep == 4 && hasMetamask">
          <span>Añadir wallet</span>
        </button>
        <!-- <button class="btn btn-success text-center" style=" width: 150px; border-radius: 30px;" 
        (click)="verStep()" >
        <span>ver step</span>  
      </button> -->
        <div class="d-flex justify-content-center align-items-center" style="gap: 8px;">
            <div class="modal-divider"></div>
            <span mat-button (click)="logOut()" class="logout-txt" >Cerrar sesión</span>
            <div class="modal-divider"></div>
        </div>
        
    </div>
  </mat-dialog-actions>
</div>
