<div>
    <div class="modal-header d-flex flex-column">
        <h1 class="modal-title" id="exampleModalLabel">¡AYUDANOS A MEJORAR!</h1>
        <p class="modal-subtitle">¿Cómo has conocido a Domoblock?</p>
    </div>

    <div class="d-flex justify-content-center align-items-center">
    <div class="d-flex flex-column">

        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault1" [(ngModel)]="selectedOption" value="linkedin">
            <label class="form-check-label" for="flexRadioDefault1">
                LinkedIn
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault2" [(ngModel)]="selectedOption" value="youtube">
            <label class="form-check-label" for="flexRadioDefault2">
                Youtube
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault3" [(ngModel)]="selectedOption" value="twitter">
            <label class="form-check-label" for="flexRadioDefault3">
                Twitter
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault4" [(ngModel)]="selectedOption" value="facebook">
            <label class="form-check-label" for="flexRadioDefault4">
                Facebook o Instagram
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault5" [(ngModel)]="selectedOption" value="google">
            <label class="form-check-label" for="flexRadioDefault5">
                Os encontré por Google
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault6" [(ngModel)]="selectedOption" value="friend">
            <label class="form-check-label" for="flexRadioDefault6">
                Me lo recomendó un amigo o familiar
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault7" [(ngModel)]="selectedOption" value="website">
            <label class="form-check-label" for="flexRadioDefault7">
                Conocí a Domoblock en otro sitio web
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault8" [(ngModel)]="selectedOption" value="newsletter">
            <label class="form-check-label" for="flexRadioDefault8">
                Me suscribí a vuestra Newsletter
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault9" [(ngModel)]="selectedOption" value="event">
            <label class="form-check-label" for="flexRadioDefault9">
                Evento presencial
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault" 
            id="flexRadioDefault10" [(ngModel)]="selectedOption" value="newspaper">
            <label class="form-check-label" for="flexRadioDefault10">
                Periódico o radio
            </label>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" name="flexRadioDefault"
            id="flexRadioDefault11" [(ngModel)]="selectedOption" value="other" >
            <label class="form-check-label" for="flexRadioDefault11">
                Otra
            </label>
        </div>

        <div *ngIf="selectedOption === 'other'" class="form-group mt-1">
            <input type="text" class="form-control" id="otherOption" [(ngModel)]="otherOption" placeholder="Escriba aquí">
        </div> 
    </div>
    </div>

    <mat-dialog-actions class="d-flex justify-content-center align-items-center mb-3 mt-3">

        <div class="d-flex justify-content-center align-items-center" style="flex-direction: column; gap: 15px;">
            <button class="btn btn-success text-center" style=" width: 150px; border-radius: 30px;"
            [disabled]="!selectedOption" (click)="next()">
              <span>Continuar</span>
            </button>
            <div class="d-flex justify-content-center align-items-center" style="gap: 8px;">
                <div class="modal-divider"></div>
                <span mat-button (click)="logOut()" class="logout-txt">Cerrar sesión</span>
                <div class="modal-divider"></div>
            </div>
            
        </div>
      </mat-dialog-actions>
</div>