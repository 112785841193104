import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

interface PaymentData {
  userId : string,
  userWallet : string,
  projectId : string,
  totalAmount : number,
  totalTokens : number
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = 'https://redsys.api.devtop.online'; // Reemplaza con la URL de tu API

  constructor(private http: HttpClient) {}

  enviarDatos(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(`${this.apiUrl}/redsys/payment`, datos, httpOptions);
  }

  /* DEPRECATED */ 
  transferenciaBancaria(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(`${this.apiUrl}/bank/create-payment`, datos, httpOptions);
  }

  investDigitalWallet(datos: PaymentData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(`${this.apiUrl}/projects/invest`, datos, httpOptions);
  }

}
