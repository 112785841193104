import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private isLoading = new BehaviorSubject<any>(null);
  dataIsLoading = this.isLoading.asObservable();
  private initialLoading =  new BehaviorSubject<any>(null);
  initialLoadingStatus = this.initialLoading.asObservable();


  constructor() { }

  hideSpinner() {
    const loadingObj = {
      isLoading: false,
      loadingMessage: ''
    }
    this.isLoading.next(loadingObj);
  }

  showSpinner(loadingMessage?: string) {
    const loadingObj = {
      isLoading: true,
      loadingMessage: loadingMessage
    }
    this.isLoading.next(loadingObj);
  }

  showInitialSpinner(loadingMessage?: string) {
    const loadingObj = {
      isLoading: true,
      loadingMessage: loadingMessage
    }
    this.initialLoading.next(loadingObj);
  }

  hideInitialSpinner() {
    this.initialLoading.next(false);
  }


  getLoadingDots(): { promise: Promise<string>, stop: () => void } {
    let intervalId: any;
    let stopFunction: () => void;

    const promise = new Promise<string>((resolve) => {
      let dots = '';
      intervalId = setInterval(() => {
        dots += '.';
        if (dots.length > 3) {
          dots = '';
        }
        resolve(dots);
      }, 500);

      stopFunction = () => {
        clearInterval(intervalId);
        resolve('');
      };
    });

    return { promise, stop: () => stopFunction() };
  }
}
