import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(value: number, currencySymbol: string = '€', decimalLength: number = 2, chunkDelimiter: string = '.', decimalDelimiter: string = ',', chunkLength: number = 3): string {
      const regex = '\\d(?=(\\d{' + chunkLength + '})+' + (decimalLength > 0 ? '\\D' : '$') + ')';
      const num = value.toFixed(Math.max(0, ~~decimalLength));
  
      return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(regex, 'g'), '$&' + chunkDelimiter) + currencySymbol;
  }

}
