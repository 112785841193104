<div class="new-container mb-5 pb-5">
    <form class="p-4 bradius" *ngIf="formYoutube !== undefined || formYoutube !== null" [formGroup]="formYoutube">
        <p class="title text-center h4 w-100">Cambiar la URL del Iframe de Youtube</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="url" class="form-label">Url del Iframe:</label>
            <input [class.errorInput]="url?.invalid && (url?.dirty || url?.touched)" formControlName="url" type="text" class="form-control" id="titulo" placeholder="Introduce la url del Iframe del vídeo">
        </div>
        
        <button (click)="setVideo()" type="button" class="btn btn-success w-100 pt-3 pb-3">Actualizar vídeo</button>
    </form>
</div>