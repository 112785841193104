import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-docs-legal',
  templateUrl: './docs-legal.component.html',
  styleUrls: ['./docs-legal.component.css']
})
export class DocsLegalComponent implements OnInit {
  formDocs: FormGroup = this.createFormDocs();

  constructor(private afs: AngularFirestore) {}

  createFormDocs() {
    return new FormGroup({
      urlTerminos: new FormControl(null),
      urlAviso: new FormControl(null),
      urlPrivacidad: new FormControl(null),
      urlCookies: new FormControl(null)
    })
  }

  get urlTerminos() { return this.formDocs?.get('urlTerminos'); }
  get urlAviso() { return this.formDocs?.get('urlAviso'); }
  get urlPrivacidad() { return this.formDocs?.get('urlPrivacidad'); }
  get urlCookies() { return this.formDocs?.get('urlCookies'); }

  setDocs() {
    if (this.formDocs.invalid) { 
      this.formDocs.markAllAsTouched();
      return; 
    }

    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').set({
      urlTerminos: this.urlTerminos?.getRawValue(),
      urlAviso: this.urlAviso?.getRawValue(),
      urlPrivacidad: this.urlPrivacidad?.getRawValue(),
      urlCookies: this.urlCookies?.getRawValue()
    }, {merge: true})
      .then(() => {
        Swal.fire({
          icon: 'success',
          text: `Urls de los documentos actualizadas`
        });
      })
      .catch(() => { 
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo ha ido mal!'
        });
      });
  }

  ngOnInit(): void {
    this.afs.collection('dapp').doc('HjdaO0nvpbsWq6LCF4fS').get().subscribe((res: any) => {
      if (res.exists) {
        this.urlTerminos?.patchValue(res.data().urlTerminos);
        this.urlAviso?.patchValue(res.data().urlAviso);
        this.urlPrivacidad?.patchValue(res.data().urlPrivacidad);
        this.urlCookies?.patchValue(res.data().urlCookies);
      }
    });
  }
}
