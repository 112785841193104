import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private apiUrl = 'https://sendmail.domoblock.io'; // Reemplaza con la URL de tu API

  constructor(private http: HttpClient) {}

  enviarDatos(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(`${this.apiUrl}/redsys/payment`, datos, httpOptions);
  }

  transferenciaBancaria(datos: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(`${this.apiUrl}/bank/create-payment`, datos, httpOptions);
  }

  createWallet(id: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.patch<any>(`${this.apiUrl}/bank/create/wallet/${id}`, httpOptions);
  }
}
