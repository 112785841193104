<div class="mt-5">
    <h3>
        Vincula una cuenta bancaria
    </h3>
    <p style="max-width: 530px;" class="mt-4" *ngIf="bankAccounts.length <= 0 ">
        Para poder retirar dinero debes asociar una cuenta bancaria que esté a tu nombre. 
        Puedes registrar hasta 4 cuentas bancarias diferentes que podrás cambiar cuando quieras.
    </p>

    <div class="mt-4">
        <mat-card class="example-card my-3" *ngFor="let account of bankAccounts; let i = index">
            <mat-card-content style="padding: 10px;" class="content-card">
                <span>IBAN:</span>
                <div class="d-flex justify-content-between wallet">
                    <p style="font-weight: 400; font-size: 1.5em;">{{account}}</p>
                    <p class="delete-wallet" (click)="deleteBankAccount(i)">Eliminar</p>
                </div>
            </mat-card-content>
        </mat-card>

        <div class="example-button-row">
            <button mat-stroked-button data-bs-toggle="modal" data-bs-target="#exampleModal">Vincular una cuenta</button>
        </div>
    </div>

    <div class="mt-4 d-flex justify-content-center align-items-center">
        <img src="assets/img/credit-cards-2.svg" alt="withdraw" class="withdraw-image">
    </div>

</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div> -->
        <div class="modal-body" style="padding: 3rem !important;">
            <div class="position-relative">
                <div class="position-absolute" style="top: -25px; right:-20px;">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <h4 class="modal-title" id="staticBackdropLabel">Vincular una cuenta bancaria</h4>
                
                <p class="mt-4">
                    Para poder retirar dinero debes asociar una cuenta bancaria que esté a tu nombre. 
                    Puedes registrar hasta 4 cuentas bancarias diferentes que podrás cambiar cuando quieras.
                </p>

                <div class="mt-4">
                    <label for="iban" class="form-label">Titular de la cuenta</label>
                    <input type="text" class="form-control" placeholder="Escriba aquí su nombre" [(ngModel)]="iban" required>
                </div>

                <div class="mt-4">
                    <label>Número IBAN</label>
                    <div class="row mt-2">
                        <div class="col-2">
                          <input type="text" class="border-0 no-focus-border w-100" placeholder="ES72">
                          <div class="border-top thick-border"></div>
                        </div>
                        <div class="col-2">
                         <input type="text" class="border-0 no-focus-border w-100" placeholder="1583">
                         <div class="border-top thick-border"></div>
                       </div>
                       <div class="col-2">
                         <input type="text" class="border-0 no-focus-border w-100" placeholder="0000">
                         <div class="border-top thick-border"></div>
                       </div>
                       <div class="col-2">
                         <input type="text" class="border-0 no-focus-border w-100" placeholder="1290">
                         <div class="border-top thick-border"></div>
                       </div>
                       <div class="col-2">
                         <input type="text" class="border-0 no-focus-border w-100" placeholder="1857">
                         <div class="border-top thick-border"></div>
                       </div>
                       <div class="col-2">
                         <input type="text" class="border-0 no-focus-border w-100" placeholder="6110">
                         <div class="border-top thick-border"></div>
                       </div>
                     </div>
                </div>

                <div class="mt-4">
                    <label for="name" class="form-label">Entidad bancaria</label>
                    <ng-select [items]="banks" bindLabel="name" bindValue="id" placeholder="Selecciona una entidad bancaria" required>
                    </ng-select>
                </div> 

                <div class="mt-4">
                    <button class="btn btn-success text-center" style=" width: 180px; border-radius: 30px;" >
                        <span style="color: white;">Vincular cuenta</span>
                    </button>
                </div>
          
        </div>
      </div>
    </div>
</div>
