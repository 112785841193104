<div class="investment-container">
    <div class="menu-investment">

        <div class="menu-options font font-weight-bold">
            <p (click)="selectOption('resumen')">RESUMEN FINANCIERO</p>
            <p (click)="selectOption('movimientos')">MOVIMIENTOS</p>
            <p (click)="selectOption('inversiones')">MIS INVERSIONES</p>
        </div>

        <div class="divider">
            <div class="divider-section" 
             [ngClass]="{'selected': selectedOption === 'resumen'}" 
             style="flex: 0.175"></div>
        <div class="divider-section" 
             [ngClass]="{'selected': selectedOption === 'movimientos'}" 
             style="flex: 0.145"></div>
        <div class="divider-section" 
             [ngClass]="{'selected': selectedOption === 'inversiones'}" 
             style="flex: 0.165"></div>
        </div>
    </div>
    <div class="content">
        <div class="pt-5 d-flex" style="gap: 25px;">
           <div class="data-box">
                <p>Saldo disponible</p>
                <h4 class="font-weight-normal">1.500,00€</h4>
           </div> 
            <div class="data-box">
                <p style="max-width: 140px;">Rentabilidad anual media obtenida</p>
                <h4>12.30%</h4>
            </div> 
            <div class="data-box">
                <p>Saldo disponible</p>
                <h4>1.500,00€</h4>
            </div> 

            <div id="chart" style="max-height: 120px;">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [labels]="chartOptions.labels"
                  [responsive]="chartOptions.responsive"
                ></apx-chart>
            </div>
        </div>
        
    </div>
</div>