import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalLoginComponent } from '../modal-login/modal-login.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  capsLockActivated: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  forgotForm: FormGroup = this.createForm();
  sentEmailForm: FormGroup = this.createFormEmail();
  verifyEmail: any;
  isLoading: boolean = false;
  loadingMessage: string = 'Cargando...';
  sentEmail: boolean = false;
  isDisabled: boolean = false;
  countdown: number = 60;
  interval: any;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authSrv: AuthService
  ) { }

  ngOnInit(): void {
   this.verifyEmail = this.email?.valueChanges.subscribe(() => {
      if (!this.email?.errors && this.email?.value !== null) {
        this.password?.enable();
        this.confirmPassword?.enable();
      } else {
        this.password?.disable();
        this.password?.patchValue(null);
        this.confirmPassword?.disable();
        this.confirmPassword?.patchValue(null);
      };
    });
  }

  ngOnDestroy(): void {
    this.verifyEmail.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event instanceof KeyboardEvent) {
      this.capsLockActivated = event.getModifierState('CapsLock');
    }
  }

  public togglePassword(showPassword: string) {
    switch (showPassword) {
      case 'password':
        this.showPassword = !this.showPassword;
        break;
      case 'confirmPassword':
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
      default:
        break;
    }
  }

  createForm() {
    return new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.minLength(7), Validators.maxLength(7)]),
      password: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(20),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[0-9]/),
      ]),
      confirmPassword: new FormControl({ value: null, disabled: true })
    })
  }

  createFormEmail(){
    return new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    })
  }

  get email() { return this.sentEmailForm?.get('email'); }
  get password() { return this.forgotForm?.get('password'); }
  get confirmPassword() { return this.forgotForm?.get('confirmPassword'); }
  get otp() { return this.forgotForm?.get('otp'); }
  
  openModalLogin() {
    const dialogRef = this.dialog.open(ModalLoginComponent, {disableClose:true, panelClass: 'modal-login-register'});

    dialogRef.afterClosed().subscribe(result => {
      console.log('El modal se ha cerrado');
    });
  }

  goToLogin() {
    this.dialogRef.close();
    this.openModalLogin();
  }

  convertToUppercase(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
    this.forgotForm.get('otp')?.setValue(input.value);
  }

  async getOtp() {

    if (this.isDisabled) {
      return;
    }

    if(!this.email?.value) {
      Swal.fire({
        icon: 'error',
        title: 'Campo vacío',
        text: 'Por favor, introduce tu email'
      });
      return;
    }

    if (!this.email?.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Email no válido',
        text: 'Por favor, introduce un email válido'
      });
      return;
    }

    this.isLoading = true;
    await this.authSrv.sentOtpforResetPassword(this.email?.value).then((res) => {
      if (res.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'OTP enviado',
          text: 'Hemos enviado un OTP a tu email'
        });
        this.sentEmail = true;
        this.isDisabled = true;
        this.countdown = 60;
        this.interval = setInterval(() => {
          this.countdown--;
          if (this.countdown === 0) {
            this.isDisabled = false;
            clearInterval(this.interval);
          }
        }, 1000);
      }
    }).catch((error) => {
      console.log(error);

      Swal.fire({
        icon: 'error',
        title: 'Error al enviar el OTP',
        text: 'Por favor, intenta de nuevo'
      });
    });

    this.isLoading = false;
  }

  async changePassword() {

    if (!this.otp?.valid) {
      Swal.fire({
        icon: 'error',
        title: 'Otp no válido',
        text: 'Por favor, otp válido'
      }); 
      return; 
    }

    if (!this.password?.valid) {
      const error = this.password?.errors as any
      if (error.minlength) {
        Swal.fire({
          icon: 'error',
          title: 'Contraseña no válida',
          text: `La contraseña debe tener al menos ${error.minlength.requiredLength} 
                caracteres y actualmente tiene  ${error.minlength.actualLength}`
        });
        return;
      }
      if (error.maxlength) {
        Swal.fire({
          icon: 'error',
          title: 'Contraseña no válida',
          text: `La contraseña debe tener máximo ${error.maxlength.requiredLength} 
                caracteres y actualmente tiene  ${error.maxlength.actualLength}`
        });
        return;
      }
      if (this.password?.errors?.['pattern']?.requiredPattern){
        switch (this.password?.errors?.['pattern']?.requiredPattern) {
          case '/[A-Z]/':
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce al menos una mayúscula'
            });
            break;
          case '/[a-z]/':
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce al menos una minúscula'
            });
            break;
          case '/[0-9]/':
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce al menos un número'
            });
            break;
          default:
            Swal.fire({
              icon: 'error',
              title: 'Contraseña no válida',
              text: 'Por favor, introduce una contraseña válida'
            });
            break
        }
      }
      Swal.fire({
        icon: 'error',
        title: 'Contraseña no válida',
        text: 'Por favor, introduce una contraseña válida'
      });
      return;
    }

    if (this.password?.value != this.confirmPassword?.value) { 
      Swal.fire({
        icon: 'error',
        title: 'Las contraseñas no coinciden',
        text: 'Por favor, verifica que las contraseñas coincidan'
      });
      return;
    }

    this.isLoading = true;

    try {
      const restPassword = await this.authSrv.forgotPassword(this.email?.value, this.password?.value, this.otp?.value);
      if (restPassword.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Contraseña cambiada',
          text: 'Tu contraseña ha sido cambiada correctamente'
        });
        this.dialogRef.close();
        this.openModalLogin();
      }
      this.isLoading = false;
    } catch (error : any) {
      console.log(error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al cambiar la contraseña',
        text: 'Por favor, intenta de nuevo'
      });
      this.isLoading = false;
      return;
    }
  }
}
