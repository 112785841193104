<div class="hide-container">
    <form class="bradius mb-5" *ngIf="formSetHiden !== undefined || formSetHiden !== null" [formGroup]="formSetHiden">
        <p class="title text-center h4 w-100">Ocultar proyecto</p>

        <hr class="w-100 mb-md-5">

        <div class="mb-4">
            <label for="nameToken" class="form-label">Nombre del token:</label>
            <input maxlength="60" [class.errorInput]="nameToken?.invalid && (nameToken?.dirty || nameToken?.touched)" formControlName="nameToken" type="text" class="form-control" id="nameToken" placeholder="Introduce el nombre del token del proyecto">
        </div>

        <div class="w-100 mb-3">
            <p class="w-100 mb-0">Editar visibilidad <del></del> proyecto:</p>
            <select formControlName="hide" class="form-select">
                <option value="null" selected>Selecciona una opción</option>
                <option value="true">Ocultar proyecto</option>
                <option value="false">Mostrar proyecto</option>
                <option value="inversors">Mostrar proyecto Solo Inversores</option>
            </select>
        </div>

        <button (click)="setHide()" [disabled]="formSetHiden.invalid" type="button" class="btn btn-success w-100 mt-5 pt-3 pb-3">Cambiar visibilidad</button>
    </form>
</div>