<div *ngIf="!registroFlag && loginFlag" class="login-container">
    <form class="p-5 bradius" *ngIf="formLogin !== undefined || formLogin !== null" [formGroup]="formLogin">
        <p class="title text-center h4 mb-3 w-100 text-white">Login</p>

        <hr class="w-100 mb-5">

        <div class="mb-4">
            <label for="email" class="form-label text-white">Email:</label>
            <input formControlName="email" type="text" class="form-control" id="email" placeholder="Introduce un email">
        </div>

        <div class="mb-4">
            <label for="password" class="form-label text-white">Password:</label>
            <input formControlName="password" type="password" class="form-control mb-2" id="password" placeholder="Introduce una contraseña">
            <p class="small text-white p-1">* La contraseña debe contener al menos 6 caracteres, un numéro, una letra mayúscula y una letra minúscula.</p>
        </div>

        <button  (click)="login()" [disabled]="formLogin.invalid" class="btn btn-success w-100 pt-3 pb-3 mb-4">Entrar</button>

        <hr class="w-100 mb-5">

        <div class="links">
            <button (click)="registroFlag = true; loginFlag = false;" class="btn btn-success">¿Aún no eres miembro? Regístrate</button>
            <button (click)="resetPasword()" class="btn btn-outline-success">¿Te has olvidado de la contraseña?</button>
        </div>
    </form>
</div>

<div *ngIf="registroFlag && !loginFlag" class="login-container">
    <form class="p-5 bradius" *ngIf="formRegistro !== undefined || formRegistro !== null" [formGroup]="formRegistro">
        <p class="title text-center h4 mb-3 w-100 text-white">Registro</p>

        <hr class="w-100 mb-5">

        <div class="mb-4">
            <label for="name" class="form-label text-white">Nombre:</label>
            <input formControlName="name" type="text" class="form-control" id="name" placeholder="Introduce tu nombre">
        </div>

        <div class="mb-4">
            <label for="emailR" class="form-label text-white">Email:</label>
            <input formControlName="emailR" type="text" class="form-control" id="emailR" placeholder="Introduce un email">
        </div>

        <div class="mb-4">
            <label for="passwordR1" class="form-label text-white">Password:</label>
            <input formControlName="passwordR1" type="password" class="form-control mb-2" id="passwordR1" placeholder="Itroduce una contraseña">
            <p class="small text-white p-1">* La contraseña debe contener al menos 6 caracteres, un numéro, una letra mayúscula y una letra minúscula.</p>
        </div>

        <div class="mb-4">
            <label for="passwordR2" class="form-label text-white">Confirma la password:</label>
            <input formControlName="passwordR2" type="password" class="form-control mb-2" id="passwordR2" placeholder="Itroduce otra vez contraseña">
            <p class="small text-white p-1">* Las contraseñas deben ser identicas.</p>
        </div>

        <button  (click)="registro()" [disabled]="formRegistro.invalid || (formRegistro.value.passwordR1 !== formRegistro.value.passwordR2)" class="btn btn-success w-100 pt-3 pb-3 mb-4">Registrarme</button>

        <hr class="w-100 mb-5">

        <div class="links">
            <button (click)="registroFlag = false; loginFlag = true;" class="btn btn-success">¿Ya eres miembro? Login</button>
        </div>
    </form>
</div>
