<div class="text-center p-5 position-relative">
    <div *ngIf="successKYC" class="position-absolute close-btn" (click)="close()">
        <i class="fa fa-times" aria-hidden="true"></i>
    </div>
    <h1 *ngIf="successKYC" style="font-size: 2.2rem;">¡Enhorabuena, ya puedes invertir!</h1>
    <h1 *ngIf="revisionKYC && !kycDuplicate" style="font-size: 2.2rem;">¡Tu cuenta está en revisión!</h1>
    <h1 *ngIf="revisionKYC  && kycDuplicate" style="font-size: 2.2rem;">¡Documento Duplicado!</h1>

    <p *ngIf="successKYC" style="font-size: 1.2rem;">¡La configuración de su cuenta se ha completado con éxito! 🎉</p>
    <p *ngIf="revisionKYC && !kycDuplicate" style="font-size: 1.2rem;">
        Estamos revisando los datos de tu cuenta. Si necesitamos 
        información adicional nos pondremos en contacto contigo. 🔎
    </p>

    <p *ngIf="kycDuplicate" style="font-size: 1.2rem;">
        Tu documento ya está registrado en otra cuenta, solo permitimos una cuenta por persona. 
        Si tienes alguna duda, por favor contáctanos. 📞
    </p>

 

    <div *ngIf="revisionKYC" class="d-flex justify-content-center align-items-center mt-4" style="gap: 8px;">
        <div class="modal-divider"></div>
        <span mat-button class="logout-txt" (click)="logout()"
        >Cerrar sesión</span>
        <div class="modal-divider"></div>
    </div>

</div>