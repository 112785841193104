<div class="payment-container">
  <div class="payment__title">
    <h1 class="text-center">¡Pago realizado con éxito!</h1>
  </div>
  <div>
    <p class="payment__text__paragraph text-center">
      La transacción en el proyecto se ha completado con éxito, en breves
      registraremos tú inversión.
    </p>
  </div>
  <div class="d-flex justify-content-center align-items-center" style="margin-bottom: 15px;">
    <button routerLink="/oportunidades" class="btn btn-success width-btn">Aceptar</button>
  </div>
</div>
